//import { actions } from '@/store/modules/authentication/Auth.actions'
import { getters } from '@/store/modules/auth/Auth.getters'
import { mutations } from '@/store/modules/auth/Auth.mutations'
import { actions } from '@/store/modules/auth/Auth.actions'
import { authLoginSchema, AuthLoginSchemaInterface } from '@/forms/auth/AuthLogin.schema'
import { useAuthFormState } from '@/composables/auth/useAuthFormState'
import { authRequestPasswordSchema, AuthRequestPasswordSchemaInterface } from '@/forms/auth/AuthRequestPassword.schema'

export type AuthFormState = 'login' | 'requestPassword'

/**
 * State interface
 */
export interface AuthState {
    requestPasswordModel:{
        email: string;
    },
    loading: boolean;
    formState: AuthFormState;
    loginSchema: AuthLoginSchemaInterface
    requestPasswordSchema: AuthRequestPasswordSchemaInterface
}

/**
 * State
 * @type {{user: null}}
 */
export const state: AuthState = {
    requestPasswordModel:{
        email: 'jaco@weide-ic.nl'
    },
    formState: useAuthFormState(),
    loginSchema: authLoginSchema,
    requestPasswordSchema: authRequestPasswordSchema,
    loading: false,
}

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
