import Api from '@/api/src/Api'
import { AxiosPromise } from 'axios'
import { AddressInterface } from '@/models/common/Address.interface'

export default class AddressesApi extends Api {
    constructor() {
        super()

        this.endPoint = 'addresses'
    }
    
    async createForStudent(data, student): Promise<AddressInterface> {
        data.student = student['@id']
        data.houseNumber = +data.houseNumber
        
        return (await super.create(data)).data
    }

    /**
     * Update resource
     * @param {string} id
     * @param {{}} data
     * @returns {AxiosPromise}
     */
    update(id: string, data:AddressInterface): AxiosPromise {
        data.houseNumber = +data.houseNumber

        return this.axios.put(`${this.endPoint}/${id}`, data)
    }

    /**
     * Updated resource by uri
     * @param {string} uri
     * @param {{}} data
     * @returns {AxiosPromise}
     */
    updateByUri(uri: string, data:AddressInterface): AxiosPromise {
        data.houseNumber = +data.houseNumber

        return this.axios({url:uri, method:'PUT', baseURL: process.env.VUE_APP_API_BASE_URL, data})
    }
}
