import { MutationTree } from 'vuex'
import { CoursesState } from '@/store/modules/courses/Courses'
import { CourseInterface } from '@/models/course/Course.interface'
import { CoursesFilterInterface } from '@/models/courses/CoursesFilter.interface'
import PaginationInterface from "@/models/pagination/Pagination.interface";


export enum CoursesMutations {
    SET_COURSES = 'SET_COURSES',
    SET_COURSES_PAGINATION = 'SET_COURSES_PAGINATION',
    UPDATE_COURSES_FILTER = 'UPDATE_COURSES_FILTER',
    SET_LOADING = 'SET_LOADING'
}


export const mutations: MutationTree<CoursesState> = {
    [CoursesMutations.SET_COURSES]: (state: CoursesState, courses: CourseInterface[]) => {
        state.courses = courses
    },

    [CoursesMutations.SET_COURSES_PAGINATION]: (state: CoursesState, pagination: PaginationInterface) => {
        state.pagination = pagination
    },

    [CoursesMutations.UPDATE_COURSES_FILTER]: (state: CoursesState, coursesFilter: CoursesFilterInterface) => {
        state.coursesFilter = Object.assign(state.coursesFilter, coursesFilter);
    },

    [CoursesMutations.SET_LOADING]: (state: CoursesState, loading: boolean) => {
        state.loading = loading
    }
}
