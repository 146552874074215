import { getters } from '@/store/modules/registration/Registration.getters'
import { actions } from '@/store/modules/registration/Registration.actions'
import { mutations } from '@/store/modules/registration/Registration.mutations'
import { generateRegistrationCompletionSchema, RegistrationCompletionSchemaInterface } from '@/forms/registration/RegistrationCompletion.schema';
import { RegistrationInterface } from '@/models/registration/Registration.interface';
import { PaymentInterface } from '@/models/payment/Payment.interface';
import {
    generateRegistrationSchema,
    RegistrationSchemaInterface
} from '@/forms/registration/Registration.schema';

/**
 * State interface
 */
export interface RegistrationState {
    registration?: RegistrationInterface;
    payment?: PaymentInterface;
    loading: boolean;
    registrationSchema: RegistrationSchemaInterface;
    registrationCompletionSchema: RegistrationCompletionSchemaInterface;
    registrationEntryChanged: boolean;
}

/**
 * Registration state
 * @type {{registrationCompletionSchema: RegistrationCompletionSchemaInterface, registrationSchema: RegistrationSchemaInterface, registration: null, payment: null, registrationEntryChanged: boolean, loading: boolean}}
 */
export const initialState: RegistrationState = {
    registration: null,
    payment: null,
    loading: false,
    registrationSchema: generateRegistrationSchema(),
    registrationCompletionSchema: generateRegistrationCompletionSchema(),
    registrationEntryChanged: false
}

export const state = initialState;

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
