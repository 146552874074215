import { MutationTree } from 'vuex'
import { CourseState, initialState } from '@/store/modules/course/Course'
import { CourseEntryInterface } from '@/models/course-entries/CourseEntry.interface'
import { CourseInterface } from '@/models/course/Course.interface'
import { CourseCompletedFilterInterface } from '@/models/course/CourseCompletedFilter.interface'
import { CoursePlannedFilterInterface } from '@/models/course/CoursePlannedFilter.interface'
import { StartDateNotification } from '@/models/startdate-notification/StartDateNotification.interface'
import { generateCourseSchema } from '@/forms/course/Course.schema'
import { CostsInterface } from '@/models/course/CourseAdditionalCosts.interface'
import { CourseType } from '@/models/course/Course.types'
import { generateCourseCost } from '@/forms/course/generators/generateCourseCost'
import { formatCostsToDecimal } from '@/functions'
import { collect } from 'collect.js'
import {
    generateStartDateMailingSchema
} from '@/forms/startDateMailing/StartDateMailing.schema'
import { FieldChoiceInterface } from '@/models/forms/schema/FieldOptions.interface'
import { CourseArchivedFilterInterface } from '@/models/course/CourseArchivedFilter.interface'
import {MediaObjectInterface} from "@/models/media/MediaObject.interface";

export enum CourseMutations {
    SET_COURSE = 'SET_COURSE',
    SET_COURSE_SCHEMA_FILES = 'SET_COURSE_SCHEMA_FILES',
    SET_STARTDATE_NOTIFICATIONS = 'SET_STARTDATE_NOTIFICATIONS',
    SET_LOADING = 'SET_LOADING',
    RESET_STATE = 'RESET_STATE',

    SET_COMPLETED = 'SET_COMPLETED',
    UPDATE_COMPLETED_FILTER = 'UPDATE_COMPLETED_FILTER',

    SET_ARCHIVED = 'SET_ARCHIVED',
    UPDATE_ARCHIVED_FILTER = 'UPDATE_ARCHIVED_FILTER',

    SET_PLANNED = 'SET_PLANNED',
    SET_COURSE_START_DATE_OPTIONS = 'SET_COURSE_START_DATE_OPTIONS',

    SET_ELEARNINGS = 'SET_ELEARNINGS',
    UPDATE_PLANNED_FILTER = 'UPDATE_PLANNED_FILTER',
    ADD_COURSE_COSTS = 'ADD_COURSE_COSTS',
    DELETE_COURSE_COSTS = 'DELETE_COURSE_COSTS',
    SET_PRICES = 'SET_PRICES',
    SET_PRICES_FILTER = 'SET_PRICES_FILTER'
}

interface CourseDeleteCourseCostsInterface {
    index: number,
    type: CourseType
}

// mutations
export const mutations: MutationTree<CourseState> = {
    [CourseMutations.SET_COURSE]: (state: CourseState, course: CourseInterface) => {
        state.course = course

        const modelCourse = JSON.parse(JSON.stringify(course))
        modelCourse.prices = formatCostsToDecimal(modelCourse.prices)
        modelCourse.additionalCosts = formatCostsToDecimal(modelCourse.additionalCosts)

        state.courseSchema.model = modelCourse
    },

    [CourseMutations.SET_PRICES]: (state: CourseState, prices: Record<CourseType, Partial<CostsInterface>[]>) => {
        state.prices = prices
    },

    [CourseMutations.SET_PRICES_FILTER]: (state: CourseState, type: CourseType) => {
        state.pricesFilter = type
    },

    [CourseMutations.SET_STARTDATE_NOTIFICATIONS]: (state: CourseState, startDateNotifications: StartDateNotification[]) => {
        state.startDateNotifications = startDateNotifications
    },

    [CourseMutations.SET_LOADING]: (state: CourseState, loading: boolean) => {
        state.loading = loading
    },

    [CourseMutations.RESET_STATE]: (state: CourseState) => {
        Object.assign(state, initialState)

        // Reset schema
        state.courseSchema = generateCourseSchema()
        state.courseStartDataMailingSchema = generateStartDateMailingSchema()
    },

    // Completed
    [CourseMutations.SET_COMPLETED]: (state: CourseState, courseEntries: CourseEntryInterface[]) => {
        state.completed = courseEntries
    },

    [CourseMutations.UPDATE_COMPLETED_FILTER]: (state: CourseState, filters: CourseCompletedFilterInterface) => {
        state.completedFilter = Object.assign(state.completedFilter, filters);
    },

    // Planned
    [CourseMutations.SET_PLANNED]: (state: CourseState, courseEntries: CourseEntryInterface[]) => {
        state.planned = courseEntries
    },

    // Archived
    [CourseMutations.SET_ARCHIVED]: (state: CourseState, courseEntries: CourseEntryInterface[]) => {
        state.archived = courseEntries
    },

    [CourseMutations.UPDATE_ARCHIVED_FILTER]: (state: CourseState, filters: CourseArchivedFilterInterface) => {
        state.archivedFilter = Object.assign(state.archivedFilter, filters);
    },

    // Set options for start dates
    [CourseMutations.SET_COURSE_START_DATE_OPTIONS]: (state: CourseState, choices: FieldChoiceInterface[]) => {
        state.courseStartDataMailingSchema.fields.startDate.choices = choices
    },

    [CourseMutations.UPDATE_PLANNED_FILTER]: (state: CourseState, filters: CoursePlannedFilterInterface) => {
        state.plannedFilter = Object.assign(state.plannedFilter, filters);
    },

    // Elearnings
    [CourseMutations.SET_ELEARNINGS]: (state: CourseState, courseEntries: CourseEntryInterface[]) => {
        state.elearnings = courseEntries
    },

    // Files
    [CourseMutations.SET_COURSE_SCHEMA_FILES]: (state: CourseState, files: MediaObjectInterface[]) => {
        state.courseSchema.model.files = files
    },

    /**
     * Add course costs
     * @param {CourseState} state
     * @param {CourseType} type
     */
    [CourseMutations.ADD_COURSE_COSTS]: (state: CourseState) => {
        const costs = generateCourseCost({
            type: state.pricesFilter
        })

        state.prices[state.pricesFilter].push(costs)
    },

    /**
     * Add course costs
     * @param {CourseState} state
     * @param {CourseType} type
     */
    [CourseMutations.DELETE_COURSE_COSTS]: (state: CourseState, payload: CourseDeleteCourseCostsInterface) => {
        //state.prices

        // Get start point from costs
        const pricesByTypePrice = collect(state.prices[state.pricesFilter]).filter(price => price['@type'] === 'Price').count()
        state.prices[state.pricesFilter].splice((pricesByTypePrice + payload.index), 1)
    },
}
