import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export interface CourseStartDataMailingSchemaInterface {
    fields: {
        startDate: FieldInterface;
    },
    model:{
        startDates: [];
    }
}

export function generateStartDateMailingSchema(): CourseStartDataMailingSchemaInterface{
    return {
        fields: {
            startDate: {
                component: 'Checkbox',
                model: 'startDates',
                required: true,
                label: {
                    text: i18n.global.t('word.remember.me')
                },
                choices: [],
                validation: null
            }
        },
        model:{
            startDates: [],
        }
    }
}
