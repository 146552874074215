import { UserInterface } from '@/models/users/User.interface'
import { getters } from '@/store/modules/user/User.getters'
import { actions } from '@/store/modules/user/User.actions'
import { mutations } from '@/store/modules/user/User.mutations'

/**
 * State interface
 */
export interface UserState {
    user?: UserInterface;
    authenticated: boolean;
}

/**
 * State
 * @type {{user: null}}
 */
export const state: UserState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  authenticated: localStorage.getItem('authenticated') ? JSON.parse(localStorage.getItem('authenticated')) : false,
}

/**
 * Export module
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
