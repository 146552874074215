import { API_DATE_FORMAT, API_DATE_TIME_FORMAT } from "@/api/src/Api";
import CourseDatesApi from "@/api/modules/courseDates/CourseDates";
import { AxiosPromise } from "axios";
import { dayjs } from "@/main";
import { collect } from "collect.js";
import { CourseEntryInterface } from "@/models/course-entries/CourseEntry.interface";
import ApiInstance from "@/api/src/Api";
import axios from "axios";
import { CourseDateInterface } from "@/models/course/CourseDate.interface";
import { CourseTypes } from "@/models/course/Course.types";
import { RegistrationStatus } from "@/models/registration/Registration.types";
import merge from "deepmerge";
import Api from "@/api/Api";

export default class CoursesApi extends ApiInstance {
  constructor() {
    super();

    this.endPoint = "course_entries";
  }

  /**
   * Create resource
   * @param {{}} data
   * @returns {AxiosPromise}
   */
  create(data): AxiosPromise {
    if (data.type === CourseTypes.elearning) {
      delete data.startDate;
      delete data.courseDates;
      delete data.spots;
    } else {
      data.startDate = dayjs(data.courseDates[0].date).format(API_DATE_FORMAT);

      // Sort by date
      data.courseDates = collect(data.courseDates).sortBy("date").all();

      data.courseDates = data.courseDates.map((courseDate, index) => {
        // Format date
        courseDate.startDate = dayjs(courseDate.date)
          .minute(courseDate.startDate)
          .format(API_DATE_TIME_FORMAT);
        courseDate.endDate = dayjs(courseDate.date)
          .minute(courseDate.endDate)
          .format(API_DATE_TIME_FORMAT);

        // Set day_number
        courseDate.dayNumber = index + 1;

        return courseDate;
      });
    }

    return this.axios.post(`${this.endPoint}`, data);
  }

  /**
   * Update course dates
   * @param data
   */
  async updateCourseDates(data) {
    const apiCalls = [];
    const courseDatesApi = new CourseDatesApi();

    // Sort by date
    let courseDates: CourseDateInterface[] = collect(data).sortBy("date").all();
    courseDates = data.map((courseDate, index) => {
      // Format date
      courseDate.startDate = dayjs(courseDate.date)
        .minute(courseDate.startDate)
        .format(API_DATE_TIME_FORMAT);
      courseDate.endDate = dayjs(courseDate.date)
        .minute(courseDate.endDate)
        .format(API_DATE_TIME_FORMAT);

      // Set day_number
      courseDate.dayNumber = index + 1;
      //courseDate.teacher = courseDate.teacher['@id']

      return courseDate;
    });

    courseDates.forEach((courseDate: CourseDateInterface) => {
      courseDatesApi.update(courseDate.id, courseDate);
      apiCalls.push(courseDate);
    });

    return axios.all(apiCalls);
  }

  /**
   * Update
   * @param {string} data
   * @returns {AxiosPromise}
   */
  async update(data) {
    data = JSON.parse(JSON.stringify(data));
    delete data.stats;

    const deleteCourseDateCalls = [];

    if (data.type === CourseTypes.elearning) {
      delete data.startDate;
      delete data.courseDates;
      delete data.spots;
    } else {
      data.courseDates
        .filter((courseDate) => courseDate.deletedAt)
        .forEach((courseDate) => {
          deleteCourseDateCalls.push(
            this.axios.delete(`course_dates/${courseDate.id}`),
          );
        });
      data.startDate = dayjs(data.courseDates[0].date).format(API_DATE_FORMAT);

      // Sort by date
      data.courseDates = collect(data.courseDates)
        .filter((courseDate: CourseDateInterface) => !courseDate.deletedAt)
        .sortBy("date")
        .all();
      data.courseDates = data.courseDates.map((courseDate, index) => {
        // Format date
        courseDate.startDate = dayjs(courseDate.date)
          .minute(courseDate.startDate)
          .format(API_DATE_TIME_FORMAT);
        courseDate.endDate = dayjs(courseDate.date)
          .minute(courseDate.endDate)
          .format(API_DATE_TIME_FORMAT);

        // Set day_number
        courseDate.dayNumber = index + 1;
        //courseDate.teacher = courseDate.teacher['@id']

        return courseDate;
      });
    }

    // Await delete calls for deleting courseDates
    await Promise.all(deleteCourseDateCalls);
    return await this.axios.put(`${this.endPoint}/${data.id}`, data, {
      // @ts-ignore
      delay: 2000,
    });
  }

  /**
   * Get course dates from entry
   * @param {CourseEntryInterface} courseEntry
   * @param {Record<string, any>} options
   * @returns {AxiosPromise}
   */

  getCourseDates(
    courseEntry: CourseEntryInterface,
    options: Record<string, any> = {},
  ): Promise<any> {
    const apiCalls = [];
    const courseDates: CourseDateInterface[] = courseEntry.courseDates;

    courseDates.forEach((courseDate) => {
      apiCalls.push(this.axios.get(`course_dates/${courseDate.id}`), options);
    });

    return axios.all(apiCalls);
  }

  /**
   * Get course registrations from entry
   * @param {CourseEntryInterface} courseEntry
   * @param {Record<string, any>} options
   * @returns {AxiosPromise}
   */

  getRegistrations(
    courseEntry: CourseEntryInterface,
    options: Record<string, any> = {},
  ): AxiosPromise {
    options = merge(
      {
        params: {
          status: RegistrationStatus.REGISTERED,
          itemsPerPage: 30,
          "order[createdAt]": "DESC",
        },
      },
      options,
    );

    return this.axios.get(
      `${this.endPoint}/${courseEntry.id}/registrations`,
      options,
    );
  }

  /**
   * Get export link
   * @param {CourseEntryInterface} courseEntry
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getExportLink(courseEntry: CourseEntryInterface) {
    return this.axios.get(
      `${this.endPoint}/${courseEntry.id}/export/registrations/link`,
    );
  }

  /**
   * Get bulk export link
   * @param {CourseEntryInterface} courseEntry
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getExportBulkLink(courseEntry: CourseEntryInterface) {
    return this.axios.get(
      `${this.endPoint}/${courseEntry.id}/generate-certificate-bulk/get-link`,
    );
  }

  async downloadCertificates(link) {
    return this.axios.get(link, {
      responseType: "blob",
    });
  }

  /**
   * Get related course entries
   * @param {CourseEntryInterface} courseEntry
   * @param {Record<string, any>} options
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getRelatedCourseEntries(
    courseEntry: CourseEntryInterface,
    options: Record<string, any> = {},
  ) {
    return this.axios.get(
      `${Api.courses.endPoint}/${courseEntry.course.id}/course_entries`,
      options,
    );
  }
}
