import { actions } from '@/store/modules/registrations/Registrations.actions'
import { getters } from '@/store/modules/registrations/Registrations.getters'
import { mutations } from '@/store/modules/registrations/Registrations.mutations'
import { RegistrationInterface } from '@/models/registration/Registration.interface'
import { RegistrationsFilterInterface } from '@/models/registrations/RegistrationsFilter.interface'
import { RegistrationStatus } from '@/models/registration/Registration.types'
import {
    generateRegistrationsFilter,
    RegistrationsFilterSchemaInterface
} from '@/forms/registrations/RegistrationsFilter.schema'
import PaginationInterface from '@/models/pagination/Pagination.interface'

export interface RegistrationsState {
    registrations: RegistrationInterface[];
    pagination: PaginationInterface;
    registrationsFilter?: RegistrationsFilterInterface;
    registrationsFilterSchema: RegistrationsFilterSchemaInterface;
    loading: boolean;
}

export const state: RegistrationsState = {
    registrations: null,
    loading: false,
    registrationsFilterSchema: generateRegistrationsFilter(),
    pagination: null,
    registrationsFilter: {
        order: 'desc',
        order_by: 'createdAt',
        dateRange: 'week',
        status: RegistrationStatus.REGISTERED
    }
}

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
