import i18n from '@/i18n';

const Registrations = () => import(/* webpackChunkName: "group-registrations" */ '@/views/app/registrations/RegistrationsIndex.vue')
const RegistrationCreate = () => import(/* webpackChunkName: "group-registrations" */ '@/views/app/registrations/RegistrationCrud.vue')
const GeneralRouterView = () => import(/* webpackChunkName: "group-registrations" */ '@/components/general/GeneralRouterView.vue')
import menuRegistrationIcon from '@/assets/images/icons/menu-registration-icon.svg'

export default [
    {
        path: '/registrations',
        name: 'registrations',
        component: GeneralRouterView,
        meta: {
            'label': 'word.registrations',
            'icon': menuRegistrationIcon,
            'iconCurrent': menuRegistrationIcon
        },
        children:[
            {
                path: '',
                name: 'registrationsIndex',
                component: Registrations,
                meta:{
                    seo: {
                        title: i18n.global.t('word.registrations')
                    }
                }
            },
            {
                path: 'create',
                name: 'registrationCreate',
                component: RegistrationCreate,
                meta:{
                    seo: {
                        title: i18n.global.t('word.new.registration')
                    }
                }
            }
        ]
    }
]
