export default ({ store, next }) => {
    if(!store.state.User.authenticated){
        next({
            name:'authLogin'
        })

        return false
    }

    next()
}


//export function middleWareIsAuthenticated({store, next}){
//    if(!store.state.User.authenticated){
//        next({
//            name:'authLogin'
//        })
//
//        return false
//    }
//
//    return next()
//}
//
