import { FieldInterface } from '@/models/forms/schema/Field.interface'
import { generateFieldFirstName } from '@/forms/common/fields/FieldFirstName'
import { generateFieldLastName } from '@/forms/common/fields/FieldLastName'
import i18n from "@/i18n";

export interface TeacherSchemaInterface {
    fields: {
        firstName: FieldInterface;
        lastName: FieldInterface;
        id: FieldInterface;
    },
    model:{
        firstName: string;
        lastName: string;
        id: string;
    }
}

export function generateTeacherSchema(): TeacherSchemaInterface{
    return {
        fields: {
            firstName: generateFieldFirstName(),
            lastName: generateFieldLastName(),
            id: {
                component: 'Input',
                type: 'text',
                model: 'id',
                label:{
                    text: i18n.global.t('word.id')
                },
                attributes: {
                    readonly: true
                }
            }
        },
        model:{
            firstName: '',
            lastName: '',
            id: ''
        }
    }
}
