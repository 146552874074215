// We can't use state instead of status to avoid conflicts with the states
export enum RegistrationStatus {
    OPTION = 'option',
    NEW = 'new',
    REGISTERED = 'registered',
    CANCELLED = 'cancelled',
    WAITING = 'waiting'
}
export type RegistrationStateType = RegistrationStatus.OPTION | RegistrationStatus.NEW | RegistrationStatus.REGISTERED | RegistrationStatus.CANCELLED


export enum RegistrationTypes {
    NEW = 'type.new',
    OPTION = 'type.option',
    REGISTRATION = 'type.registration',
    WAITING_LIST = 'type.waiting.list'
}

export enum RegistrationResult {
    FAILED = 'result.failed',
    SUCCESS = 'result.success'
}

export enum RegistrationInvoiceType {
    PRIVATE = 'private',
    COMPANY = 'company'
}

export type RegistrationResultType = RegistrationResult.FAILED | RegistrationResult.SUCCESS
