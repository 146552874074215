import i18n from '@/i18n';

const Agenda = () => import(/* webpackChunkName: "group-agenda" */ '@/views/app/agenda/AgendaIndex.vue')
import menuAgendaIcon from '@/assets/images/icons/menu-agenda-icon.svg'

export default [
    {
        path: '/agenda',
        name: 'agenda',
        component: Agenda,
        meta: {
            label: 'word.agenda',
            icon: menuAgendaIcon,
            iconCurrent: menuAgendaIcon,
            seo:{
                title: i18n.global.t('word.agenda')
            }
        }
    }
]
