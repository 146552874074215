import { ActionTree } from 'vuex'
import { StudentState } from '@/store/modules/student/Student'
import { StudentMutations } from '@/store/modules/student/Student.mutations'
import { AxiosResponse } from 'axios'
import { StudentInterface } from '@/models/student/Student.interface'

import { state } from '@/store/modules/student/Student'
import Api from '@/api/Api'
import { collect } from 'collect.js'
import { RegistrationInterface } from '@/models/registration/Registration.interface';
import {RegistrationStatus, RegistrationTypes} from '@/models/registration/Registration.types'

export enum StudentActions {
    GET_STUDENT = 'GET_STUDENT',
    UPDATE_STUDENT = 'UPDATE_STUDENT',
    DELETE_STUDENT = 'DELETE_STUDENT',
    SORT_REGISTRATIONS = 'SORT_REGISTRATIONS',
    SEND_VADEMECUM_REMINDER = 'SEND_VADEMECUM_REMINDER'
}

// actions
export const actions: ActionTree<StudentState, any> = {
    /**
     * Get student
     * @param {Commit} commit
     * @param {any} id
     * @returns {Promise<void>}
     */

    [StudentActions.GET_STUDENT]: async ({
        commit
    }, {
        id
    }) => {
        commit(StudentMutations.RESET_STATE)

        const studentResponse: AxiosResponse<StudentInterface> = await Api.students.showById(id)
        commit(StudentMutations.SET_STUDENT, studentResponse.data)
        commit(StudentMutations.SET_REGISTRATIONS, studentResponse.data.registrations.filter((registration: RegistrationInterface) => {
            return registration.status === RegistrationStatus.REGISTERED
        }))
        commit(StudentMutations.SET_WAITING_LISTS, studentResponse.data.registrations.filter((registration: RegistrationInterface) => {
            return registration.type === RegistrationTypes.WAITING_LIST
        }))

        const recommendedCoursesResponse: AxiosResponse<StudentInterface> = await Api.students.getRecommendedCourses(id)
        commit(StudentMutations.SET_RECOMMENDED_COURSES, recommendedCoursesResponse.data)
        commit(StudentMutations.SET_LOADING, false)
    },

    /**
     * Update student
     * @param {ActionContext<StudentState, any>} context
     * @returns {Promise<void>}
     */
    [StudentActions.UPDATE_STUDENT]: async (context) => {
        const model = context.state.studentSchema.model
        await Api.students.update(model.id, model)
    },

    /**
     * Delete student
     * @param {ActionContext<StudentState, any>} context
     * @returns {Promise<void>}
     */
    [StudentActions.DELETE_STUDENT]: async (context) => {
        await Api.students.delete(context.state.studentSchema.model.id)
        context.commit(StudentMutations.SET_STUDENT, null)
    },


    /**
     * Sort registrations
     * @param {Commit} commit
     * @param {any} filters
     */
    [StudentActions.SORT_REGISTRATIONS]: ({
        commit
    }, {
        filters
    }) => {
        commit(StudentMutations.UPDATE_STUDENT_REGISTRATIONS_FILTER, filters)

        let registrationsFiltered;
        const registrations = collect(state.registrations)
        const stateFilter =  state.studentRegistrationsFilter

        if(stateFilter.order === 'asc'){
            registrationsFiltered = registrations.sortBy(stateFilter.order_by).all()
        }else {
            registrationsFiltered = registrations.sortByDesc(stateFilter.order_by).all()
        }

        commit(StudentMutations.SET_REGISTRATIONS, registrationsFiltered);
    },

    /**
     * Send vademecum reminder
     * @param {Commit} commit
     * @param {StudentState} state
     */
    [StudentActions.SEND_VADEMECUM_REMINDER]: async ({
        state,
        commit
    }) => {
        try {
            await Api.students.sendVademecumReminder(state.student)

            commit(StudentMutations.SET_STUDENT_VADEMECUM_ORDERED, true)
        } catch(e){
            console.log(e)
        }
    }
}
