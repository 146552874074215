import i18n from '@/i18n'
import yup from '@/yup'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export function generateFieldLocation(options: any = {}){
    let location: FieldInterface = {
        component: 'Select',
        model: 'location',
        required: true,
        label:{
            text: i18n.global.t('word.location')
        },
        choices:[{
            label: '',
            value: ''
        }],
        validation: yup.string()
            .required()
            .label(i18n.global.t('word.location'))
    }

    location = Object.assign(options, location)

    return location
}
