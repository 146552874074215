import { mutations } from '@/store/modules/courses/Courses.mutations'
import { actions } from '@/store/modules/courses/Courses.actions'
import { coursesGetters } from '@/store/modules/courses/Courses.getters'

import { CourseInterface } from '@/models/course/Course.interface'
import { CoursesFilterInterface } from '@/models/courses/CoursesFilter.interface'
import PaginationInterface from "@/models/pagination/Pagination.interface";

/**
 * State interface
 */
export interface CoursesState {
    courses: CourseInterface[];
    pagination: PaginationInterface;
    loading: boolean;
    coursesFilter: CoursesFilterInterface;
}

/**
 * State
 * @type {{courses: null, coursesFilter: {order_by: string, order: string}, loading: boolean}}
 */
export const state: CoursesState = {
    courses: null,
    pagination: null,
    coursesFilter: {
        order: 'desc',
        order_by: 'stats.amountPlanned'
    },
    loading: false
}

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters: coursesGetters,
    actions,
    mutations
}
