import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export function generateFieldPhoneNumber(options: any = {}){
    let phoneNumber: FieldInterface = {
        component: 'Input',
        type: 'text',
        model: 'phoneNumber',
        required: true,
        label:{
            text: i18n.global.t('word.phone.number')
        },
        //validation: yup.string()
        //    .required()
        //    .min(10)
        //    .label(i18n.global.t('word.phone.number'))
    }

    phoneNumber = Object.assign(options, phoneNumber)

    return phoneNumber
}
