import i18n from '@/i18n'
import yup from '@/yup'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export function generateFieldCurrency(options: any = {}){
    let currency: FieldInterface = {
        component: 'Input',
        type: 'currency',
        model: 'price',
        required: true,
        label:{
            text: i18n.global.t('word.price')
        },
        validation: yup.number()
            .label(options.label || i18n.global.t('word.price'))
    }


    currency = Object.assign(options, currency)

    return currency
}
