import { mutations } from '@/store/modules/dashboard/Dashboard.mutations'
import { dashboardGetters } from '@/store/modules/dashboard/Dashboard.getters'
import { actions } from '@/store/modules/dashboard/Dasboard.actions'
import { CourseEntryInterface } from '@/models/course-entries/CourseEntry.interface'

/**
 * State interface
 */
export interface DashboardState {
    newEntries?: CourseEntryInterface[];
    newEntriesNextLink?: string;
    completedEntries?: CourseEntryInterface[];
    startingSoonEntries?: CourseEntryInterface[];
    loading: boolean;
}

/**
 * State
 * @type {{courseDates: null, courseDatesFilter: {teacher: string, dateRange: string, order_by: string, location: string, order: string}, loading: boolean}}
 */
export const initialState: DashboardState = {
    newEntries: null,
    completedEntries: null,
    startingSoonEntries: null,
    loading: false,
    newEntriesNextLink: null
}

const state = Object.assign({}, initialState)

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters: dashboardGetters,
    actions,
    mutations
}
