import { MutationTree } from 'vuex'
import { GeneralState } from '@/store/modules/general/General'

export enum GeneralMutations {
    SET_LAYOUT = 'SET_LAYOUT',
    SET_LANG = 'SET_LANG',
    SET_GLOBAL_MESSAGE = 'SET_GLOBAL_MESSAGE',
    SET_LAYOUT_CONTENT_CLASS = 'SET_LAYOUT_CONTENT_CLASS'
}

// mutations
export const mutations: MutationTree<GeneralState> = {
    [GeneralMutations.SET_GLOBAL_MESSAGE]: (state: GeneralState, message: string) => {
        state.globalMessage = message
    },

    [GeneralMutations.SET_LAYOUT]: (state: GeneralState, layout: string) => {
        state.layout = layout
    },

    [GeneralMutations.SET_LANG]: (state: GeneralState, lang: string) => {
        state.lang = lang
    },

    [GeneralMutations.SET_LAYOUT_CONTENT_CLASS]: (state: GeneralState, cssClass: string) => {
        state.layoutContentClass = cssClass
    }
}
