import { mutations } from '@/store/modules/courseEntries/CourseEntries.mutations'
import { actions } from '@/store/modules/courseEntries/CourseEntries.actions'
import { courseEntriesGetters } from '@/store/modules/courseEntries/CourseEntries.getters'
import { CourseEntryInterface } from '@/models/course-entries/CourseEntry.interface'

/**
 * State interface
 */
export interface CourseEntriesState {
    elearnings?: CourseEntryInterface[];
    loading: boolean;
}

/**
 * State
 * @type {{elearnings: null, loading: boolean}}
 */
export const state: CourseEntriesState = {
    elearnings: null,
    loading: false
}

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters: courseEntriesGetters,
    actions,
    mutations
}
