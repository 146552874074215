import { getters } from '@/store/modules/course/Course.getters'
import { actions } from '@/store/modules/course/Course.actions'
import { mutations } from '@/store/modules/course/Course.mutations'
import { CourseInterface } from '@/models/course/Course.interface'
import { CourseEntryInterface } from '@/models/course-entries/CourseEntry.interface'
import { CourseCompletedHeadingInterface } from '@/models/course/CourseCompletedHeading.interface'
import { CourseCompletedFilterInterface } from '@/models/course/CourseCompletedFilter.interface'

import { CoursePlannedHeadingInterface } from '@/models/course/CoursePlannedHeading.interface'
import { CoursePlannedFilterInterface } from '@/models/course/CoursePlannedFilter.interface'
import { StartDateNotification } from '@/models/startdate-notification/StartDateNotification.interface'
import { CourseSchemaInterface, generateCourseSchema } from '@/forms/course/Course.schema'
import { CostsInterface } from '@/models/course/CourseAdditionalCosts.interface'
import { CourseType, CourseTypes } from '@/models/course/Course.types'

import i18n from '@/i18n'
import {
    CourseStartDataMailingSchemaInterface, generateStartDateMailingSchema
} from '@/forms/startDateMailing/StartDateMailing.schema'
import { CourseArchivedFilterInterface } from '@/models/course/CourseArchivedFilter.interface'
import { CourseArchivedHeadingInterface } from '@/models/course/CourseArchivedHeading.interface'

/**
 * State interface
 */

export interface CourseState {
    course?: CourseInterface;
    startDateNotifications?: StartDateNotification[];
    loading: boolean;

    completed?: CourseEntryInterface[];
    completedHeadings?: CourseCompletedHeadingInterface[];
    completedFilter?: CourseCompletedFilterInterface;

    planned?: CourseEntryInterface[];
    plannedHeadings?: CoursePlannedHeadingInterface[];
    plannedFilter?: CoursePlannedFilterInterface;

    archived: CourseEntryInterface[];
    archivedHeadings?: CourseArchivedHeadingInterface[];
    archivedFilter?: CourseArchivedFilterInterface;

    courseSchema?: CourseSchemaInterface;
    courseStartDataMailingSchema?: CourseStartDataMailingSchemaInterface;
    prices?: Record<CourseType, Partial<CostsInterface>[]> | Partial<CostsInterface>[];
    pricesFilter?: CourseType;
    elearnings: CourseEntryInterface[];
}

/**
 * State
 * @type {{student: null, loading: boolean, recommendedCourses: null}}
 */
const courseSchema = generateCourseSchema()
const courseStartDataMailingSchema = generateStartDateMailingSchema()

export const initialState: CourseState = {
    course: null,
    loading: false,
    startDateNotifications: null,
    completed: null,
    completedHeadings: [{
            name: i18n.global.t('word.date'),
            key: 'startDate',
            sortable: true,
            class: 'td-date-w-badge'
        },
        {
            name: i18n.global.t('word.type'),
            key: 'type',
            sortable: true,
            class: 'td-course-type'
        },
        {
            name: i18n.global.t('word.location'),
            key: 'location.name',
            sortable: true,
            class: 'td-location'
        },
        {
            name: i18n.global.t('word.full.percentage'),
            key: 'stats.occupancyRate',
            sortable: true,
            class: 'td-course-occupancy-rate'
        },
        {
            name: i18n.global.t('word.places'),
            key: 'places',
            sortable: true,
            class: ''
        },
        {
            name: i18n.global.t('word.course.stat.cancellations'),
            key: 'stats.canceled',
            sortable: true,
            class: 'td-grade-list'
        }
    ],
    completedFilter: {
        order: 'asc',
        order_by: 'startDate'
    },

    planned: null,
    plannedHeadings: [{
        name: i18n.global.t('word.date'),
        key: 'startDate',
        sortable: true,
        class: 'td-date-w-badge'
    },
    {
        name: i18n.global.t('word.type'),
        key: 'type',
        sortable: true,
        class: 'td-course-type'
    },
    {
        name: i18n.global.t('word.location'),
        key: 'location.name',
        sortable: true,
        class: 'td-location'
    },
    {
        name: i18n.global.t('word.full.percentage'),
        key: 'stats.occupancyRate',
        sortable: true,
        class: 'td-course-occupancy-rate'
    },
    {
        name: i18n.global.t('word.places'),
        key: 'stats.totalSpots',
        sortable: true,
        class: ''
    },
    {
        name: i18n.global.t('word.course.stat.cancellations'),
        key: 'stats.canceled',
        sortable: true,
        class: 'td-grade-list'
    },
    {
        name: i18n.global.t('word.active'),
        key: 'active',
        sortable: true,
        class: 'td-grade-list'
    }],
    plannedFilter: {
        order: 'desc',
        order_by: 'startDate'
    },

    archived: null,
    archivedHeadings: [{
            name: i18n.global.t('word.date'),
            key: 'startDate',
            sortable: true,
            class: 'td-date-w-badge'
        },
        {
            name: i18n.global.t('word.type'),
            key: 'type',
            sortable: true,
            class: 'td-course-type'
        },
        {
            name: i18n.global.t('word.location'),
            key: 'location.name',
            sortable: true,
            class: 'td-location'
        },
        {
            name: i18n.global.t('word.places'),
            key: 'stats.totalSpots',
            sortable: true,
            class: ''
        },
        {
            name: i18n.global.t('word.course.stat.cancellations'),
            key: 'stats.canceled',
            sortable: false,
            class: 'td-grade-list'
        }
    ],
    archivedFilter: {
        order: 'asc',
        order_by: 'startDate'
    },
    elearnings: null,
    courseSchema,
    courseStartDataMailingSchema,
    prices: courseSchema.model.prices,
    pricesFilter: CourseTypes.classical
}

export const state = Object.assign({}, initialState);

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
