import i18n from '@/i18n';

const Login = () => import(/* webpackChunkName: "group-authentication" */ '@/views/auth/AuthLogin.vue')
const RequestPassword = () => import(/* webpackChunkName: "group-authentication" */ '@/views/auth/AuthRequestPassword.vue')

export default [
    {
        path: '/auth/login',
        name: 'authLogin',
        component: Login,
        meta:{
            roles: [],
            auth: false,
            middleware: [],
            layout: 'general/GeneralRouterView',
            seo:{
                title: i18n.global.t('word.login')
            }
        }
    },
    {
        path: '/auth/logout',
        name: 'authLogout',
        component: Login,
        meta:{
            roles: [],
            auth: false,
            middleware: [],
            layout: 'general/GeneralRouterView',
            label: 'word.logout',
            seo:{
                title: i18n.global.t('word.logout')
            }
        }
    },
    {
        path: '/auth/request-password',
        name: 'authRequestPassword',
        component: RequestPassword,
        meta:{
            roles: [],
            auth: false,
            middleware: [],
            layout: 'general/GeneralRouterView',
            seo:{
                title: i18n.global.t('word.request.password')
            }
        }
    }
]
