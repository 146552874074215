import { createRouter, createWebHistory } from 'vue-router'
import GeneralRoutes from '@/router/routes/General'
import CoursesRoutes from '@/router/routes/Courses'
import AgendaRoutes from '@/router/routes/Agenda'
import StudentRoutes from '@/router/routes/Students'
import TeacherRoutes from '@/router/routes/Teachers'
import AuthenticationRoutes from '@/router/routes/Auth'
import LocationRoutes from '@/router/routes/Locations'
import ExceptionRoutes from '@/router/routes/Exceptions'

import { GeneralActions } from '@/store/modules/general/General.actions'
import { store } from '@/store';
import { StoreModuleNames } from '@/store/ModuleNames'
import { GeneralMutations } from '@/store/modules/general/General.mutations'
import middleWareIsAuthenticated from '@/middlewares/isAuthenticated'
import middleware from "@grafikri/vue-middleware"
import { setSeoProperties } from '@/composables/seo/Seo';
import RegistrationsRoutes from '@/router/routes/Registrations'

const routes: any[] = [
    ...AuthenticationRoutes,
    ...GeneralRoutes,
    ...CoursesRoutes,
    ...AgendaRoutes,
    ...StudentRoutes,
    ...TeacherRoutes,
    ...LocationRoutes,
    ...RegistrationsRoutes,
    ...ExceptionRoutes
]

routes.forEach(route => {
    // Set meta
    if(!route?.meta){
        route.meta = {}
    }

    if(!route?.meta?.auth){
        route.meta = Object.assign((route.meta || {}), {
            auth: true
        })
    }

    if(!route?.meta?.middleware){
        route.meta = Object.assign((route.meta || {}), {
            middleware:[
                middleWareIsAuthenticated,
            ]
        })
    }

    if(route?.meta?.layout === undefined){
        route.meta.layout = 'layout/Layout'
    }
})

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach(middleware({ store }))

router.beforeEach(async (to, from, next) => {
    await store.dispatch(`${StoreModuleNames.General}/${GeneralActions.RESET_GLOBAL_MESSAGE}`)
    await store.commit(`${StoreModuleNames.General}/${GeneralMutations.SET_LAYOUT}`, to.meta.layout)
    await store.commit(`${StoreModuleNames.General}/${GeneralMutations.SET_LAYOUT_CONTENT_CLASS}`, null)
    await setSeoProperties(to?.meta?.seo)

    next()
})

export default router;
