import Api, {API_DATE_FORMAT} from '@/api/src/Api'
import { AxiosPromise } from 'axios';
import { RegistrationInterface } from '@/models/registration/Registration.interface';
import {dayjs} from "@/main";

export default class RegistrationsApi extends Api {
    constructor() {
        super()

        this.endPoint = 'registrations'
    }

    /**
     * Create resource
     * @param {{}} data
     * @returns {AxiosPromise}
     */
    create(data: Partial<RegistrationInterface>): AxiosPromise {
        data.invoiceAddress.houseNumber = +data.invoiceAddress.houseNumber

        if(typeof data.student === 'object' && dayjs(data.student.dateOfBirth).isValid()) {
            data.student.dateOfBirth = dayjs(data.student.dateOfBirth).format(API_DATE_FORMAT)
        }

        return this.axios.post(`${this.endPoint}`, data)
    }

    /**
     * Update
     * @param {string} id
     * @param {RegistrationInterface} data
     * @returns {string}
     */
    update(id: string, data) {
        // Send only courseEntry id
        data = JSON.parse(JSON.stringify(data))
        if (data.courseEntry) {
            data.courseEntry = {
                '@id': data.courseEntry['@id']
            }
        }
        
        return super.update(id, data);
    }

    /**
     * Cancel registration
     * @param {RegistrationInterface} registration
     * @returns {AxiosPromise}
     */
    cancel(registration: RegistrationInterface): AxiosPromise {
        return this.axios.post(`${this.endPoint}/${registration.id}/cancel`)
    }

    /**
     * Export certificate
     * @param {RegistrationInterface} registration
     * @returns {Promise<AxiosResponse<any>>}
     */
    exportCertificate(registration: RegistrationInterface){
        window.open(`${process.env.VUE_APP_API_URL}/${this.endPoint}/${registration.id}/generate-certificate`)
    }

    exportStapConfirmation(registration: RegistrationInterface){
        window.open(`${process.env.VUE_APP_API_URL}/${this.endPoint}/${registration.id}/generate-stap-confirmation`)
    }

    exportStapRegistrations(){
        window.open(`${process.env.VUE_APP_API_URL}/export/stap-registrations`)
    }
}