import i18n from '@/i18n'
import yup from '@/yup'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export function generateFieldName(options: any = {}){
    let name: FieldInterface = {
        component: 'Input',
        type: 'text',
        model: 'name',
        required: true,
        label:{
            text: i18n.global.t('word.name')
        },
        validation: yup.string()
            .required()
            .label(i18n.global.t('word.name'))
    }

    name = Object.assign(options, name)

    return name
}
