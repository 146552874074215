import { ActionTree } from 'vuex'
import { CoursesState } from '@/store/modules/courses/Courses'
import { state } from '@/store/modules/courses/Courses'
import { CoursesMutations } from '@/store/modules/courses/Courses.mutations'
import { AxiosResponse } from 'axios'
import { CourseInterface } from '@/models/course/Course.interface'
import Api from '@/api/Api'
import { apiFormatOrderFilter } from '@/api/src/Api'

export enum CoursesActions {
    GET_COURSES = 'getCourses'
}

// actions
export const actions: ActionTree<CoursesState, any> = {
    [CoursesActions.GET_COURSES]: async ({
        commit
    }, {
        page
    }) => {
        commit(CoursesMutations.SET_LOADING, true)

        const courses: AxiosResponse<CourseInterface> = await Api.courses.index({
            params: {
                _page: page,
                itemsPerPage: process.env.VUE_APP_ENV === 'staging' ? 99 : 20,
                ...apiFormatOrderFilter(state.coursesFilter)
            }
        })

        commit(CoursesMutations.SET_COURSES, courses.data['hydra:member'])
        commit(CoursesMutations.SET_COURSES_PAGINATION, courses.data['hydra:view'])
        commit(CoursesMutations.SET_LOADING, false)
    }
}
