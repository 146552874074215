import axios from '@/api/src/Axios'
import { AxiosInstance, AxiosPromise } from 'axios'
import { Options } from '@/api/src/Interfaces'
import merge from 'deepmerge'
import { SortType } from '@/models/common/Types'

export const axiosInstance = axios;

export default class ApiInstance {
    public axios: AxiosInstance
    public endPoint!: string

    constructor() {
        this.axios = axiosInstance
    }

    /**
     * Show all resources
     * @param {Options} options
     * @returns {AxiosPromise}
     */
    index(options: Record<string, any> = {}): AxiosPromise {
        options = merge({
            params: {
                'deletedAt[null]': true,
                'archivedAt[null]': true,
                'itemsPerPage': 999
            }
        }, options)

        return this.axios.get(`${this.endPoint}`, options)
    }

    /**
     * Show single resources
     * @param {string} id
     * @param {Options} options
     * @returns {AxiosPromise}
     */
    showById(id: string, options: Options = {}): AxiosPromise {
        return this.axios.get(`${this.endPoint}/${id}`, options)
    }

    /**
     *
     * @param {string} uri
     * @param {Options} options
     * @returns {AxiosPromise}
     */
    showByUri(uri: string, options: Options = {}): AxiosPromise {
        return this.axios({url:uri, baseURL: process.env.VUE_APP_API_BASE_URL, params: options})
    }

    /**
     * Create resource
     * @param {{}} data
     * @returns {AxiosPromise}
     */
    create(data = {}): AxiosPromise {
        return this.axios.post(`${this.endPoint}`, data)
    }

    /**
     * Update resource
     * @param {string} id
     * @param {{}} data
     * @returns {AxiosPromise}
     */
    update(id: string, data = {}): AxiosPromise {
        return this.axios.put(`${this.endPoint}/${id}`, data)
    }

    /**
     * Updated resource by uri
     * @param {string} uri
     * @param {{}} data
     * @returns {AxiosPromise}
     */
    updateByUri(uri: string, data = {}): AxiosPromise {
        return this.axios({url:uri, method:'PUT', baseURL: process.env.VUE_APP_API_BASE_URL, data})
        //return this.axios.put(uri, data)
    }

    /**
     * Delete resource
     * @param {string} id
     * @returns {AxiosPromise}
     */
    delete(id: string): AxiosPromise {
        //return this.axios.put(`${this.endPoint}/${id}`, {
        //    deletedAt: new Date
        //})
        return this.axios.delete(`${this.endPoint}/${id}`)
    }

    deleteByUri(uri: string, data = {}): AxiosPromise {
        return this.axios({url:uri, method:'DELETE', baseURL: process.env.VUE_APP_API_BASE_URL, data})
    }
}

export const API_DATE_FORMAT = 'YYYY-MM-DD'
export const API_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export interface ApiFilterInterface {
    order?: SortType;
    order_by?: unknown;
}

export function apiFormatOrderFilter(filters: ApiFilterInterface){
    return {
        [`order[${filters.order_by}]`]: filters.order.toUpperCase()
    }
}
