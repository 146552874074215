import i18n from '@/i18n';

const Teachers = () => import(/* webpackChunkName: "group-teachers" */ '@/views/app/teachers/TeachersIndex.vue')
const TeacherSingle = () => import(/* webpackChunkName: "group-teachers" */ '@/views/app/teachers/TeacherSingle.vue')
const TeacherCreate = () => import(/* webpackChunkName: "group-teachers" */ '@/views/app/teachers/TeacherCrud.vue')
const TeacherUpdate = () => import(/* webpackChunkName: "group-teachers" */ '@/views/app/teachers/TeacherCrud.vue')
const GeneralRouterView = () => import(/* webpackChunkName: "group-teachers" */ '@/components/general/GeneralRouterView.vue')
import menuTeacherIcon from '@/assets/images/icons/menu-teacher-icon.svg'

export default [
    {
        path: '/teachers',
        name: 'teachers',
        component: GeneralRouterView,
        meta: {
            'label': 'word.teachers',
            'icon': menuTeacherIcon,
            'iconCurrent': menuTeacherIcon
        },
        children:[
            {
                path: '',
                name: 'teachersIndex',
                component: Teachers,
                meta:{
                    seo: {
                        title: i18n.global.t('word.teachers')
                    }
                }
            },
            {
                path: ':id',
                name: 'teacherSingle',
                component: TeacherSingle,
                meta: {
                    seo: {
                        title: i18n.global.t('word.teacher')
                    }
                }
            },
            {
                path: 'create',
                name: 'teacherCreate',
                component: TeacherCreate,
                meta: {
                    seo: {
                        title: i18n.global.t('word.create.teacher')
                    }
                }
            },
            {
                path: ':id/update',
                name: 'teacherUpdate',
                component: TeacherUpdate,
                meta: {
                    seo: {
                        title: i18n.global.t('word.update.teacher')
                    }
                }
            }
        ]
    }
]
