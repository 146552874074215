import { StudentsFilterInterface } from '@/models/students/StudentsFilter.interface'
import { StudentInterface } from '@/models/student/Student.interface'
import { studentsGetters } from '@/store/modules/students/Students.getters'
import { actions } from '@/store/modules/students/Students.actions'
import { mutations } from '@/store/modules/students/Students.mutations'
import PaginationInterface from '@/models/pagination/Pagination.interface'

/**
 * State interface
 */
export interface StudentsState {
    students?: StudentInterface[];
    loading: boolean;
    pagination: PaginationInterface;
    studentsFilter: StudentsFilterInterface;
}

/**
 * State
 * @type {{students: null, studentsFilter: {order_by: string, order: string}, loading: boolean}}
 */
export const state: StudentsState = {
    students: null,
    pagination: null,
    studentsFilter: {
        order: 'asc',
        order_by: 'name'
    },
    loading: false
}

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters: studentsGetters,
    actions,
    mutations
}
