import { ActionTree } from 'vuex'
import { CourseEntriesState } from '@/store/modules/courseEntries/CourseEntries'
import { CourseEntriesMutations } from '@/store/modules/courseEntries/CourseEntries.mutations'
import { AxiosResponse } from 'axios'
import { CourseEntryInterface } from '@/models/course-entries/CourseEntry.interface'
import Api from '@/api/Api'
import { CourseTypes } from '@/models/course/Course.types'

export enum CourseEntriesActions {
    GET_ELEARNINGS = 'GET_ELEARNINGS'
}

// actions
export const actions: ActionTree<CourseEntriesState, any> = {
    [CourseEntriesActions.GET_ELEARNINGS]: async ({
        commit
    }) => {
        try {
            const elearnings: AxiosResponse<CourseEntryInterface> = await Api.courseEntries.index({
                params: {
                    'type': CourseTypes.elearning
                }
            })

            commit(CourseEntriesMutations.SET_ELEARNINGS, elearnings.data['hydra:member'])
        } catch(e){
            console.log(e)
        }
    }
}
