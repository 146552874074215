import isAuthenticated from '@/middlewares/isAuthenticated'

const Dashboard = () => import(/* webpackChunkName: "group-general" */ '@/views/app/dashboard/Dashboard.vue')

import menuDashboardIcon from '@/assets/images/icons/menu-dashboard-icon.svg'
import i18n from '@/i18n';

export default [
  {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            label: 'word.dashboard',
            icon: menuDashboardIcon,
            iconCurrent: menuDashboardIcon,
            middleware: [isAuthenticated/*, isAdmin*/],
            seo: {
                title: i18n.global.t('word.dashboard')
            }
        }
    }
]
