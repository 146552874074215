import { MutationTree } from 'vuex'
import { StudentInterface } from '@/models/student/Student.interface'
import { StudentsState } from '@/store/modules/students/Students'
import { StudentsFilterInterface } from '@/models/students/StudentsFilter.interface'
import PaginationInterface from '@/models/pagination/Pagination.interface'

export enum StudentsMutations {
    SET_STUDENTS = 'SET_STUDENTS',
    SET_STUDENTS_PAGINATION = 'SET_STUDENTS_PAGINATION',
    UPDATE_STUDENTS_FILTER = 'UPDATE_STUDENTS_FILTER',
    SET_LOADING = 'SET_LOADING'
}

// mutations
export const mutations: MutationTree<StudentsState> = {
    [StudentsMutations.SET_STUDENTS]: (state: StudentsState, students: StudentInterface[]) => {
        state.students = students
    },

    [StudentsMutations.SET_STUDENTS_PAGINATION]: (state: StudentsState, pagination: PaginationInterface) => {
        state.pagination = pagination
    },

    [StudentsMutations.UPDATE_STUDENTS_FILTER]: (state: StudentsState, studentsFilter: StudentsFilterInterface) => {
        state.studentsFilter = Object.assign(state.studentsFilter, studentsFilter);
    },

    [StudentsMutations.SET_LOADING]: (state: StudentsState, loading: boolean) => {
        state.loading = loading
    }
}
