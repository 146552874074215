import i18n from '@/i18n'
import yup from '@/yup'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export function generateFieldTeacher(options: any = {}){
    let teacher: FieldInterface = {
        component: 'Select',
        model: 'teacher',
        required: true,
        label:{
            text: i18n.global.t('word.teacher')
        },
        choices:[{
            label: '',
            value: ''
        }],
        validation: yup.string()
            .required()
            .label(i18n.global.t('word.teacher'))
    }

    teacher = Object.assign(teacher, options)

    return teacher
}
