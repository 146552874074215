import { createStore } from 'vuex'
import General from '@/store/modules/general/General'
import Agenda from '@/store/modules/agenda/Agenda'
import User from '@/store/modules/user/User'
import Teachers from '@/store/modules/teachers/Teachers'
import Teacher from '@/store/modules/teacher/Teacher'
import Locations from '@/store/modules/locations/Locations'
import Location from '@/store/modules/location/Location'
import Course from '@/store/modules/course/Course'
import Courses from '@/store/modules/courses/Courses'
import CourseEntries from '@/store/modules/courseEntries/CourseEntries'
import CourseEntry from '@/store/modules/courseEntry/CourseEntry'
import Registration from '@/store/modules/registration/Registration'
import Registrations from '@/store/modules/registrations/Registrations'
import Students from '@/store/modules/students/Students'
import Student from '@/store/modules/student/Student'
import Dashboard from '@/store/modules/dashboard/Dashboard'
import { createLogger } from 'vuex'
import Auth from '@/store/modules/auth/Auth'
import Seo from '@/store/modules/seo/Seo'
//import createPersistedState from 'vuex-persistedstate'

export const store = createStore({
  plugins: process.env.NODE_ENV === 'development' ? [createLogger()] : [/*createPersistedState()*/],
  modules: {
    General,
    User,
    Agenda,
    Teachers,
    Teacher,
    Locations,
    Location,
    Courses,
    Course,
    CourseEntries,
    CourseEntry,
    Registration,
    Registrations,
    Students,
    Student,
    Dashboard,
    Auth,
    Seo
  }
})

export default store
