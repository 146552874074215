import { ActionTree } from 'vuex'
import { AgendaMutations } from '@/store/modules/agenda/Agenda.mutations'
import { AxiosResponse } from 'axios'
import { CourseDateInterface } from '@/models/course/CourseDate.interface'
import { AgendaState, state } from '@/store/modules/agenda/Agenda'
import { dateStringToFilterParams } from '@/functions'
import Api from '@/api/Api'
import { StoreModuleNames } from '@/store/ModuleNames'
import { LocationsActions } from '@/store/modules/locations/Locations.actions'
import { TeachersActions } from '@/store/modules/teachers/Teachers.actions'
import { apiFormatOrderFilter } from '@/api/src/Api';
import { GeneralActions } from '@/store/modules/general/General.actions';

export enum AgendaActions {
    GET_COURSE_DATES = 'GET_COURSE_DATES',
    GET_COURSE_DATES_FILTER = 'GET_COURSE_DATES_FILTER',
    GET_COURSE_DATES_FILTER_TEACHERS_OPTIONS = 'GET_COURSE_DATES_FILTER_TEACHERS_OPTIONS',
    GET_COURSE_DATES_FILTER_LOCATIONS_OPTIONS = 'GET_COURSE_DATES_FILTER_LOCATIONS_OPTIONS',
    GET_DATA = 'GET_DATA'
}

// actions
export const actions: ActionTree<AgendaState, any> = {
    [AgendaActions.GET_COURSE_DATES]: async ({
        commit,
        dispatch
    }) => {
        commit(AgendaMutations.SET_LOADING, true)

        try {

            const filters = Object.assign({}, state.courseDatesFilter)

            const dateRange = dateStringToFilterParams(filters.dateRange)
            const filterParams = {
                ...dateRange,
                isCompleted: false,
                gradesCompleted: false,
                ...apiFormatOrderFilter(filters)
            }

            if (state.courseDatesFilterSchema.model.teacher) {
                filterParams['teacher.id'] = state.courseDatesFilterSchema.model.teacher
            }

            if (state.courseDatesFilterSchema.model.location) {
                filterParams['courseEntry.location.id'] = state.courseDatesFilterSchema.model.location
            }

            const response: AxiosResponse<CourseDateInterface> = await Api.courseDates.index({
                params: filterParams
            })

            commit(AgendaMutations.SET_COURSE_DATES, response.data['hydra:member'])
            commit(AgendaMutations.SET_LOADING, false)
        } catch(e){
            console.log(e)
            commit(AgendaMutations.SET_LOADING, false)
            dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    [AgendaActions.GET_COURSE_DATES_FILTER_TEACHERS_OPTIONS]: async (context) => {
        context.commit(AgendaMutations.SET_COURSE_DATES_FILTER_TEACHERS_OPTIONS, context.rootGetters['Teachers/getTeachersOptions'])
    },

    [AgendaActions.GET_COURSE_DATES_FILTER_LOCATIONS_OPTIONS]: async (context) => {
        context.commit(AgendaMutations.SET_COURSE_DATES_FILTER_LOCATIONS_OPTIONS, context.rootGetters['Locations/getLocationsOptions'])
    },

    [AgendaActions.GET_DATA]: async ({ dispatch }) => {
        try {
            await dispatch(`${StoreModuleNames.Locations}/${LocationsActions.GET_LOCATIONS}`, null, {
                root: true
            })
            await dispatch(`${StoreModuleNames.Teachers}/${TeachersActions.GET_TEACHERS}`, null, {
                root: true
            })
            await dispatch(`${AgendaActions.GET_COURSE_DATES_FILTER_TEACHERS_OPTIONS}`)
            await dispatch(`${AgendaActions.GET_COURSE_DATES_FILTER_LOCATIONS_OPTIONS}`)
        } catch(e){
            console.log(e)

            dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    }
}
