import yup from '@/yup'
import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'
import { generateCountryChoices } from '@/functions';


export interface AddressFieldInterface{
    streetName: FieldInterface;
    zipCode: FieldInterface;
    houseNumber: FieldInterface;
    addition: FieldInterface;
    countryCode: FieldInterface;
    city: FieldInterface;
}

export interface AddressSchemaModelInterface {
    name: string;
    streetName: string;
    zipCode: string;
    houseNumber: string;
    addition: string;
    countryCode: string;
    city: string;
}

export interface AddressSchemaValidationInterface {
    name: string;
    streetName: any;
    zipCode: any;
    houseNumber: any;
    addition: any;
    countryCode: any;
    city: any;
}

export interface AddressSchemaInterface {
    fields: AddressFieldInterface,
    model: AddressSchemaModelInterface,
    validation: any
}

export function generateAddressFieldsSchema(): AddressFieldInterface {
    return {
        streetName: {
            component: 'Input',
            type: 'text',
            model: 'streetName',
            required: true,
            label: {
                text: i18n.global.t('word.street.name')
            }
        },
        zipCode: {
            component: 'Input',
            type: 'text',
            model: 'zipCode',
            required: true,
            label: {
                text: i18n.global.t('word.zipcode')
            }
        },
        houseNumber: {
            component: 'Input',
            type: 'text',
            model: 'houseNumber',
            required: true,
            label: {
                text: i18n.global.t('abbr.house.number')
            }
        },
        addition: {
            component: 'Input',
            type: 'text',
            model: 'addition',
            required: true,
            label: {
                text: i18n.global.t('abbr.house.number.addition')
            }
        },
        countryCode: {
            component: 'Select',
            model: 'countryCode',
            required: true,
            label: {
                text: i18n.global.t('word.country.code')
            },
            choices:generateCountryChoices()
        },
        city: {
            component: 'Input',
            type: 'text',
            model: 'city',
            required: true,
            label: {
                text: i18n.global.t('word.city')
            }
        }
    }
}

export function generateAddressModelSchema(): AddressSchemaModelInterface {
    return {
        name: '',
        streetName: '',
        zipCode: '',
        houseNumber: '',
        addition: '',
        countryCode: 'NL',
        city: ''
    }
}

export function generateAddressValidationSchema(): Record<any, any> {
    return {
        streetName:yup.string()
            .required()
            .label(i18n.global.t('word.street.name')),
        zipCode:yup.string()
            .required()
            .label(i18n.global.t('word.zipcode')),
        houseNumber: yup.string()
            .required()
            .label(i18n.global.t('abbr.house.number')),
        addition: yup.string()
            .nullable()
            .label(i18n.global.t('abbr.house.number.addition')),
        countryCode: yup.string()
            .required()
            .label(i18n.global.t('word.country.code')),
        city: yup.string()
            .required()
            .label(i18n.global.t('word.city'))
    }
}

export function generateAddressSchema(): AddressSchemaInterface{
    return {
        fields: generateAddressFieldsSchema(),
        model: generateAddressModelSchema(),
        validation: generateAddressValidationSchema()
    }
}
