import { CoursePhases } from '@/models/course-entries/Types'

export enum CourseTypes {
    classical = 'classical',
    webinar = 'webinar',
    elearning = 'elearning',
}

export type CourseOfferedByType = 'SOHF' | 'vitakruid';
export type CourseSkillLevelType = 1 | 2 | 3;
export type CourseType = CourseTypes.classical | CourseTypes.webinar | CourseTypes.elearning;
export type CourseCompletedFilterType =  'startDate' | 'type' | 'location.name' | 'stats.occupancyRate' | 'stats.canceled' | 'places' | 'gradelist'
export type CoursePlannedFiltertype = 'startDate' | 'type' | 'location.name' | 'stats.occupancyRate' | 'stats.canceled' |  'stats.totalSpots' | 'waitingList' | 'active';
export type CourseArchivedFiltertype = 'startDate' | 'type' | 'location.name' | 'stats.occupancyRate' | 'stats.canceled' | 'stats.totalSpots' | 'gradeList' | 'active';
export type CoursePhaseType = CoursePhases.earlyBird | CoursePhases.guaranteedStart | CoursePhases.lastRemaining | CoursePhases.elearning
export type CourseCertificationType = 'type.diploma' | 'type.certificate'

export const courseTypes: CourseType[] = [
    CourseTypes.classical,
    CourseTypes.webinar,
    CourseTypes.elearning
]

export const coursePhases: CoursePhaseType[] = [
    CoursePhases.earlyBird,
    CoursePhases.guaranteedStart,
    CoursePhases.lastRemaining,
    CoursePhases.elearning
]
