import { GetterTree } from 'vuex'
import { CourseState } from '@/store/modules/course/Course'
import i18n from '@/i18n'
import { CourseStatsInterface } from '@/models/course/CourseStats.interface'
import { collect } from 'collect.js'
import { formatStringToDecimal } from '@/functions'
import CourseStartDateNotificationsModalRow from '@/components/courses/CourseStartDateNotificationsModalRow.vue'

interface CourseStatsGetterInterface {
    title: string;
    value: number|string;
}

export enum CourseGetters {
    GET_STATS = 'getStats',
    GET_PRICES = 'getPrices',
    GET_STARTDATE_MAILING_OPTIONS = 'getStartDateMailingOptions',
    GET_CERTIFICATION_TYPE_TEXT = 'getCertificationtypeText'
}

export const getters: GetterTree<CourseState, any> = {
    [CourseGetters.GET_STATS](state: CourseState){
        if(state.course) {
            const stats: CourseStatsInterface = state.course.stats

            const courseStats: CourseStatsGetterInterface[] = [
                {
                    title: i18n.global.t('word.course.stat.classical.registrations'),
                    value: stats.classicalRegistrations
                },
                {
                    title: i18n.global.t('word.course.stat.elearning.registrations'),
                    value: stats.elearningRegistrations
                },
                {
                    title: i18n.global.t('word.course.stat.cancellations'),
                    value: stats.cancellations
                },
                {
                    title: i18n.global.t('word.course.stat.waitinglist.registrations'),
                    value: stats.waitingListRegistrations
                },
                {
                    title: i18n.global.t('word.course.stat.occupancy.rate'),
                    value: `${Math.ceil(stats.occupancyRate)}%`
                },
                {
                    title: i18n.global.t('word.course.stat.options'),
                    value: `${Math.ceil(stats.options)}`
                }
            ]

            return courseStats;
        }
    },

    [CourseGetters.GET_PRICES](state: CourseState){
        if(state.courseSchema){
            const modelPrices = state.courseSchema.model.prices || []
            const costs = state.courseSchema.model.additionalCosts || []

            return collect([...modelPrices, ...costs])
                .groupBy('type')
                .each((items: any) => {
                    return items.items.map(item => {
                        item.amount = formatStringToDecimal(item.amount)
                        return item
                    })
                })
                .all()
        }

        return []
    },

    [CourseGetters.GET_STARTDATE_MAILING_OPTIONS](state: CourseState){
        if(state.planned && state.planned.length > 0) {
            return state.planned.map(entry => {
                return {
                    label: i18n.global.d(new Date(entry.startDate), 'shortDate'),
                    value: entry.id,
                    component: CourseStartDateNotificationsModalRow,
                    componentProps:{
                        courseEntry: entry
                    }
                }
            })
        }
    },

    /**
     * Get certification type text
     * @param {CourseState} state
     * @returns {string}
     */
    [CourseGetters.GET_CERTIFICATION_TYPE_TEXT](state: CourseState){
        let text = ''

        if(state.course) {
            if (state.course.certificationType === 'type.diploma') {
                text = i18n.global.t('word.certification.type.diploma')
            }

            if (state.course.certificationType === 'type.certificate') {
                text = i18n.global.t('word.certification.type.certificate')
            }
        }

        return text;
    }
}
