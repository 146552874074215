import { MutationTree } from 'vuex'
import { initialState, SeoState } from '@/store/modules/seo/Seo';

export enum SeoMutations {
    SET_TITLE = 'SET_TITLE',
    RESET_STATE = 'RESET_STATE'
}

// mutations
export const mutations: MutationTree<SeoState> = {
    [SeoMutations.SET_TITLE]: (state: SeoState, title: string) => {
        state.title = `${title} | SOHF.nl`
    },

    [SeoMutations.RESET_STATE]: (state: SeoState) => {
        Object.assign(state, initialState)
    }
}
