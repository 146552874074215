import * as yup from 'yup'

yup.setLocale({
    mixed:{
        required: 'Er is geen <b>${label}</b> ingevuld.'
    },
    string:{
        email: 'Geen geldig <b>${label}</b>',
        min: '<b>${label}</b> moet minimaal ${min} karakters bevatten',
        max: '<b>${label}</b> mag maximaal ${min} karakters bevatten'
    }
})

export default yup



