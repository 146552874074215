import { LocationInterface } from '@/models/locations/Location.interface'
import { actions } from '@/store/modules/locations/Locations.actions'
import { locationGetters } from '@/store/modules/locations/Locations.getters'
import { mutations } from '@/store/modules/locations/Locations.mutations'
import { LocationsFilterInterface } from '@/models/locations/LocationsFilter.interface';

/**
 * State interface
 */
export interface LocationState {
    locations: LocationInterface[];
    locationsFilter: LocationsFilterInterface;
}

/**
 * State
 * @type {{locationsFilter: {order_by: string, order: string}, locations: any[]}}
 */
const state: LocationState = {
    locations: [],
    locationsFilter: {
        order: 'asc',
        order_by: 'name'
    }
}

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters: locationGetters,
    actions,
    mutations
}
