import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export function generateFieldInsertion(options: any = {}){
    let insertion: FieldInterface = {
        component: 'Input',
        type: 'text',
        model: 'insertion',
        required: true,
        label:{
            text: i18n.global.t('abbr.insertion')
        },
        //validation: yup.string()
        //    .nullable()
        //    .label(i18n.global.t('abbr.insertion'))
    }

    insertion = Object.assign(options, insertion)

    return insertion
}
