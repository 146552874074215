import { getters } from '@/store/modules/student/Student.getters'
import { actions } from '@/store/modules/student/Student.actions'
import { mutations } from '@/store/modules/student/Student.mutations'
import { CourseInterface } from '@/models/course/Course.interface'
import { StudentInterface } from '@/models/student/Student.interface'
import { RegistrationInterface } from '@/models/registration/Registration.interface'
import { StudentRegistrationFilterInterface } from '@/models/student/StudentRegistrationFilter.interface'
import { generateStudentSchema, StudentSchemaInterface } from '@/forms/student/Student.schema'

/**
 * State interface
 */
export interface StudentState {
    student?: StudentInterface;
    registrations?: RegistrationInterface[];
    waitingLists?: RegistrationInterface[];
    loading: boolean;
    recommendedCourses: CourseInterface[];
    studentSchema: StudentSchemaInterface;
    studentRegistrationsFilter: StudentRegistrationFilterInterface;
}

/**
 * State
 * @type {{registrations: null, student: null, studentSchema: StudentSchemaInterface, loading: boolean, studentRegistrationsFilter: {order_by: null, order: string}, recommendedCourses: null}}
 */
export const initialState: StudentState = {
    student: null,
    loading: false,
    registrations: null,
    waitingLists: null,
    recommendedCourses: null,
    studentSchema: generateStudentSchema(),
    studentRegistrationsFilter: {
        order: 'asc',
        order_by: null
    }
}

export const state = JSON.parse(JSON.stringify(initialState));

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
