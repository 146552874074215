import i18n from '@/i18n'
import yup from '@/yup'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export function generateFieldId(options: any = {}){
    let id: FieldInterface = {
        component: 'Input',
        type: 'text',
        model: 'id',
        required: true,
        label:{
            text: i18n.global.t('word.id')
        },
        validation: yup.string()
            .required()
            .label(i18n.global.t('word.id'))
    }

    id = Object.assign(options, id)

    return id
}
