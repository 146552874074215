import { ActionTree } from 'vuex'
import { StudentsState } from '@/store/modules/students/Students'
import { StudentsMutations } from '@/store/modules/students/Students.mutations'
import { AxiosResponse } from 'axios'
import { StudentInterface } from '@/models/student/Student.interface'
import Api from '@/api/Api'

export enum StudentsActions {
    GET_STUDENTS = 'GET_STUDENTS'
}

// actions
export const actions: ActionTree<StudentsState, any> = {
    [StudentsActions.GET_STUDENTS]: async ({
        commit
    }, {
        page
    }) => {
        commit(StudentsMutations.SET_LOADING, true)

        const response: AxiosResponse<StudentInterface> = await Api.students.index({
            params: {
                _page: page
            }
        })

        commit(StudentsMutations.SET_STUDENTS, response.data['hydra:member'])
        commit(StudentsMutations.SET_STUDENTS_PAGINATION, response.data['hydra:view'])
        commit(StudentsMutations.SET_LOADING, false)
    }
}
