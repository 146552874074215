import { CoursePhaseType } from '@/models/course/Course.types'

export enum CoursePhases {
    earlyBird = 'earlyBird',
    guaranteedStart = 'guaranteedStart',
    lastRemaining = 'lastRemaining',
    elearning = 'elearning'
}

export type CourseEntrySpotType = {
    type: CoursePhaseType;
    amount: number;
}

export type CourseEntryPlatformType = 'webinargeek' | 'zoom';

export enum CourseEntryPlatformTypes {
    webinargeek = 'webinargeek',
    zoom = 'zoom'
}
