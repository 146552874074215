import { CostsInterface } from '@/models/course/CourseAdditionalCosts.interface'
import { coursePhases, CourseTypes, courseTypes } from '@/models/course/Course.types'
import { CoursePhases } from '@/models/course-entries/Types'

export function generateCourseCost(options?: Partial<CostsInterface>): CostsInterface {
    const costs: CostsInterface = {
        '@type': 'Cost',
        type: CourseTypes.classical,
        phase: CoursePhases.earlyBird,
        isRequired: true,
        name: '',
        amount: 0
    }

    return Object.assign(costs, options)
}

export function generateCourseSchemaCosts(): CostsInterface[] {
    const costs = []

    for (const courseType of courseTypes) {
        if(courseType !== CourseTypes.elearning) {
            for (const phase of coursePhases) {
                if (phase !== CoursePhases.elearning) {
                    const generatedCosts = generateCourseCost({
                        '@type': 'Price',
                        type: courseType,
                        phase: phase,
                        isRequired: true,
                        name: '',
                        amount: 0
                    })

                    costs.push(generatedCosts)
                }
            }
        } else {
            const elearningCosts = generateCourseCost({
                '@type': 'Price',
                type: courseType,
                phase: CoursePhases.elearning,
                isRequired: true,
                name: '',
                amount: 0
            })

            costs.push(elearningCosts)
        }
    }

    return costs
}
