import { mutations } from "@/store/modules/courseEntry/CourseEntry.mutations";
import { actions } from "@/store/modules/courseEntry/CourseEntry.actions";
import { getters } from "@/store/modules/courseEntry/CourseEntry.getters";
import { CourseEntryInterface } from "@/models/course-entries/CourseEntry.interface";
import { RegistrationInterface } from "@/models/registration/Registration.interface";
import {
  CourseEntrySchemaInterface,
  generateCourseEntrySchema,
} from "@/forms/courseEntry/CourseEntry.schema";
import PaginationInterface from "@/models/pagination/Pagination.interface";

/**
 * State interface
 */
export interface CourseEntryState {
  courseEntries?: CourseEntryInterface[];
  activeCourseEntries?: CourseEntryInterface[];
  courseEntry?: CourseEntryInterface;
  exportLink?: string;
  exportBulkLink?: string;
  registrations?: RegistrationInterface[];
  registrationsPagination?: PaginationInterface;
  waitingLists?: RegistrationInterface[];
  cancellations?: RegistrationInterface[];
  loading: boolean;
  courseEntrySchema: CourseEntrySchemaInterface;
}

/**
 * State
 * @type {{courseEntry: null, loading: boolean, courseEntries: null}}
 */

export const initialState: CourseEntryState = {
  courseEntries: null,
  activeCourseEntries: null,
  courseEntry: null,
  exportLink: null,
  exportBulkLink: null,
  registrations: null,
  registrationsPagination: null,
  waitingLists: null,
  cancellations: null,
  loading: false,
  courseEntrySchema: generateCourseEntrySchema(),
};

export const state = Object.assign({}, initialState);

/**
 * Export module
 */
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
