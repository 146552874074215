import { MutationTree } from 'vuex'
import { DashboardState } from '@/store/modules/dashboard/Dashboard'
import { CourseEntryInterface } from '@/models/course-entries/CourseEntry.interface'
import { initialState } from '@/store/modules/dashboard/Dashboard'

export enum DashboardMutations {
    SET_NEW_ENTRIES = 'SET_NEW_ENTRIES',
    UPDATE_NEW_ENTRIES = 'UPDATE_NEW_ENTRIES',
    SET_NEW_ENTRIES_NEXT_LINK = 'SET_NEW_ENTRIES_NEXT_LINK',
    SET_COMPLETED_ENTRIES = 'SET_COMPLETED_ENTRIES',
    SET_STARTING_SOON_ENTRIES = 'SET_STARTING_SOON_ENTRIES',
    SET_LOADING = 'SET_LOADING',
    RESET_STATE = 'RESET_STATE'
}

// mutations
export const mutations: MutationTree<DashboardState> = {
    [DashboardMutations.SET_NEW_ENTRIES]: (state: DashboardState, entries: CourseEntryInterface[]) => {
        state.newEntries = entries
    },
    [DashboardMutations.UPDATE_NEW_ENTRIES]: (state: DashboardState, entries: CourseEntryInterface[]) => {
        state.newEntries = state.newEntries.concat(entries)
    },
    [DashboardMutations.SET_NEW_ENTRIES_NEXT_LINK]: (state: DashboardState, link: string) => {
        state.newEntriesNextLink = link
    },
    [DashboardMutations.SET_COMPLETED_ENTRIES]: (state: DashboardState, entries: CourseEntryInterface[]) => {
        state.completedEntries = entries
    },

    [DashboardMutations.SET_STARTING_SOON_ENTRIES]: (state: DashboardState, entries: CourseEntryInterface[]) => {
        state.startingSoonEntries = entries
    },

    [DashboardMutations.SET_LOADING]: (state: DashboardState, loading: boolean) => {
        state.loading = loading
    },

    [DashboardMutations.RESET_STATE]: () => {
        Object.assign({}, initialState)
    }
}
