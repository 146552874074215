import { MutationTree } from 'vuex'
import { TeacherState, initialState } from '@/store/modules/teacher/Teacher'
import { TeacherInterface } from '@/models/teachers/Teacher.interface'
import { CourseDateInterface } from '@/models/course/CourseDate.interface'
import { generateTeacherSchema } from '@/forms/teacher/Teacher.schema'

export enum TeacherMutations {
    SET_TEACHER = 'SET_TEACHER',
    SET_COURSE_DATES = 'SET_COURSE_DATES',
    SET_LOADING = 'SET_LOADING',
    RESET_STATE = 'RESET_STATE'
}

// mutations
export const mutations: MutationTree<TeacherState> = {
    [TeacherMutations.SET_TEACHER]: (state: TeacherState, teacher: TeacherInterface) => {
        state.teacher = teacher
        state.teacherSchema.model = JSON.parse(JSON.stringify(teacher))
    },

    [TeacherMutations.SET_COURSE_DATES]: (state: TeacherState, courseDates: CourseDateInterface[]) => {
        state.courseDates = courseDates
    },

    [TeacherMutations.SET_LOADING]: (state: TeacherState, loading: boolean) => {
        state.loading = loading
    },

    [TeacherMutations.RESET_STATE]: (state: TeacherState) => {
        Object.assign(state, initialState)

        // Reset schema
        state.teacherSchema = generateTeacherSchema()
    }
}
