import { store } from '@/store';
import { StoreModuleNames } from '@/store/ModuleNames';
import { SeoMutations } from '@/store/modules/seo/Seo.mutations';
import { SeoProperties } from '@/models/seo/SeoProperties.interface';

export async function setSeoProperties(seo?: SeoProperties){
    await store.commit(`${StoreModuleNames.Seo}/${SeoMutations.RESET_STATE}`)

    if(seo && seo.title){
        await store.commit(`${StoreModuleNames.Seo}/${SeoMutations.SET_TITLE}`, seo.title)
    }
}
