import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export function generateFieldFirstName(options: any = {}){
    let firstName: FieldInterface = {
        component: 'Input',
        type: 'text',
        model: 'firstName',
        required: true,
        label:{
            text: i18n.global.t('word.first.name')
        },
        validation: null
        //validation: yup.string()
        //    .required()
        //    .label(i18n.global.t('word.first.name'))
    }

    firstName = Object.assign(options, firstName)

    return firstName
}
