import { FieldInterface } from '@/models/forms/schema/Field.interface'
import { generateFieldName } from '@/forms/common/fields/FieldName'
import i18n from "@/i18n";

export interface LocationSchemaInterface {
    fields: {
        name: FieldInterface;
        id: FieldInterface;
    },
    model:{
        name: string;
        id: string;
    }
}

export function generateLocationSchema(): LocationSchemaInterface {
    return {
        fields: {
            name: generateFieldName(),
            id: {
                component: 'Input',
                type: 'text',
                model: 'id',
                label:{
                    text: i18n.global.t('word.id')
                },
                attributes: {
                    readonly: true
                }
            }
        },
        model:{
            name: '',
            id: null
        }
    }
}


