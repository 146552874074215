export enum StoreModuleNames {
    Agenda = 'Agenda',
    Auth = 'Auth',
    Course = 'Course',
    CourseEntries = 'CourseEntries',
    CourseEntry = 'CourseEntry',
    Courses = 'Courses',
    Dashboard = 'Dashboard',
    General = 'General',
    Location = 'Location',
    Locations = 'Locations',
    Notifications = 'Notifications',
    Registration = 'Registration',
    Registrations = 'Registrations',
    Seo = 'Seo',
    Student = 'Student',
    Students = 'Students',
    Teacher = 'Teacher',
    Teachers = 'Teachers',
    User = 'User'
}
