import { MutationTree } from 'vuex'
import { TeachersFilterInterface } from '@/models/teachers/TeachersFilter.interface'
import { TeacherInterface } from '@/models/teachers/Teacher.interface'
import { TeachersState } from '@/store/modules/teachers/Teachers'

export enum TeachersMutations {
    SET_TEACHERS = 'SET_TEACHERS',
    UPDATE_TEACHERS_FILTER = 'UPDATE_TEACHERS_FILTER',
    SET_LOADING = 'SET_LOADING'
}

// mutations
export const mutations: MutationTree<TeachersState> = {
    [TeachersMutations.SET_TEACHERS]: (state: TeachersState, teachers: TeacherInterface[]) => {
        state.teachers = teachers
    },

    [TeachersMutations.UPDATE_TEACHERS_FILTER]: (state: TeachersState, teachersFilter: TeachersFilterInterface) => {
        state.teachersFilter = Object.assign(state.teachersFilter, teachersFilter);
    },

    [TeachersMutations.SET_LOADING]: (state: TeachersState, loading: boolean) => {
        state.loading = loading
    }
}
