import { FieldInterface } from '@/models/forms/schema/Field.interface'
import { TeacherInterface } from '@/models/teachers/Teacher.interface'
import { LocationInterface } from '@/models/locations/Location.interface'
import { generateFieldTeacher } from '@/forms/common/fields/FieldTeacher'
import { generateFieldLocation } from '@/forms/common/fields/FieldLocation'

export interface CourseDatesFilterSchema {
    fields: {
        teacher: FieldInterface;
        location: FieldInterface;
    },
    model: {
        teacher?: TeacherInterface | string;
        location?: LocationInterface | string;
    }
}

export function generateAgendaFilter(): CourseDatesFilterSchema{
    return {
        fields: {
            teacher: generateFieldTeacher(),
            location: generateFieldLocation()
        },
        model:{
            teacher: '',
            location: ''
        }
    }
}
