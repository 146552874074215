export default function fieldTimeGenerator(){
    const times = []
    const hours = [...Array(24).keys()].filter(hour => hour > 5)

    hours.forEach(i => {
        const hour = i.toString().padStart(2, '0')

        times.push(...[{
            label: `${hour}:00`,
            value: 60 * i
        },{
            label: `${hour}:30`,
            value: (60 * i) + 30
        }])
    })

    return times
}
