import { GetterTree } from 'vuex'
import { TeachersState } from '@/store/modules/teachers/Teachers'
import { collect } from 'collect.js'
import { fullNameFilter } from '@/filters'

// Getters
export const teachersGetters: GetterTree<TeachersState, any> = {
    getTeacherById:(state) => (id) => {
        if(state.teachers && state.teachers.length > 0) {
            return collect(state.teachers).filter(teacher => teacher.id === id).first()
        }
    },
    getTeachersOptions: (state) => {
        if(state.teachers && state.teachers.length > 0) {
            const teacherOptions = state.teachers.map(teacher => {
                return {
                    label: fullNameFilter(teacher),
                    value: teacher['@id']
                }
            })

            return teacherOptions
        }
    }
}
