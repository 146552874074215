import { mutations } from '@/store/modules/general/General.mutations'
import { getters } from '@/store/modules/general/General.getters'
import { actions } from '@/store/modules/general/General.actions'

/**
 * State interface
 */
export interface GeneralState {
    currentDate: Date;
    layout: string;
    layoutContentClass?: string;
    globalMessage?: string;
    lang: string;
}

/**
 * State
 * @type {{globalMessage: null, notificationCount: number, viewType: string, currentDate: Date, lang: string}}
 */
const state: GeneralState = {
    currentDate: new Date(),
    layout: 'general/GeneralRouterView',
    globalMessage: null,
    lang: 'nl',
    layoutContentClass: null,
}

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
