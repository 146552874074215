import { GetterTree } from 'vuex'
import { LocationState } from '@/store/modules/locations/Locations'

export const locationGetters: GetterTree<LocationState, any> = {
    getLocationsOptions: (state) => {
        if(state.locations && state.locations.length > 0) {
            const locationOptions = state.locations.map(location => {
                return {
                    label: location.name,
                    value: location['@id']
                }
            })

            return locationOptions
        }
    }
}
