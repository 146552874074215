import { MutationTree } from 'vuex'
import { CourseEntriesState } from '@/store/modules/courseEntries/CourseEntries'
import { CourseEntryInterface } from '@/models/course-entries/CourseEntry.interface'

export enum CourseEntriesMutations {
    SET_ELEARNINGS = 'SET_ELEARNINGS',
    SET_LOADING = 'SET_LOADING'
}

export const mutations: MutationTree<CourseEntriesState> = {
    [CourseEntriesMutations.SET_ELEARNINGS]: (state: CourseEntriesState, elearnings: CourseEntryInterface[]) => {
        state.elearnings = elearnings
    },

    [CourseEntriesMutations.SET_LOADING]: (state: CourseEntriesState, loading: boolean) => {
        state.loading = loading
    }
}
