import { MutationTree } from 'vuex'
import { AgendaCourseEntriesFilterInterface } from '@/models/agenda/AgendaCourseEntriesFilter.interface'
import { CourseDateInterface } from '@/models/course/CourseDate.interface'
import { AgendaState } from '@/store/modules/agenda/Agenda'
import { FieldChoiceInterface } from '@/models/forms/schema/FieldOptions.interface'

export enum AgendaMutations {
    SET_COURSE_DATES = 'SET_COURSE_DATES',
    UPDATE_COURSE_DATES_FILTER = 'UPDATE_COURSE_DATES_FILTER',
    SET_COURSE_DATES_FILTER_LOCATION = 'SET_COURSE_DATES_FILTER_LOCATION',
    SET_COURSE_DATES_FILTER_TEACHER = 'SET_COURSE_DATES_FILTER_TEACHER',
    SET_COURSE_DATES_FILTER_TEACHERS_OPTIONS = 'SET_COURSE_DATES_FILTER_TEACHERS_OPTIONS',
    SET_COURSE_DATES_FILTER_LOCATIONS_OPTIONS = 'SET_COURSE_DATES_FILTER_LOCATIONS_OPTIONS',
    UPDATE_COURSE_DATES_FILTER_SCHEMA = 'UPDATE_COURSE_DATES_FILTER_SCHEMA',
    SET_LOADING = 'SET_LOADING'
}

// mutations
export const mutations: MutationTree<AgendaState> = {
    [AgendaMutations.SET_COURSE_DATES]: (state: AgendaState, courseDates: CourseDateInterface[]) => {
        state.courseDates = courseDates
    },

    [AgendaMutations.SET_LOADING]: (state: AgendaState, loading: boolean) => {
        state.loading = loading
    },

    [AgendaMutations.UPDATE_COURSE_DATES_FILTER]: (state: AgendaState, courseDatesFilter: AgendaCourseEntriesFilterInterface) => {
        state.courseDatesFilter = Object.assign(state.courseDatesFilter, courseDatesFilter);
    },

    [AgendaMutations.SET_COURSE_DATES_FILTER_LOCATION]: (state: AgendaState, location: string) => {
        state.courseDatesFilter.location = location
    },

    [AgendaMutations.SET_COURSE_DATES_FILTER_TEACHER]: (state: AgendaState, teacher: string) => {
        state.courseDatesFilter.teacher = teacher
    },

    [AgendaMutations.UPDATE_COURSE_DATES_FILTER_SCHEMA]: (state: AgendaState, courseDatesFilterSchema: Partial<AgendaCourseEntriesFilterInterface>) => {
        state.courseDatesFilterSchema.model = Object.assign(state.courseDatesFilterSchema.model, courseDatesFilterSchema);
    },

    [AgendaMutations.SET_COURSE_DATES_FILTER_TEACHERS_OPTIONS]: (state: AgendaState, teachers: FieldChoiceInterface[]) => {
        state.courseDatesFilterSchema.fields.teacher.choices = teachers
    },

    [AgendaMutations.SET_COURSE_DATES_FILTER_LOCATIONS_OPTIONS]: (state: AgendaState, locations: FieldChoiceInterface[]) => {
        state.courseDatesFilterSchema.fields.location.choices = locations
    }
}


//
//store.dispatch(`${StoreModuleNames.Teachers}/${TeachersActions.GET_TEACHERS}`)
//store.dispatch(`${StoreModuleNames.Locations}/${LocationsActions.GET_LOCATIONS}`)
