import { RegistrationInterface } from '@/models/registration/Registration.interface'
import { TimeStampsInterface } from '@/models/common/TimeStampInterface'

export enum PaymentMethods {
    IDEAL = 'ideal',
    MAESTRO = 'maestro',
    MASTERCARD = 'mastercard',
    PAYPAL = 'paypal',
    VISA = 'visa',
    ACCOUNT = 'account',
    MISTERCASH = 'mistercash',
}

export type PaymentStatusType = 'completed' | 'open' | 'new' | 'cancelled';
export type PaymentMethodsTypes = PaymentMethods.IDEAL | PaymentMethods.MAESTRO | PaymentMethods.MASTERCARD | PaymentMethods.PAYPAL | PaymentMethods.VISA | PaymentMethods.ACCOUNT | PaymentMethods.MISTERCASH

export enum PaymentStates {
    COMPLETED = 'completed',
    OPEN = 'open',
    NEW = 'new',
    CANCELLED = 'cancelled'
}

export interface PaymentInterface extends TimeStampsInterface {
    '@id': string;
    '@type': 'Payment';
    id: string;
    paymentMethod: PaymentMethodsTypes;
    registration: RegistrationInterface;
    status: PaymentStatusType;
    paymentStatus: PaymentStatusType;
    totalAmount: number;
    payedAmount: number;
    taxAmount: number;
}


