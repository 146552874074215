import collect from 'collect.js'
import { dayjs } from '@/main'
import { DateType } from '@/models/common/Types'
import { API_DATE_FORMAT } from '@/api/src/Api'
import { CostsInterface } from '@/models/course/CourseAdditionalCosts.interface'
import i18n from "@/i18n";

/**
 * Sum all values from an object
 * @param {object} object
 * @returns {number | string}
 */
export function sumValues(object: Record<string, any>){
    return collect(Object.values(object)).sum()
}


/**
 * Interface for dateStringToFilterParams function
 */
interface DateStringToFilterParams {
    'date[before]'?: string;
    'date[after]'?: string;
    //'endDate[before]'?: string;
}

/**
 * Export object with start_date and end_date, used for filtering
 * @param {DateType} dateType
 * @param {string} key
 * @returns {DateStringToFilterParams}
 */
export function dateStringToFilterParams(dateType?: DateType, key = 'date'){
    let params: DateStringToFilterParams = {
        [`${key}[after]`] : dayjs().subtract(1, 'day').format(API_DATE_FORMAT)
    }

    if(dateType === 'week'){
        params = {
            [`${key}[after]`]: dayjs().startOf('isoWeek').subtract(1, 'day').format(API_DATE_FORMAT),
            [`${key}[before]`]: dayjs().endOf('isoWeek').add(1, 'day').format(API_DATE_FORMAT)
        }
    } else if(dateType === 'month'){
        params = {
            [`${key}[after]`]: dayjs().startOf('month').subtract(1, 'day').format(API_DATE_FORMAT),
            [`${key}[before]`]: dayjs().endOf('month').format(API_DATE_FORMAT)
        }
    }

    return params
}

/**
 * Remove null and undefined from object
 * @param {Record<string, any>} obj
 * @returns {any}
 */
export function deleteNullUndefinedFormObject(obj: Record<string, any>){
    return Object.entries(obj).reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {})
}

/**
 * Convert array to class string
 * @param {Array<string>} arr
 * @returns {string}
 */
export function arrayToCssClassString(arr: Array<string>){
    return arr && arr.join(' ');
}

/**
 * Format currency
 * @param {number} number
 * @returns {any}
 */
export function formatCurrency(number: number){
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(number / 100)
}

export function generateCountryChoices(){
    //@ts-ignore
    //const regionNames = new Intl.DisplayNames(['nl'], { type: 'region' })
    const regions = ['NL', 'BE', 'DE', 'FR', 'ES']

    return regions.map(region => {
        return {
            label: region,
            value: region
        }
    })
}

/**
 * Get index by entity id
 * @param items
 * @param item
 * @returns {any}
 */
export function getIndexById(items, item){
    return items.map(i => i.id).indexOf(item.id);
}

export function formatDecimalToNumber(number: string){
    return Math.floor(+number * 100)
}

/**
 * Format costs
 * @param {CostsInterface[]} costs
 * @returns {CostsInterface[]}
 */
export function formatCostsToDecimal(costs: CostsInterface[]){
    costs.forEach((cost, index) => {
        //costs[index].amount = (+cost.amount / 100)
        costs[index].amount = Math.floor((+cost.amount / 100) * 100) / 100
    })

    return costs
}

/**
 * Format costs
 * @param {CostsInterface[]} costs
 * @returns {CostsInterface[]}
 */
export function formatCostsToNumber(costs: CostsInterface[]){
    return costs.map(price => {
        price.amount = (+price.amount * 100)

        return price
    })
    //costs.forEach((cost, index) => {
    //    costs[index].amount = (+cost.amount / 100).toFixed(2)
    //})
    //
    //return costs
}

/**
 * Format string to decimal
 * @param {string} number
 * @returns {string}
 */
export function formatStringToDecimal(number: string){
    return parseFloat(number).toFixed(2)
}

/**
 * Check if number is in range
 * @param {number} value
 * @param {number} limit1
 * @param {number} limit2
 * @returns {boolean}
 */
export function isInRange(value: number, limit1: number, limit2: number): boolean {
    return value >= limit1 && value <= limit2;
}

/**
 * Download file from api
 * @param response
 * @param {string} fileName
 */
export function downloadFileFromApi(response, fileName: string){
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', fileName);
    link.click();

    // no need to append link as child to body.
    setTimeout(() => window.URL.revokeObjectURL(url), 0);
}

export function boolToString(comparison: boolean){
    return comparison ? i18n.global.t('word.yes') : i18n.global.t('word.no');
}
