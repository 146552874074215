import { createApp } from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'

const app = createApp(App).use(VueSvgIconPlugin, { tagName: 'icon' })
  .use(store)
  .use(router)
  .use(i18n)
  //.use(api)
  .mount('#app')

// Dayjs
dayjs.locale(store.state.General.lang)
dayjs.extend(isoWeek)
dayjs.extend(weekOfYear)
dayjs.extend(duration)
dayjs.extend(utc)

export { dayjs, app }
