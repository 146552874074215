import { FieldInterface } from '@/models/forms/schema/Field.interface'
import { GradesInterface } from '@/models/grades/GradesInterface';
import { RegistrationResult, RegistrationResultType } from '@/models/registration/Registration.types';
import { CourseEntryInterface } from '@/models/course-entries/CourseEntry.interface';
import i18n from '@/i18n';

export interface RegistrationCompletionSchemaInterface {
    fields: {
        gradesCompleted: FieldInterface;
        result: FieldInterface;
        courseEntry: FieldInterface;
        grades: {
            part: FieldInterface;
            grade: FieldInterface;
        }
    },
    model:{
        gradesCompleted: boolean;
        grades?: GradesInterface[]
        result?: RegistrationResultType
        courseEntry: string | CourseEntryInterface;
    },
    validation: Record<any, any>
}

export function generateRegistrationCompletionSchema(): RegistrationCompletionSchemaInterface {
    return {
        fields: {
            grades: {
                part: {
                    component: 'Input',
                    type: 'text',
                    required: true,
                    label: {
                        text: i18n.global.t('word.part')
                    },
                    attributes: {
                        readonly: true
                    }
                },
                grade: {
                    component: 'Input',
                    type: 'text',
                    required: true,
                    label: {
                        text: i18n.global.t('word.grade')
                    }
                }
            },
            gradesCompleted: {
                component: 'Switch',
                model: 'gradesCompleted',
                required: true,
                label: {
                    text: i18n.global.t('word.grades.completed')
                }
            },
            result: {
                component: 'Select',
                model: 'result',
                required: true,
                choices:[{
                    label: i18n.global.t('word.registration.exam.no.status'),
                    value: null
                }, {
                    label: i18n.global.t('word.registration.exam.successful'),
                    value: RegistrationResult.SUCCESS
                }, {
                    label: i18n.global.t('word.registration.exam.failed'),
                    value: RegistrationResult.FAILED
                }],
                label: {
                    text: i18n.global.t('word.state')
                }
            },
            courseEntry: {
                component: 'Select',
                model: 'courseEntry',
                required: true,
                choices:[],
                label: {
                    text: i18n.global.t('word.course.entry')
                }
            }
        },
        model:{
            gradesCompleted: false,
            grades: [],
            result: null,
            courseEntry: null
        },
        validation: null
    }
}
