import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'
import fieldTimeGenerator from '@/forms/common/generators/FieldTimeGenerator'

export function generateFieldTime(options: any = {}){
    const fieldTime: FieldInterface = {
        component: 'Select',
        required: true,
        label: {
            text: i18n.global.t('word.time')
        },
        choices: fieldTimeGenerator(),
        validation: null
        //validation: yup.number()
        //    .required()
        //    .label(options.label || i18n.global.t('word.time'))
    }

    if(options.label){
        fieldTime.label.text = options.label
        //fieldTime.validation.label(options.label)
    }

    if(options.model){
        fieldTime.model = options.model
    }

    return fieldTime
}
