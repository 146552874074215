import UsersApi from '@/api/modules/users/Users'
import CourseDatesApi from '@/api/modules/courseDates/CourseDates'
import ElearningsApi from '@/api/modules/elearning/Elearnings'
import TeachersApi from '@/api/modules/teachers/Teachers'
import LocationsApi from '@/api/modules/locations/Locations'
import CoursesApi from '@/api/modules/courses/Courses'
import CostsApi from '@/api/modules/costs/Costs'
import CourseEntriesApi from '@/api/modules/courseEntries/CourseEntries'
import StudentsApi from '@/api/modules/students/Students'
import StartDateNotificationsApi from '@/api/modules/startDateNotifications/StartDateNotifications'
import RegistrationsApi from '@/api/modules/registrations/Registrations'
import AuthApi from '@/api/modules/auth/Auth'
import ApiInstance from '@/api/src/Api'
import { AxiosInstance } from 'axios'
import axios from '@/api/src/Axios'
import AddressesApi from '@/api/modules/addresses/Addresses'
import MediaApi from '@/api/modules/media/Media';


export interface ApiInterface {
    users: UsersApi
    courseDates: CourseDatesApi
    elearnings: ElearningsApi
    teachers: TeachersApi
    locations: LocationsApi
    courses: CoursesApi
    courseEntries: CourseEntriesApi;
    students: StudentsApi
    registrations: RegistrationsApi
    startDateNotifications: StartDateNotificationsApi
    addresses: AddressesApi
    costs: CostsApi
    media: MediaApi
    auth: AuthApi
    axios: AxiosInstance
    instance: ApiInstance
}

class Api implements ApiInterface {
    instance = new ApiInstance;
    users = new UsersApi
    courseDates = new CourseDatesApi
    elearnings = new ElearningsApi
    teachers = new TeachersApi
    locations = new LocationsApi
    courses = new CoursesApi
    courseEntries = new CourseEntriesApi
    students = new StudentsApi
    registrations = new RegistrationsApi
    startDateNotifications = new StartDateNotificationsApi
    addresses = new AddressesApi
    costs = new CostsApi
    media = new MediaApi()
    auth = new AuthApi
    axios = axios
}

export default new Api
