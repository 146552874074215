import { FieldChoiceInterface } from '@/models/forms/schema/FieldOptions.interface'

export default function fieldRangeGenerator(min = 1, max = 250): FieldChoiceInterface[] {
    const range: FieldChoiceInterface[] = []

    for(let i = min; i <= max; i++ ){
        range.push({
            label: i,
            value: i
        })
    }

    return range
}
