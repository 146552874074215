import yup from '@/yup'
import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export interface AuthRequestPasswordSchemaInterface {
    fields: {
        email: FieldInterface;
    },
    model:{
        email: string;
    }
}

export const authRequestPasswordSchema: AuthRequestPasswordSchemaInterface = {
    fields: {
        email: {
            component: 'Input',
            type: 'email',
            model: 'email',
            required: true,
            label: {
                text: i18n.global.t('word.email')
            },
            validation: yup.string()
                .required()
                .email()
                .label(i18n.global.t('word.email'))
        }
    },
    model:{
        email: ''
    }
}
