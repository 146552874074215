import { ActionTree } from "vuex";
import { CourseEntryMutations } from "@/store/modules/courseEntry/CourseEntry.mutations";
import { AxiosResponse } from "axios";
import { CourseEntryInterface } from "@/models/course-entries/CourseEntry.interface";
import Api from "@/api/Api";
import { StoreModuleNames } from "@/store/ModuleNames";
import { LocationsActions } from "@/store/modules/locations/Locations.actions";
import { TeachersActions } from "@/store/modules/teachers/Teachers.actions";
import { CourseEntryState } from "@/store/modules/courseEntry/CourseEntry";
import { GeneralActions } from "@/store/modules/general/General.actions";
import { RegistrationStatus } from "@/models/registration/Registration.types";
import { dayjs } from "@/main";
import { API_DATE_FORMAT } from "@/api/src/Api";
import { CourseTypes } from "@/models/course/Course.types";
import deepmerge from "deepmerge";

export enum CourseEntryActions {
  GET_COURSE_ENTRIES = "GET_COURSE_ENTRIES",
  GET_ACTIVE_COURSE_ENTRIES = "GET_ACTIVE_COURSE_ENTRIES",
  GET_REGISTRATIONS = "GET_REGISTRATIONS",
  GET_WAITING_LISTS = "GET_WAITING_LISTS",
  GET_CANCELLATIONS = "GET_CANCELLATIONS",
  GET_COURSE_ENTRY = "GET_COURSE_ENTRY",
  GET_COURSE_DATES_TEACHERS_OPTIONS = "GET_COURSE_DATES_TEACHERS_OPTIONS",
  GET_COURSE_DATES_LOCATIONS_OPTIONS = "GET_COURSE_DATES_LOCATIONS_OPTIONS",
  CREATE_COURSE_ENTRY = "CREATE_COURSE_ENTRY",
  UPDATE_COURSE_ENTRY = "UPDATE_COURSE_ENTRY",
  DELETE_COURSE_ENTRY = "DELETE_COURSE_ENTRY",
  UPDATE_COURSE_DATES = "UPDATE_COURSE_DATES",
  REMOVE_COURSE_DATE = "REMOVE_COURSE_DATE",
  GET_DATA = "GET_DATA",
}

// actions
export const actions: ActionTree<CourseEntryState, any> = {
  [CourseEntryActions.GET_COURSE_ENTRIES]: async (
    { commit, dispatch },
    { id },
  ) => {
    try {
      const courseEntries: AxiosResponse<CourseEntryInterface> =
        await Api.courseEntries.index({
          params: {
            "order[startDate]": "ASC",
            "course.id": id,
          },
        });

      commit(
        CourseEntryMutations.SET_COURSE_ENTRIES,
        courseEntries.data["hydra:member"],
      );
    } catch (e) {
      dispatch(
        `${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`,
        {},
        {
          root: true,
        },
      );
    }
  },

  [CourseEntryActions.GET_ACTIVE_COURSE_ENTRIES]: async ({
    commit,
    dispatch,
  }) => {
    try {
      const courseEntries: AxiosResponse<CourseEntryInterface> =
        await Api.courseEntries.index({
          params: {
            "order[course.name]": "ASC",
            itemsPerPage: 9999,
            isCompleted: false,
            gradesCompleted: false,
            "startDate[after]": dayjs().startOf("year").format(API_DATE_FORMAT),
          },
        });

      // Needs to be in a separate call, because of limitations in API platform
      const elearnings: AxiosResponse<CourseEntryInterface> =
        await Api.courseEntries.index({
          params: {
            type: CourseTypes.elearning,
          },
        });

      const entries = deepmerge(
        courseEntries.data["hydra:member"],
        elearnings.data["hydra:member"],
      );

      commit(CourseEntryMutations.SET_ACTIVE_COURSE_ENTRIES, entries);
    } catch (e) {
      await dispatch(
        `${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`,
        {},
        {
          root: true,
        },
      );
    }
  },

  /**
   * Set registrations
   * @param {Commit} commit
   * @returns {Promise<void>}
   */
  [CourseEntryActions.GET_REGISTRATIONS]: async (
    { commit, state, dispatch },
    { page },
  ) => {
    try {
      const courseEntries: AxiosResponse<CourseEntryInterface> =
        await Api.courseEntries.getRegistrations(state.courseEntry, {
          params: {
            _page: page,
          },
        });

      commit(
        CourseEntryMutations.SET_REGISTRATIONS,
        courseEntries.data["hydra:member"],
      );
      commit(
        CourseEntryMutations.SET_REGISTRATIONS_PAGINATION,
        courseEntries.data["hydra:view"],
      );
    } catch (e) {
      console.log(e);

      dispatch(
        `${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`,
        {},
        {
          root: true,
        },
      );
    }
  },

  /**
   * Waiting lists
   * @param {Commit} commit
   * @param {CourseEntryState} state
   * @param {Dispatch} dispatch
   * @returns {Promise<void>}
   */
  [CourseEntryActions.GET_WAITING_LISTS]: async ({
    commit,
    state,
    dispatch,
  }) => {
    try {
      const courseEntries: AxiosResponse<CourseEntryInterface> =
        await Api.courseEntries.getRegistrations(state.courseEntry, {
          params: {
            status: RegistrationStatus.WAITING,
          },
        });

      commit(
        CourseEntryMutations.SET_WAITING_LISTS,
        courseEntries.data["hydra:member"],
      );
    } catch (e) {
      console.log(e);

      dispatch(
        `${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`,
        {},
        {
          root: true,
        },
      );
    }
  },

  [CourseEntryActions.GET_CANCELLATIONS]: async ({
    commit,
    state,
    dispatch,
  }) => {
    try {
      const courseEntries: AxiosResponse<CourseEntryInterface> =
        await Api.courseEntries.getRegistrations(state.courseEntry, {
          params: {
            status: RegistrationStatus.CANCELLED,
          },
        });

      commit(
        CourseEntryMutations.SET_CANCELLATIONS,
        courseEntries.data["hydra:member"],
      );
    } catch (e) {
      console.log(e);

      dispatch(
        `${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`,
        {},
        {
          root: true,
        },
      );
    }
  },

  /**
   * Get course entry
   * @param {Commit} commit
   * @param {any} id
   * @returns {Promise<void>}
   */
  [CourseEntryActions.GET_COURSE_ENTRY]: async (
    { commit, state, dispatch },
    { id },
  ) => {
    try {
      await commit(CourseEntryMutations.SET_REGISTRATIONS, null);
      await commit(CourseEntryMutations.SET_LOADING, true);
      const courseEntry: AxiosResponse<CourseEntryInterface> =
        await Api.courseEntries.showById(id);

      await commit(CourseEntryMutations.SET_COURSE_ENTRY, courseEntry.data);

      const exportBulkLink: AxiosResponse<unknown> =
        await Api.courseEntries.getExportBulkLink(state.courseEntry);
      await commit(
        CourseEntryMutations.SET_EXPORT_BULK_LINK,
        `${process.env.VUE_APP_API_BASE_URL}${exportBulkLink.data}`,
      );

      const exportLink: AxiosResponse<unknown> =
        await Api.courseEntries.getExportLink(state.courseEntry);
      await commit(
        CourseEntryMutations.SET_EXPORT_LINK,
        `${process.env.VUE_APP_API_BASE_URL}${exportLink.data}`,
      );

      await commit(CourseEntryMutations.SET_LOADING, false);
    } catch (e) {
      console.log(e);

      await commit(CourseEntryMutations.SET_LOADING, false);

      dispatch(
        `${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`,
        {},
        {
          root: true,
        },
      );
    }
  },

  /**
   * Get teachers options for select
   * @param {ActionContext<CourseEntryState, any>} context
   */
  [CourseEntryActions.GET_COURSE_DATES_TEACHERS_OPTIONS]: (context) => {
    context.commit(
      CourseEntryMutations.SET_COURSE_DATES_TEACHERS_OPTIONS,
      context.rootGetters["Teachers/getTeachersOptions"],
    );
  },

  /**
   * Get locations options for select
   * @param {ActionContext<CourseEntryState, any>} context
   */
  [CourseEntryActions.GET_COURSE_DATES_LOCATIONS_OPTIONS]: (context) => {
    context.commit(
      CourseEntryMutations.SET_COURSE_DATES_LOCATIONS_OPTIONS,
      context.rootGetters["Locations/getLocationsOptions"],
    );
  },

  /**
   * Get all data for an course entry
   * @param {Dispatch} dispatch
   * @param {any} id
   * @returns {Promise<void>}
   */
  [CourseEntryActions.GET_DATA]: async ({ dispatch }, { id }) => {
    try {
      if (id) {
        await dispatch(CourseEntryActions.GET_COURSE_ENTRY, { id });
        //await dispatch(CourseEntryActions.GET_COURSE_DATES)
      }
      await dispatch(
        `${StoreModuleNames.Teachers}/${TeachersActions.GET_TEACHERS}`,
        null,
        {
          root: true,
        },
      );
      await dispatch(
        `${StoreModuleNames.Locations}/${LocationsActions.GET_LOCATIONS}`,
        null,
        {
          root: true,
        },
      );

      await dispatch(`${CourseEntryActions.GET_COURSE_DATES_TEACHERS_OPTIONS}`);
      await dispatch(
        `${CourseEntryActions.GET_COURSE_DATES_LOCATIONS_OPTIONS}`,
      );
    } catch (e) {
      dispatch(
        `${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`,
        {},
        {
          root: true,
        },
      );
    }
  },

  /**
   * Create an course entry
   * @param {ActionContext<CourseEntryState, any>} context
   * @returns {Promise<void>}
   */
  [CourseEntryActions.CREATE_COURSE_ENTRY]: async (context) => {
    try {
      const model = context.state.courseEntrySchema.model;

      model.course = context.rootState.Course.course["@id"];
      await Api.courseEntries.create(model);
    } catch (e) {
      console.log(e);

      context.dispatch(
        `${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`,
        {},
        {
          root: true,
        },
      );
    }
  },

  /**
   * Updated course entry
   * @param {ActionContext<CourseEntryState, any>} context
   * @returns {Promise<void>}
   */
  [CourseEntryActions.UPDATE_COURSE_ENTRY]: async (context) => {
    try {
      const model = context.state.courseEntrySchema.model;

      await Api.courseEntries.update(model);
    } catch (e: any) {
      context.dispatch(
        `${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`,
        {},
        {
          root: true,
        },
      );

      throw new Error(e.message);
    }
  },

  /**
   * Delete course entry
   * @param {CourseEntryState} state
   * @returns {Promise<void>}
   */
  [CourseEntryActions.DELETE_COURSE_ENTRY]: async ({ state }) => {
    try {
      await Api.courseEntries.delete(state.courseEntrySchema.model.id);
    } catch (e) {
      console.log(e);
    }
  },
};
