import axios from '@/api/src/Axios'
import { AxiosInstance } from 'axios'


export interface AuthApiTokenInterface {
    email: string;
    password: string;
}

export interface AuthRequestPasswordInterface {
    email: string;
}

export interface AuthApiRefreshTokenInterface {
    authToken: string;
}

export default class AuthApi {
    protected axios: AxiosInstance

    constructor() {
        this.axios = axios
    }

    async authenticate(data: AuthApiTokenInterface){
        return this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/authentication_token`, data, {
            withCredentials: true
        })
    }

    async requestPassword(data: AuthRequestPasswordInterface){
        return this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/reset-password`, data, {
            withCredentials: true
        })
    }

    async invalidateToken(){
        return this.axios.post(`${process.env.VUE_APP_API_URL}/token/invalidate`, {
            withCredentials: true
        })
    }
}
