import yup from '@/yup'
import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'
import { CourseCertificationType, CourseOfferedByType, CourseType, CourseTypes } from '@/models/course/Course.types'
import { CostsInterface } from '@/models/course/CourseAdditionalCosts.interface'

import { generateFieldName } from '@/forms/common/fields/FieldName'
import { generateFieldId } from '@/forms/common/fields/FieldId'
import { generateFieldTotal } from '@/forms/common/fields/FieldTotal'

import { generateFieldCurrency } from '@/forms/common/fields/FieldCurrency'
import { generateCourseSchemaCosts } from '@/forms/course/generators/generateCourseCost'
import {MediaObjectInterface} from "@/models/media/MediaObject.interface";

export interface CourseSchemaInterface {
    fields: {
        id: FieldInterface,
        name: FieldInterface;
        certificationTitle: FieldInterface;
        skillLevel: FieldInterface;
        skillLevelDisclaimer: FieldInterface;
        offeredBy: FieldInterface;
        isOnline: FieldInterface;
        price: FieldInterface;
        ects: FieldInterface;
        total: FieldInterface;
        certificationType: FieldInterface;
        offeredTypes: FieldInterface;
        publicUrl: FieldInterface;
        eLearningLink: FieldInterface;
        eLearningExamLink: FieldInterface;
        kntoReviewLink: FieldInterface;
        stapId: FieldInterface;
        digitalStapId: FieldInterface;
        additionalCosts: {
            amount: FieldInterface,
            name: FieldInterface,
        }
    },
    model:{
        id: string;
        name: string;
        certificationTitle: string;
        skillLevel: number|string;
        skillLevelDisclaimer?: string;
        offeredBy: CourseOfferedByType;
        certificationType: CourseCertificationType;
        offeredTypes: CourseType[];
        isOnline: boolean;
        isActive: boolean;
        additionalCosts?: CostsInterface[];
        prices: Partial<CostsInterface>[];
        ects: string;
        publicUrl: string;
        eLearningLink: string;
        eLearningExamLink: string;
        kntoReviewLink: string;
        stapId?: string;
        digitalStapId?: string;
        files: MediaObjectInterface[];
    }
}

export function generateCourseSchema(): CourseSchemaInterface {
    return {
        fields: {
            name: generateFieldName(),
            id: generateFieldId(),
            total: generateFieldTotal(),
            certificationTitle: {
                component: 'Input',
                type: 'text',
                model: 'certificationTitle',
                required: true,
                label: {
                    text: i18n.global.t('word.certification.title')
                },
                validation: yup.string()
                  .label(i18n.global.t('word.certification.title'))
            },
            ects: {
                component: 'Input',
                type: 'number',
                model: 'ects',
                required: true,
                label:{
                    text: i18n.global.t('word.ects')
                },
                validation: yup.string()
                    .label(i18n.global.t('word.ects'))
            },
            skillLevel:{
                component: 'Select',
                model: 'skillLevel',
                required: true,
                label: {
                    text: i18n.global.t('word.skill.level')
                },
                choices:[
                    {
                        label: `${i18n.global.t('word.skill.level')} 1`,
                        value: 1
                    },
                    {
                        label: `${i18n.global.t('word.skill.level')} 2`,
                        value: 2
                    },
                    {
                        label: `${i18n.global.t('word.skill.level')} 3`,
                        value: 3
                    }],
                validation: yup.string()
                    .required()
                    .label(i18n.global.t('word.skill.level'))

            },
            skillLevelDisclaimer: {
                component: 'TextArea',
                model: 'skillLevelDisclaimer',
                required: true,
                label:{
                    text: i18n.global.t('word.disclaimer')
                },
                validation: yup.string()
                    .nullable()
                    .label(i18n.global.t('word.disclaimer'))
            },
            offeredBy:{
                component: 'Select',
                model: 'skillLevel',
                required: true,
                label: {
                    text: i18n.global.t('word.offered.by')
                },
                choices:[
                    {
                        label: 'SOHF',
                        value: 'SOHF'
                    },
                    {
                        label: 'Vitakruid',
                        value: 'vitakruid'
                    },
                    {
                        label: i18n.global.t('word.external'),
                        value: 'external'
                    }
                ],
                validation: yup.string()
                    .required()
                    .label(i18n.global.t('word.offered.by'))

            },
            certificationType:{
                component: 'Select',
                model: 'certificationType',
                required: true,
                label: {
                    text: i18n.global.t('word.type.certification')
                },
                choices:[
                    {
                        label: i18n.global.t('word.certification.type.diploma'),
                        value: 'type.diploma'
                    },
                    {
                        label: i18n.global.t('word.certification.type.certificate'),
                        value: 'type.certificate'
                    }
                ],
                validation: yup.string()
                    .nullable()
                    .required()
                    .label(i18n.global.t('word.offered.by'))

            },
            isOnline: {
                component: 'Switch',
                model: 'isOnline',
                required: true,
                label: {
                    text: i18n.global.t('word.course.online')
                },
                validation: yup.bool()
            },
            offeredTypes: {
                component: 'Switch',
                model: 'offeredTypes',
                required: true,
                label: {
                    text: i18n.global.t('word.active')
                },
                choices: [{
                    label: i18n.global.t('word.course.type.classical'),
                    value: CourseTypes.classical
                }, {
                    label: i18n.global.t('word.course.type.elearning'),
                    value: CourseTypes.elearning
                }, {
                    label: i18n.global.t('word.course.type.webinar'),
                    value: CourseTypes.webinar
                }]
            },
            price: generateFieldCurrency(),
            additionalCosts:{
                name: {
                    component: 'Input',
                    type: 'text',
                    model: 'name',
                    required: true,
                    label: {
                        text: i18n.global.t('word.value')
                    },
                    validation: yup.string()
                        .required()
                        .label(i18n.global.t('word.value'))
                },
                amount: generateFieldCurrency()
            },
            publicUrl: {
                component: 'Input',
                type: 'text',
                model: 'publicUrl',
                required: true,
                label:{
                    text: i18n.global.t('word.sohf.link')
                },
                validation: yup.string()
                    .nullable()
                    .label(i18n.global.t('word.sohf.link'))
            },
            eLearningLink: {
                component: 'Input',
                type: 'text',
                model: 'eLearningLink',
                required: true,
                label:{
                    text: i18n.global.t('word.elearning.link')
                },
                validation: yup.string()
                    .nullable()
                    .label(i18n.global.t('word.elearning.link'))
            },
            eLearningExamLink: {
                component: 'Input',
                type: 'text',
                model: 'eLearningExamLink',
                required: true,
                label:{
                    text: i18n.global.t('word.elearning.exam.link')
                },
                validation: yup.string()
                    .nullable()
                    .label(i18n.global.t('word.elearning.exam.link'))
            },
            kntoReviewLink: {
                component: 'Input',
                type: 'text',
                model: 'kntoReviewLink',
                required: true,
                label:{
                    text: i18n.global.t('word.knto.review.link')
                },
                validation: yup.string()
                    .nullable()
                    .label(i18n.global.t('word.knto.review.link'))
            },
            stapId: {
                component: 'Input',
                type: 'text',
                model: 'stapId',
                required: true,
                label:{
                    text: i18n.global.t('word.classical.stap.id')
                },
                validation: yup.string()
                    .nullable()
                    .label(i18n.global.t('word.classical.stap.id'))
            },
            digitalStapId: {
                component: 'Input',
                type: 'text',
                model: 'digitalStapId',
                required: true,
                label:{
                    text: i18n.global.t('word.digital.stap.id')
                },
                validation: yup.string()
                    .nullable()
                    .label(i18n.global.t('word.digital.stap.id'))
            }
        },
        model:{
            name: '',
            certificationTitle: '',
            id: '',
            ects: '',
            skillLevel: 1,
            skillLevelDisclaimer: '',
            offeredBy: 'SOHF',
            certificationType: 'type.diploma',
            offeredTypes: [],
            isOnline: false,
            isActive: true,
            prices: generateCourseSchemaCosts(),
            publicUrl: '',
            eLearningLink: '',
            eLearningExamLink: '',
            kntoReviewLink: '',
            files: []
        }
    }
}

