import { ActionTree } from 'vuex'
import { AxiosResponse } from 'axios'
import { LocationState } from '@/store/modules/locations/Locations'
import { LocationInterface } from '@/models/locations/Location.interface'
import { LocationsMutations } from '@/store/modules/locations/Locations.mutations'

import Api from '@/api/Api'
import { apiFormatOrderFilter } from '@/api/src/Api';
//import axios from 'axios'
//import { collect } from 'collect.js'

export enum LocationsActions {
    GET_LOCATIONS = 'GET_LOCATIONS',
    //CREATE_LOCATION = 'CREATE_LOCATION',
    //ADD_NEW_LOCATION = 'ADD_NEW_LOCATION',
    //SAVE_LOCATIONS = 'SAVE_LOCATIONS',
    //DELETE_LOCATION = 'DELETE_LOCATION',
}

// actions
export const actions: ActionTree<LocationState, any> = {
    [LocationsActions.GET_LOCATIONS]: async ({commit, state}) => {
        const response: AxiosResponse<LocationInterface> = await Api.locations.index({
            params: {
                'archivedAt[null]': true,
                'deletedAt[null]': true,
                ...apiFormatOrderFilter(state.locationsFilter)
            }
        })
        commit(LocationsMutations.SET_LOCATIONS, response.data['hydra:member'])
    },

    //[LocationsActions.CREATE_LOCATION]: async (context) => {
    //    const response: AxiosResponse<LocationInterface> = await Api.locations.create(context.state.locationSchema.model)
    //    context.commit(LocationsMutations.CREATE_LOCATION, response.data)
    //},
    //
    //[LocationsActions.ADD_NEW_LOCATION]: async ({commit}) => {
    //    commit(LocationsMutations.ADD_NEW_LOCATION, {
    //        name: ''
    //    })
    //},
    //
    //[LocationsActions.DELETE_LOCATION]: async ({commit, state}, index) => {
    //    if(state.locationSchema.model.location[index]?.id) {
    //        await Api.location.delete(state.locationSchema.model.location[index].id)
    //    }
    //
    //    commit(LocationsMutations.DELETE_LOCATION, index)
    //},
    //
    //[LocationsActions.SAVE_LOCATIONS]: async ({state}) => {
    //    const apiCalls = []
    //
    //    state.locationSchema.model.location.forEach(location => {
    //        if(location.id){
    //            apiCalls.push(Api.location.update(location.id, location))
    //        } else {
    //            apiCalls.push(Api.location.create(location))
    //        }
    //    })
    //
    //    await axios.all(apiCalls)
    //}
}
