import { ActionTree } from 'vuex'
import { AuthState } from '@/store/modules/auth/Auth'
import { AuthMutations } from '@/store/modules/auth/Auth.mutations'
import { StoreModuleNames } from '@/store/ModuleNames'
import { UserMutations } from '@/store/modules/user/User.mutations'
import { GeneralMutations } from '@/store/modules/general/General.mutations'
import { AxiosResponse } from 'axios'

import Api from '@/api/Api'
import i18n from '@/i18n'
import { GeneralActions } from '@/store/modules/general/General.actions'
import { UserActions } from '@/store/modules/user/User.actions';

export enum AuthActions {
    AUTHENTICATE = 'AUTHENTICATE',
    REQUEST_PASSWORD = 'REQUEST_PASSWORD',
    REVOKE_ACCESS = 'REVOKE_ACCESS'
}

// actions
export const actions: ActionTree<AuthState, any> = {
    [AuthActions.AUTHENTICATE]: async ({commit, state, dispatch}) => {
        commit(AuthMutations.SET_LOADING, true)

        // Reset error
        await dispatch(`${StoreModuleNames.General}/${GeneralActions.RESET_GLOBAL_MESSAGE}`, null, {
            root: true
        })

        try {
            const loginResponse: AxiosResponse<Record<any, any>> = await Api.auth.authenticate(state.loginSchema.model)
            await dispatch(`${StoreModuleNames.User}/${UserActions.GET_USER}`, {
                id: loginResponse.data.data.id
            }, {
                root:true
            })

            await commit(`${StoreModuleNames.User}/${UserMutations.SET_AUTHENTICATED}`, true, {
                root: true
            })
            await commit(AuthMutations.SET_LOADING, false)
        } catch (e) {
            commit(`${[StoreModuleNames.General]}/${GeneralMutations.SET_GLOBAL_MESSAGE}`, {
                name: GeneralMutations.SET_GLOBAL_MESSAGE,
                title: i18n.global.t('sentence.validation.invalid.credentials'),
                message: i18n.global.t('sentence.validation.invalid.credentials'),
                type: 'error'
            }, {
                root: true
            })

            commit(AuthMutations.SET_LOADING, false)
        }
    },

    [AuthActions.REQUEST_PASSWORD]: async ({commit, state}) => {
        commit(AuthMutations.SET_LOADING, true)

        await Api.auth.requestPassword(state.requestPasswordModel)

        commit(AuthMutations.SET_LOADING, false)
    },

    /**
     * Revoke access
     * @param {Commit} commit
     * @returns {Promise<void>}
     */
    [AuthActions.REVOKE_ACCESS]: async ({commit}) => {
        await Api.auth.invalidateToken()
        await commit(`${StoreModuleNames.User}/${UserMutations.SET_AUTHENTICATED}`, false, {
            root: true
        })
        await commit(`${StoreModuleNames.User}/${UserMutations.SET_USER}`, null, {
            root: true
        })
    }
}
