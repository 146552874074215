import {RegistrationTypes} from '@/models/registration/Registration.types'
import {ActionTree} from 'vuex'
import {AxiosResponse} from 'axios'
import Api from '@/api/Api'
import {RegistrationInterface} from '@/models/registration/Registration.interface';
import {RegistrationState} from '@/store/modules/registration/Registration';
import {RegistrationMutations} from '@/store/modules/registration/Registration.mutations';
import {PaymentInterface} from '@/models/payment/Payment.interface';
import {StoreModuleNames} from '@/store/ModuleNames';
import {GeneralActions} from '@/store/modules/general/General.actions';
import dayjs from 'dayjs';
import {API_DATE_FORMAT} from '@/api/src/Api';

export enum RegistrationActions {
    GET_REGISTRATION = 'GET_REGISTRATION',
    GET_RELATED_COURSE_ENTRIES = 'GET_RELATED_COURSE_ENTRIES',
    GET_PAYMENT = 'GET_PAYMENT',
    CANCEL_REGISTRATION = 'CANCEL_REGISTRATION',
    UPDATE_REGISTRATION = 'UPDATE_REGISTRATION',
    CREATE_REGISTRATION = 'CREATE_REGISTRATION',
    EXPORT_CERTIFICATE = 'EXPORT_CERTIFICATE',
    EXPORT_STAP_CONFIRMATION = 'EXPORT_STAP_CONFIRMATION',
    EXPORT_STAP_REGISTRATIONS = 'EXPORT_STAP_REGISTRATIONS',
    START_STAP_COURSE = 'START_STAP_COURSE'
}

// actions
export const actions: ActionTree<RegistrationState, any> = {
    /**
     * Get teacher
     * @param {Commit} commit
     * @param {any} id
     * @returns {Promise<void>}
     */
    [RegistrationActions.GET_REGISTRATION]: async ({commit, dispatch, state}, {
        id
    }) => {
        try {
            commit(RegistrationMutations.RESET_STATE)
            await commit(RegistrationMutations.SET_LOADING, true)

            const response: AxiosResponse<RegistrationInterface> = await Api.registrations.showById(id)
            commit(RegistrationMutations.SET_REGISTRATION, response.data)

            await dispatch(`${RegistrationActions.GET_PAYMENT}`)
            await dispatch(`${RegistrationActions.GET_RELATED_COURSE_ENTRIES}`, {
                registration: state.registration
            })
            await commit(RegistrationMutations.SET_LOADING, false)
        } catch (e) {
            await commit(RegistrationMutations.SET_LOADING, false)
        }
    },

    /**
     * Get related course entries
     * @param {RegistrationState} state
     * @param {Commit} commit
     * @returns {Promise<void>}
     */
    [RegistrationActions.GET_RELATED_COURSE_ENTRIES]: async ({commit, dispatch}, {registration}) => {
        try {
            const response: AxiosResponse<RegistrationInterface> = await Api.courseEntries.getRelatedCourseEntries(registration.courseEntry, {
                params: {
                    'startDate[after]': dayjs().subtract(6, 'month').format(API_DATE_FORMAT)
                }
            })
            commit(RegistrationMutations.SET_SCHEMA_COMPLETION_COURSE_ENTRIES_CHOICES, response.data['hydra:member'])
        } catch (e) {
            console.log(e)
            await dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },


    /**
     * Create registration
     * @param {RegistrationState} state
     * @param {Dispatch} dispatch
     * @param {Commit} commit
     * @returns {Promise<void>}
     */
    [RegistrationActions.CREATE_REGISTRATION]: async ({state, dispatch}) => {
        try {
            const model = state.registrationSchema.model as Partial<RegistrationInterface>

            /**
             * We need a separate update action on the type to simulate regular flow
             */
            const registrationType = model.type
            model.type = RegistrationTypes.NEW

            const response = await Api.registrations.create(model)
            await Api.registrations.update(response.data.id, {type: registrationType})
        } catch (e) {
            console.log(e)
            await dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    /**
     * Updated registration
     * @param {ActionContext<RegistrationState, any>} context
     * @returns {Promise<void>}
     */
    [RegistrationActions.UPDATE_REGISTRATION]: async ({state, dispatch, commit}) => {
        try {
            const model = state.registrationCompletionSchema.model as RegistrationInterface
            await Api.registrations.update(model.id, model)


            // Compare models for change in course entry
            if (state.registrationCompletionSchema.model.courseEntry['@id'] !== state.registration.courseEntry['@id']) {
                commit(RegistrationMutations.SET_REGISTRATION_ENTRY_CHANGED, true)
            }

        } catch (e) {
            console.log(e)
            await dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    /**
     * Cancel registration
     * @param {ActionContext<RegistrationState, any>} context
     * @param {any} registration
     * @returns {Promise<void>}
     */
    [RegistrationActions.CANCEL_REGISTRATION]: async ({
                                                          state,
                                                          dispatch
                                                      }, registration: RegistrationInterface = state.registration) => {
        try {
            await Api.registrations.cancel(registration)
        } catch (e) {
            await dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    /**
     * Get payment
     * @param {RegistrationState} state
     * @param {Commit} commit
     * @returns {Promise<void>}
     */
    [RegistrationActions.GET_PAYMENT]: async ({state, commit}) => {
        try {
            const response: AxiosResponse<PaymentInterface> = await Api.registrations.showByUri(state.registration.payments[0]['@id'])
            commit(RegistrationMutations.SET_PAYMENT, response.data)
        } catch (e) {
            console.log(e)
            commit(RegistrationMutations.SET_PAYMENT, null)
        }
    },

    /**
     * Export certificate
     * @param {RegistrationState} state
     * @returns {Promise<void>}
     */
    [RegistrationActions.EXPORT_CERTIFICATE]: async ({
                                                         state,
                                                         dispatch
                                                     }, registration: RegistrationInterface = state.registration) => {
        try {
            await Api.registrations.exportCertificate(registration)
        } catch (e) {
            await dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    [RegistrationActions.EXPORT_STAP_CONFIRMATION]: async ({
                                                               state,
                                                               dispatch
                                                           }, registration: RegistrationInterface = state.registration) => {
        try {
            await Api.registrations.exportStapConfirmation(registration)
        } catch (e) {
            await dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    [RegistrationActions.EXPORT_STAP_REGISTRATIONS]: async ({
        dispatch
    }) => {
        try {
            await Api.registrations.exportStapRegistrations()
        } catch (e) {
            await dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    [RegistrationActions.START_STAP_COURSE]: async ({state, dispatch, commit}) => {
        try {
            const model = state.registrationCompletionSchema.model as RegistrationInterface
            model.stapInvoiceProcessed = dayjs().format(API_DATE_FORMAT)

            const response = await Api.registrations.update(model.id, model)
            commit(RegistrationMutations.SET_REGISTRATION, response.data)
        } catch (e) {
            await dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },
}
