import { MutationTree } from 'vuex'
import { RegistrationsState } from '@/store/modules/registrations/Registrations'
import { RegistrationInterface } from '@/models/registration/Registration.interface'
import { RegistrationsFilterInterface } from '@/models/registrations/RegistrationsFilter.interface'
import PaginationInterface from '@/models/pagination/Pagination.interface'

export enum RegistrationsMutations {
    SET_REGISTRATIONS = 'SET_REGISTRATIONS',
    SET_REGISTRATIONS_PAGINATION = 'SET_REGISTRATIONS_PAGINATION',
    UPDATE_REGISTRATIONS_FILTER = 'UPDATE_REGISTRATIONS_FILTER',
    //SET_COURSE_DATES_FILTER_LOCATION = 'SET_COURSE_DATES_FILTER_LOCATION',
    //SET_COURSE_DATES_FILTER_TEACHER = 'SET_COURSE_DATES_FILTER_TEACHER',
    //SET_COURSE_DATES_FILTER_TEACHERS_OPTIONS = 'SET_COURSE_DATES_FILTER_TEACHERS_OPTIONS',
    //SET_COURSE_DATES_FILTER_LOCATIONS_OPTIONS = 'SET_COURSE_DATES_FILTER_LOCATIONS_OPTIONS',
    //UPDATE_COURSE_DATES_FILTER_SCHEMA = 'UPDATE_COURSE_DATES_FILTER_SCHEMA',
    SET_LOADING = 'SET_LOADING'
}

export const mutations: MutationTree<RegistrationsState> = {
    [RegistrationsMutations.SET_REGISTRATIONS]: (state: RegistrationsState, registrations: RegistrationInterface[]) => {
        state.registrations = registrations
    },

    [RegistrationsMutations.SET_REGISTRATIONS_PAGINATION]: (state: RegistrationsState, pagination: PaginationInterface) => {
        state.pagination = pagination
    },

    [RegistrationsMutations.SET_LOADING]: (state: RegistrationsState, loading: boolean) => {
        state.loading = loading
    },

    [RegistrationsMutations.UPDATE_REGISTRATIONS_FILTER]: (state: RegistrationsState, registrationsFilter: RegistrationsFilterInterface) => {
        state.registrationsFilter = Object.assign(state.registrationsFilter, registrationsFilter);
    },
}
