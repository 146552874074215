import i18n from '@/i18n'
import yup from '@/yup'
import { FieldInterface } from '@/models/forms/schema/Field.interface'
import { RegistrationStatus } from '@/models/registration/Registration.types'
import { registrationStatusFilter } from '@/filters';

export function generateFieldStatus(options: any = {}){
    const choices = [{
        label: i18n.global.t('word.filter.all'),
        value: 'all'
    }]

    choices.push(...[RegistrationStatus.REGISTERED, RegistrationStatus.CANCELLED, RegistrationStatus.WAITING, RegistrationStatus.NEW].map(status => {
        return {
            label: registrationStatusFilter(status),
            value: status
        }
    }))

    let statusField: FieldInterface = {
        component: 'Select',
        model: 'status',
        required: true,
        label:{
            text: i18n.global.t('word.state')
        },
        choices,
        validation: yup.string()
            .required()
            .label(i18n.global.t('word.state'))
    }

    statusField = Object.assign(statusField, options)

    return statusField
}
