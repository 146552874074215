import { ActionTree } from 'vuex'
import { RegistrationsState } from '@/store/modules/registrations/Registrations'
import { AxiosResponse } from 'axios'
import { StoreModuleNames } from '@/store/ModuleNames'
import { GeneralActions } from '@/store/modules/general/General.actions'
import { RegistrationsMutations } from '@/store/modules/registrations/Registrations.mutations'
import { RegistrationInterface } from '@/models/registration/Registration.interface'
import Api from '@/api/Api'
import { apiFormatOrderFilter } from '@/api/src/Api'
import { dateStringToFilterParams } from '@/functions'

export enum RegistrationsActions {
    GET_REGISTRATIONS = 'GET_REGISTRATIONS',
    GET_DATA = 'GET_DATA',
}

export const actions: ActionTree<RegistrationsState, any> = {
    [RegistrationsActions.GET_REGISTRATIONS]: async ({
        state,
        commit,
        dispatch
     }, {
        page
    }) => {
        commit(RegistrationsMutations.SET_LOADING, true)

        try {
            const filters = Object.assign({}, state.registrationsFilter)
            let filterParams = {
                ...apiFormatOrderFilter(filters),
                itemsPerPage: 30,
                _page: page
            }

            if(filters.dateRange !== 'all'){
                filterParams = Object.assign(filterParams, dateStringToFilterParams(filters.dateRange, 'checkoutCompletedAt' === filters.order_by ? 'checkoutCompletedAt' : 'createdAt'))
            }

            if (state.registrationsFilterSchema.model.status && state.registrationsFilterSchema.model.status !== 'all') {
                filterParams['status'] = state.registrationsFilterSchema.model.status
            }

            const response: AxiosResponse<RegistrationInterface> = await Api.registrations.index({
                params: {
                    ...filterParams,
                    'exists[student]': true
                }
            })

            commit(RegistrationsMutations.SET_REGISTRATIONS_PAGINATION, response.data['hydra:view'])
            commit(RegistrationsMutations.SET_REGISTRATIONS, response.data['hydra:member'])
            commit(RegistrationsMutations.SET_LOADING, false)
        } catch(e){
            await dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })

            commit(RegistrationsMutations.SET_LOADING, false)
        }
    }
}
