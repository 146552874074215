import { GetterTree } from 'vuex'
import i18n from '@/i18n'
import { UserState } from '@/store/modules/user/User'
import { collect } from 'collect.js'

export const getters: GetterTree<UserState, any> = {
    isAdmin(state){
        if(state.user !== null){
            const roles = collect(state.user.roles)
            return roles.contains('ROLE_ADMIN') || roles.contains('ROLE_SUPER_ADMIN')
        }

        return false
    },
    getRole(state, getters){
        if(state.user !== null) {
            let role = i18n.global.t('word.role.user')

            if (getters.isAdmin) {
                role = i18n.global.t('word.role.admin')
            }

            return role
        }
    }
}
