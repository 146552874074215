import { MutationTree } from 'vuex'
import { RegistrationInterface } from '@/models/registration/Registration.interface';
import { RegistrationState } from '@/store/modules/registration/Registration';
import { generateRegistrationCompletionSchema } from '@/forms/registration/RegistrationCompletion.schema';
import { initialState } from '@/store/modules/registration/Registration'
import { PaymentInterface } from '@/models/payment/Payment.interface';
import { CourseEntryInterface } from '@/models/course-entries/CourseEntry.interface';
import i18n from '@/i18n';
import { collect } from 'collect.js';
import { generateRegistrationSchema } from '@/forms/registration/Registration.schema'
import { FieldChoiceInterface } from '@/models/forms/schema/FieldOptions.interface'
import { CourseTypes } from "@/models/course/Course.types";

export enum RegistrationMutations {
    SET_REGISTRATION = 'SET_REGISTRATION',
    SET_SCHEMA_COURSE_ENTRIES_CHOICES = 'SET_SCHEMA_COURSE_ENTRIES_CHOICES',
    SET_SCHEMA_COMPLETION_COURSE_ENTRIES_CHOICES = 'SET_SCHEMA_COMPLETION_COURSE_ENTRIES_CHOICES',
    SET_REGISTRATION_ENTRY_CHANGED = 'SET_REGISTRATION_ENTRY_CHANGED',
    SET_LOADING = 'SET_LOADING',
    SET_PAYMENT = 'SET_PAYMENT',
    RESET_STATE = 'RESET_STATE'
}

// mutations
export const mutations: MutationTree<RegistrationState> = {
    [RegistrationMutations.SET_REGISTRATION]: (state: RegistrationState, registration: RegistrationInterface) => {
        state.registration = registration
        state.registrationCompletionSchema.model = JSON.parse(JSON.stringify(registration))
    },

    [RegistrationMutations.SET_SCHEMA_COURSE_ENTRIES_CHOICES]: (state: RegistrationState, choices: FieldChoiceInterface[]) => {
        state.registrationSchema.fields.courseEntry.choices = choices
    },

    [RegistrationMutations.SET_SCHEMA_COMPLETION_COURSE_ENTRIES_CHOICES]: (state: RegistrationState, entries: CourseEntryInterface[]) => {
        // Sort entries
        entries = collect(entries).sortBy((entry) => new Date(entry.startDate)).all()

        // Set choices from response
        let choices = entries.map(entry => {
            console.warn('-- choice:' + entry.type)

            return {
                label: entry.type === CourseTypes.elearning ? `${entry.course.name} - ${i18n.global.t('word.course.type.elearning')}` : `${i18n.global.d(new Date(entry.startDate), 'longDate')} - ${i18n.global.t(`word.course.type.${entry.type}`)}`,
                value: entry['@id']
            }
        })

        // Add current choice (because we filter the courseEntries dates starting from today)
        if(typeof state.registration.courseEntry === 'object') {
            choices.push({
                label: `${i18n.global.d(new Date(state.registration.courseEntry.startDate), 'longDate')} - ${i18n.global.t(`word.course.type.${state.registration.courseEntry.type}`)}`,
                value: state.registration.courseEntry['@id']
            })
        }

        // Unique values
        choices = collect(choices)
            .unique('value')
            .all()

        state.registrationCompletionSchema.fields.courseEntry.choices = choices
    },

    [RegistrationMutations.SET_PAYMENT]: (state: RegistrationState, payment: PaymentInterface) => {
        state.payment = payment
    },

    [RegistrationMutations.SET_LOADING]: (state: RegistrationState, loading: boolean) => {
        state.loading = loading
    },

    [RegistrationMutations.RESET_STATE]: (state: RegistrationState) => {
        Object.assign(state, initialState)

        // Reset schema
        state.registrationCompletionSchema = generateRegistrationCompletionSchema()
        state.registrationSchema = generateRegistrationSchema()
    },

    [RegistrationMutations.SET_REGISTRATION_ENTRY_CHANGED]: (state: RegistrationState, payload) => {
        state.registrationEntryChanged = payload
    }
}
