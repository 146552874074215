import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export function generateFieldCourseEntry(options: any = {}){
    let courseEntryField: FieldInterface = {
        component: 'Select',
        model: 'courseEntry',
        required: true,
        label:{
            text: i18n.global.t('word.course.entry')
        },
        choices:[{
            label: i18n.global.t('word.course.entry'),
            value: ''
        }]
    }

    courseEntryField = Object.assign(options, courseEntryField)

    return courseEntryField
}
