import Api from '@/api/src/Api'
import CoursesApi from '@/api/modules/courses/Courses'
import { AxiosPromise } from 'axios';
import { StudentInterface } from '@/models/student/Student.interface';

export default class StudentsApi extends Api {
    constructor() {
        super()

        this.endPoint = 'students'
    }

    update(id: string, data: Partial<StudentInterface>): AxiosPromise {
        delete data.registrations

        return this.axios.put(`${this.endPoint}/${id}`, data)
    }

    /**
     * @todo temporary solution for json-server
     */
    getRecommendedCourses(uuid: string, options = {}){
        const coursesApi = new CoursesApi;

        return coursesApi.index(options)
    }

    /**
     * Send vademecum reminder
     * @param {StudentInterface} student
     * @param {string[]} data
     * @returns {Promise<AxiosResponse<any>>}
     */
    sendVademecumReminder(student: StudentInterface){
        return this.axios.post(`${this.endPoint}/${student.id}/order-vademecum`)
    }
}
