import { MutationTree } from "vuex";
import { CourseEntryInterface } from "@/models/course-entries/CourseEntry.interface";
import {
  CourseEntryState,
  initialState,
} from "@/store/modules/courseEntry/CourseEntry";
import { RegistrationInterface } from "@/models/registration/Registration.interface";
import {
  generateCourseEntrySchema,
  generateEmptyCourseDateModel,
} from "@/forms/courseEntry/CourseEntry.schema";
import { FieldChoiceInterface } from "@/models/forms/schema/FieldOptions.interface";
import { dateTimeGetMinutes } from "@/lang/dateFormatters";
import PaginationInterface from "@/models/pagination/Pagination.interface";

export enum CourseEntryMutations {
  SET_COURSE_ENTRIES = "SET_COURSE_ENTRIES",
  SET_ACTIVE_COURSE_ENTRIES = "SET_ACTIVE_COURSE_ENTRIES",
  SET_REGISTRATIONS = "SET_REGISTRATIONS",
  SET_REGISTRATIONS_PAGINATION = "SET_REGISTRATIONS_PAGINATION",
  SET_WAITING_LISTS = "SET_WAITING_LISTS",
  SET_CANCELLATIONS = "SET_CANCELLATIONS",
  REMOVE_WAITING_LIST_REGISTRATION = "REMOVE_WAITING_LIST_REGISTRATION",
  SET_COURSE_ENTRY = "SET_COURSE_ENTRY",
  SET_COURSE_DATES_TEACHERS_OPTIONS = "SET_COURSE_DATES_TEACHERS_OPTIONS",
  SET_COURSE_DATES_LOCATIONS_OPTIONS = "SET_COURSE_DATES_LOCATIONS_OPTIONS",
  ADD_COURSE_DATE = "ADD_COURSE_DATE",
  REMOVE_COURSE_DATE = "REMOVE_COURSE_DATE",
  SET_EXPORT_LINK = "SET_EXPORT_LINK",
  SET_EXPORT_BULK_LINK = "SET_EXPORT_BULK_LINK",

  ADD_PART = "ADD_PART",
  REMOVE_PART = "REMOVE_PART",

  CREATE_COURSE_ENTRY = "CREATE_COURSE_ENTRY",
  SET_LOADING = "SET_LOADING",
  RESET_STATE = "RESET_STATE",
}

export const mutations: MutationTree<CourseEntryState> = {
  [CourseEntryMutations.SET_COURSE_ENTRIES]: (
    state: CourseEntryState,
    courseEntries: CourseEntryInterface[],
  ) => {
    state.courseEntries = courseEntries;
  },

  [CourseEntryMutations.SET_ACTIVE_COURSE_ENTRIES]: (
    state: CourseEntryState,
    courseEntries: CourseEntryInterface[],
  ) => {
    state.activeCourseEntries = courseEntries;
  },

  [CourseEntryMutations.SET_REGISTRATIONS]: (
    state: CourseEntryState,
    registrations: RegistrationInterface[],
  ) => {
    state.registrations = registrations;
  },

  [CourseEntryMutations.SET_REGISTRATIONS_PAGINATION]: (
    state: CourseEntryState,
    pagination: PaginationInterface,
  ) => {
    state.registrationsPagination = pagination;
  },

  [CourseEntryMutations.SET_WAITING_LISTS]: (
    state: CourseEntryState,
    waitingLists: RegistrationInterface[],
  ) => {
    state.waitingLists = waitingLists;
  },

  [CourseEntryMutations.SET_CANCELLATIONS]: (
    state: CourseEntryState,
    cancellations: RegistrationInterface[],
  ) => {
    state.cancellations = cancellations;
  },

  [CourseEntryMutations.REMOVE_WAITING_LIST_REGISTRATION]: (
    state: CourseEntryState,
    registration: RegistrationInterface,
  ) => {
    state.waitingLists.splice(state.waitingLists.indexOf(registration), 1);
  },

  [CourseEntryMutations.SET_COURSE_ENTRY]: (
    state: CourseEntryState,
    courseEntry: CourseEntryInterface,
  ) => {
    state.courseEntry = courseEntry;
    state.courseEntrySchema.model = JSON.parse(JSON.stringify(courseEntry));

    if (courseEntry) {
      const courseEntryModel = JSON.parse(JSON.stringify(courseEntry));
      courseEntryModel.location = courseEntryModel.location["@id"];
      courseEntryModel.courseDates = courseEntryModel.courseDates.map(
        (courseDate) => {
          courseDate.teacher =
            courseDate.teacher !== null
              ? courseDate.teacher["@id"]
              : courseDate.teacher;
          courseDate.startDate = dateTimeGetMinutes(courseDate.startDate);
          courseDate.endDate = dateTimeGetMinutes(courseDate.endDate);

          return courseDate;
        },
      );

      state.courseEntrySchema.model = courseEntryModel;
    }
  },

  [CourseEntryMutations.SET_LOADING]: (
    state: CourseEntryState,
    loading: boolean,
  ) => {
    state.loading = loading;
  },

  [CourseEntryMutations.RESET_STATE]: (state: CourseEntryState) => {
    Object.assign(state, initialState);

    state.courseEntrySchema = generateCourseEntrySchema();
  },

  [CourseEntryMutations.SET_COURSE_DATES_TEACHERS_OPTIONS]: (
    state: CourseEntryState,
    teachers: FieldChoiceInterface[],
  ) => {
    state.courseEntrySchema.fields.courseDates.teacher.choices = teachers;
  },

  [CourseEntryMutations.SET_COURSE_DATES_LOCATIONS_OPTIONS]: (
    state: CourseEntryState,
    locations: FieldChoiceInterface[],
  ) => {
    state.courseEntrySchema.fields.location.choices = locations;
  },

  [CourseEntryMutations.ADD_COURSE_DATE]: (state: CourseEntryState) => {
    state.courseEntrySchema.model.courseDates.push(
      generateEmptyCourseDateModel(),
    );
  },

  [CourseEntryMutations.REMOVE_COURSE_DATE]: (
    state: CourseEntryState,
    { index },
  ) => {
    state.courseEntrySchema.model.courseDates[index].deletedAt = new Date();
  },

  [CourseEntryMutations.ADD_PART]: (state: CourseEntryState) => {
    state.courseEntrySchema.model.parts.push("");
  },

  [CourseEntryMutations.REMOVE_PART]: (state: CourseEntryState, { index }) => {
    state.courseEntrySchema.model.parts.splice(index, 1);
  },

  [CourseEntryMutations.SET_EXPORT_LINK]: (
    state: CourseEntryState,
    link: string,
  ) => {
    state.exportLink = link;
  },

  [CourseEntryMutations.SET_EXPORT_BULK_LINK]: (
    state: CourseEntryState,
    link: string,
  ) => {
    state.exportBulkLink = link;
  },

  //[CourseEntryMutations.ADD_COURSE_DATE]: (state: CourseEntryState) => {
  //    state.courseDates.push( generateEmptyCourseDateModel() )
  //},

  //[CourseEntryMutations.DELETE_COURSE_DATE]: (state: CourseEntryState) => {
  //    state.courseDates.push( generateEmptyCourseDateModel() )
  //}
};
