import { MutationTree } from 'vuex'
import { LocationState } from '@/store/modules/locations/Locations'
import { LocationInterface } from '@/models/locations/Location.interface'
import { LocationsFilterInterface } from '@/models/locations/LocationsFilter.interface';

export enum LocationsMutations {
    SET_LOCATIONS = 'SET_LOCATIONS',
    //ADD_NEW_LOCATION = 'ADD_NEW_LOCATION',
    //CREATE_LOCATION = 'CREATE_LOCATION',
    //UPDATE_LOCATION = 'UPDATE_LOCATION',
    //DELETE_LOCATION = 'DELETE_LOCATION',
    UPDATE_LOCATIONS_FILTER = 'UPDATE_LOCATIONS_FILTER'
}

// mutations
export const mutations: MutationTree<LocationState> = {
    [LocationsMutations.SET_LOCATIONS]: (state: LocationState, locations: LocationInterface[]) => {
        state.locations = locations
        //state.locationSchema.model.locations = JSON.parse(JSON.stringify(locations))
    },

    //[LocationsMutations.ADD_NEW_LOCATION]: (state: LocationState, location: LocationInterface) => {
    //    state.locationSchema.model.locations.push(location)
    //},
    //
    //[LocationsMutations.CREATE_LOCATION]: (state: LocationState, location: LocationInterface) => {
    //    state.locations.push(location)
    //},
    //
    //[LocationsMutations.UPDATE_LOCATION]: (state: LocationState, location: LocationInterface) => {
    //    const index = getIndexById(state.locations, location)
    //
    //    state.locations[index] = location
    //},
    //
    //[LocationsMutations.DELETE_LOCATION]: (state: LocationState, index: number) => {
    //    //const index = getIndexById(state.locationSchema.model.locations, location)
    //    //return state.locationSchema.model.locations.map(i => i.id).indexOf(index);
    //
    //    //state.locations.splice(1, index)
    //    state.locationSchema.model.locations.splice(index,1)
    //},

    [LocationsMutations.UPDATE_LOCATIONS_FILTER]: (state: LocationState, locationsFilter: LocationsFilterInterface) => {
        state.locationsFilter = Object.assign(state.locationsFilter, locationsFilter);
    },
}
