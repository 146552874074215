import { MutationTree } from 'vuex'
import { LocationState, initialState } from '@/store/modules/location/Location';
import { generateLocationSchema } from '@/forms/location/Location.schema';
import { LocationInterface } from '@/models/locations/Location.interface';
import { CourseDateInterface } from '@/models/course/CourseDate.interface';

export enum LocationMutations {
    SET_LOCATION = 'SET_LOCATION',
    SET_COURSE_DATES = 'SET_COURSE_DATES',
    SET_LOADING = 'SET_LOADING',
    RESET_STATE = 'RESET_STATE'
}

// mutations
export const mutations: MutationTree<LocationState> = {
    [LocationMutations.SET_LOCATION]: (state: LocationState, location: LocationInterface) => {
        state.location = location
        state.locationSchema.model = JSON.parse(JSON.stringify(location))
    },

    [LocationMutations.SET_COURSE_DATES]: (state: LocationState, courseDates: CourseDateInterface[]) => {
        state.courseDates = courseDates
    },

    [LocationMutations.SET_LOADING]: (state: LocationState, loading: boolean) => {
        state.loading = loading
    },

    [LocationMutations.RESET_STATE]: (state: LocationState) => {
        Object.assign(state, initialState)

        // Reset schema
        state.locationSchema = generateLocationSchema()
    }
}
