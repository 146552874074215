<template>
    <MenuWalker prefixClass="menu-main" :routes="routes" />
</template>

<script>
    import MenuWalker from '@/components/menus/MenuWalker.vue'
    import { reactive, computed, toRefs, defineComponent } from "vue";

    export default defineComponent({
        components:{
            MenuWalker
        },
        setup(){
            const data = reactive({
                routes: computed(() => {
                    return [
                        { route:'dashboard' },
                        { route:'registrationsIndex' },
                        { route:'coursesIndex' },
                        { route:'agenda' },
                        { route:'studentsIndex' },
                        { route:'teachersIndex' },
                        { route:'locationsIndex' }
                    ]
                })
            })

            return {
                ...toRefs(data)
            }
        }
    })
</script>

<style lang="scss">
    @use '~@/assets/scss/core/functions';
    @use '~@/assets/scss/core/mixins';
    @use '~@/assets/scss/core/config/colors';
    @use '~@/assets/scss/core/config/typography';

    .menu-main {
        &__item {
            position: relative;
            display:flex;
            opacity:0.6;
            transition: functions.trans(opacity);

            @include mixins.current-state{
                opacity:1;

                .menu-main{
                    &__icon-current{
                        opacity:1;
                    }

                    &__icon-default{
                        opacity:0;
                    }
                }
            }

            @include mixins.active{
                opacity:1;
            }
        }

        &__icon{
            display:flex;
            align-items:center;
            position:absolute;
            left:0;
        }

        &__icon-default, &__icon-current{
            position:absolute;
            left:2.5rem;
        }

        &__link {
            display: flex;
            align-items:center;
            color: colors.$primary-text;
            padding:1rem 4rem 1rem 6.5rem;
            margin:{
                top:0.5rem;
                bottom:0.5rem;
            }
            font:{
                size:1.5rem;
                weight: typography.$weight-bold;
            }
        }

    }
</style>


