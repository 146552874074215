import { GetterTree } from 'vuex'
import { CourseEntryState } from '@/store/modules/courseEntry/CourseEntry'
import i18n from '@/i18n'
import { FieldChoiceInterface } from '@/models/forms/schema/FieldOptions.interface'
import { CourseTypes } from "@/models/course/Course.types";

export const getters: GetterTree<CourseEntryState, any> = {
    getCourseEntriesOptions: (state) => {
        if(state.activeCourseEntries && state.activeCourseEntries.length > 0) {
            const choices: FieldChoiceInterface[] = [{
                label: i18n.global.t('word.course.entry'),
                value: ''
            }]

            choices.push(...state.activeCourseEntries.map(entry => {
                return {
                    label: entry.type === CourseTypes.elearning ? `${entry.course.name} - ${i18n.global.t('word.course.type.elearning')}` : `${entry.course.name} - ${i18n.global.d(new Date(entry.startDate), 'shortDate')}`,
                    value: entry['@id']
                }
            }))

            return choices
        }

        return [{
            label: '',
            value: ''
        }]
    }
}
