<template>
    <div>
        <Layout v-if="route.meta.layout === 'layout/Layout'">
            <router-view/>
        </Layout>
        <GeneralRouterView v-if="route.meta.layout === 'general/GeneralRouterView'">
            <router-view/>
        </GeneralRouterView>
    </div>
</template>

<script>
    import { defineComponent, watchEffect } from "vue";
    import {useRoute} from 'vue-router';

    import Layout from '@/components/layout/Layout.vue'
    import GeneralRouterView from '@/components/general/GeneralRouterView.vue'
    import { useStore } from 'vuex';

    export default defineComponent({
        name: 'App',
        components: {
            Layout,
            GeneralRouterView
        },
        setup() {
            const route = useRoute()
            const store = useStore()

            watchEffect(() => {
                document.title = store.state.Seo.title

            })

            // @todo use definedAsyncComponent for loading components, this is not working because the state / route.meta is not available when loading the component
            //const componentInstance = computed(() => {
            //    return defineAsyncComponent({
            //        loader: () => import(`@/components/${layout.value}`)
            //    })
            //})

            return {
                route,
                //componentInstance
            }
        }
    })
</script>

<style lang="scss">
    @use '~@/assets/scss/core.scss';
</style>
