import { MutationTree } from 'vuex'
import { AuthFormState, AuthState } from '@/store/modules/auth/Auth'

export enum AuthMutations {
    SET_FORM_STATE = 'SET_FORM_STATE',
    SET_LOADING = 'SET_LOADING'
}

// mutations
export const mutations: MutationTree<AuthState> = {
    [AuthMutations.SET_LOADING]: (state: AuthState, loading: boolean) => {
        state.loading = loading
    },

    [AuthMutations.SET_FORM_STATE]: (state: AuthState, formState: AuthFormState) => {
        state.formState = formState
    }
}
