import { ActionTree } from 'vuex'
import { AxiosResponse } from 'axios'
import Api from '@/api/Api'
import { LocationInterface } from '@/models/locations/Location.interface';
import { LocationState } from '@/store/modules/location/Location';
import { LocationMutations } from '@/store/modules/location/Location.mutations';

export enum LocationActions {
    GET_LOCATION = 'GET_LOCATION',
    GET_COURSE_DATES = 'GET_COURSE_DATES',
    CREATE_LOCATION = 'CREATE_LOCATION',
    UPDATE_LOCATION = 'UPDATE_LOCATION',
    DELETE_LOCATION = 'DELETE_LOCATION'
}

// actions
export const actions: ActionTree<LocationState, any> = {
    /**
     * Get location
     * @param {Commit} commit
     * @param {any} id
     * @returns {Promise<void>}
     */
    [LocationActions.GET_LOCATION]: async ({commit}, {
        id
    }) => {
        commit(LocationMutations.RESET_STATE)

        const response: AxiosResponse<LocationInterface> = await Api.locations.showById(id)
        commit(LocationMutations.SET_LOCATION, response.data)
    },

    [LocationActions.GET_COURSE_DATES]: async ({commit, state}) => {
        const response: AxiosResponse<LocationInterface> = await Api.courseDates.index({
            params: {
                'deletedAt[null]': true,
                'archivedAt[null]': true,
                'courseEntry.location.id' : state.location['@id']
            }
        })

        commit(LocationMutations.SET_COURSE_DATES, response.data['hydra:member'])
    },

    /**
     * Create location
     * @param {ActionContext<LocationState, any>} context
     * @returns {Promise<void>}
     */
    [LocationActions.CREATE_LOCATION]: async (context) => {
        const response: AxiosResponse<LocationInterface> = await Api.locations.create(context.state.locationSchema.model)
        context.commit(LocationMutations.SET_LOCATION, response.data)
    },

    /**
     * Update location
     * @param {ActionContext<LocationState, any>} context
     * @returns {Promise<void>}
     */
    [LocationActions.UPDATE_LOCATION]: async (context) => {
        const model = context.state.locationSchema.model as LocationInterface
        const response: AxiosResponse<LocationInterface> = await Api.locations.update(model.id, model)
        context.commit(LocationMutations.SET_LOCATION, response.data)
    },

    /**
     * Delete location
     * @param {ActionContext<LocationState, any>} context
     * @returns {Promise<void>}
     */
    [LocationActions.DELETE_LOCATION]: async (context) => {
        try {
            await Api.locations.delete(context.state.locationSchema.model.id)
        } catch(e){
            console.log(e)
        }
    }
}
