import { ActionTree } from 'vuex'
import { AxiosResponse } from 'axios'
import { CourseDateInterface } from '@/models/course/CourseDate.interface'
import Api from '@/api/Api'
import { DashboardState } from '@/store/modules/dashboard/Dashboard'
import { DashboardMutations } from '@/store/modules/dashboard/Dashboard.mutations'
import { dayjs } from '@/main'
import { API_DATE_FORMAT } from '@/api/src/Api'
import {collect} from "collect.js";

export enum DashboardActions {
    GET_NEW_ENTRIES = 'GET_NEW_ENTRIES',
    GET_COMPLETED_ENTRIES = 'GET_COMPLETED_ENTRIES',
    GET_STARTING_SOON_ENTRIES = 'GET_STARTING_SOON_ENTRIES',
    GET_ALL_ENTRIES = 'GET_ALL_ENTRIES'
}

// actions
export const actions: ActionTree<DashboardState, any> = {
    /**
     * New entries
     * @param {Commit} commit
     * @param {DashboardState} state
     * @returns {Promise<void>}
     */
    [DashboardActions.GET_NEW_ENTRIES]: async ({commit, state}) => {
        let response = null;
        if(state.newEntriesNextLink){
            response = await Api.instance.showByUri(state.newEntriesNextLink)
            commit(DashboardMutations.UPDATE_NEW_ENTRIES, response.data['hydra:member'])
        } else {
            response = await Api.courses.index({
                params: {
                    'order[stats.fullEditions]': 'DESC',
                    'itemsPerPage': 5,
                    'needNewEntries': true
                }
            })
            commit(DashboardMutations.SET_NEW_ENTRIES, response.data['hydra:member'])
        }

        commit(DashboardMutations.SET_NEW_ENTRIES_NEXT_LINK, collect(response.data['hydra:view']).get('hydra:next'))
    },

    /**
     * Completed entries
     * @param {Commit} commit
     * @returns {Promise<void>}
     */
    [DashboardActions.GET_COMPLETED_ENTRIES]: async ({ commit }) => {
        const response: AxiosResponse<CourseDateInterface> = await Api.courseEntries.index({
            params:{
                'gradesCompleted': false,
                'isCompleted': true,
                itemsPerPage: 5
            }
        })

        commit(DashboardMutations.SET_COMPLETED_ENTRIES, response.data['hydra:member'])
    },

    /**
     * Starting soon entries
     * @param {Commit} commit
     * @returns {Promise<void>}
     */
    [DashboardActions.GET_STARTING_SOON_ENTRIES]: async ({commit}) => {
        const response: AxiosResponse<CourseDateInterface> = await Api.courseEntries.index({
            params:{
                'order[startDate]': 'asc',
                'startDate[after]': dayjs().add(7, 'day').format(API_DATE_FORMAT),
                'status[planned]' : false,
                itemsPerPage: 5
            }
        })
        commit(DashboardMutations.SET_STARTING_SOON_ENTRIES, response.data['hydra:member'])
    },

    /**
     * Load all entries
     * @param {Commit} commit
     * @param {Dispatch} dispatch
     * @returns {Promise<void>}
     */
    [DashboardActions.GET_ALL_ENTRIES]: async ({commit, dispatch}) => {
        commit(DashboardMutations.RESET_STATE)
        commit(DashboardMutations.SET_LOADING, true)

        await dispatch(DashboardActions.GET_NEW_ENTRIES)
        await dispatch(DashboardActions.GET_COMPLETED_ENTRIES)
        await dispatch(DashboardActions.GET_STARTING_SOON_ENTRIES)

        commit(DashboardMutations.SET_LOADING, false)
    }
}
