import i18n from '@/i18n';

const Courses = () => import(/* webpackChunkName: "group-courses" */ '@/views/app/courses/CoursesIndex.vue')
const CourseSingle = () => import(/* webpackChunkName: "group-courses" */ '@/views/app/courses/CourseSingle.vue')
const CourseEntry = () => import(/* webpackChunkName: "group-courses" */ '@/views/app/course-entry/CourseEntry.vue')
const CourseCrud = () => import(/* webpackChunkName: "group-courses" */ '@/views/app/courses/CourseCrud.vue')
const CourseCrudCosts = () => import(/* webpackChunkName: "group-courses" */ '@/views/app/courses/crud/CourseCrudCosts.vue')
const CourseCrudIndex = () => import(/* webpackChunkName: "group-courses" */ '@/views/app/courses/crud/CourseCrudIndex.vue')
const CourseEntryCrud = () => import(/* webpackChunkName: "group-courses" */ '@/views/app/course-entry/CourseEntryCrud.vue')
const CourseEntriesArchive = () => import(/* webpackChunkName: "group-courses" */ '@/views/app/course-entries/CourseEntriesArchive.vue')
const CourseEntryRegistrations = () => import(/* webpackChunkName: "group-courses" */ '@/views/app/course-entry/CourseEntryRegistrations.vue')
const CourseEntryRegistration = () => import(/* webpackChunkName: "group-courses" */ '@/views/app/course-entry/CourseEntryRegistration.vue')

import GeneralRouterView from '@/components/general/GeneralRouterView.vue'
import menuCoursesIcon from '@/assets/images/icons/menu-course-icon.svg'

export default [
    {
        path: '/courses',
        name: 'courses',
        component: GeneralRouterView,
        meta: {
            'label': 'word.courses',
            'icon': menuCoursesIcon,
            'iconCurrent': menuCoursesIcon,
            meta:{
                seo:{
                    title: i18n.global.t('word.courses')
                }
            }
        },
        children:[
            {
                path: '',
                name: 'coursesIndex',
                component: Courses,
                meta:{
                    seo:{
                        title: i18n.global.t('word.courses')
                    }
                }
            },
            {
                path: ':id',
                name: 'courseSingle',
                component: CourseSingle,
                meta:{
                    seo:{
                        title: i18n.global.t('word.course')
                    }
                }
            },
            {
                path: ':id/entries/archive',
                name: 'courseEntriesArchive',
                component: CourseEntriesArchive,
                meta:{
                    seo:{
                        title: i18n.global.t('word.archive')
                    }
                }
            },
            {
                path: ':id/entries/:entryId',
                name: 'courseEntry',
                component: CourseEntry,
                meta:{
                    seo:{
                        title: i18n.global.t('word.course.entry')
                    }
                }
            },
            {
                path: ':id/entries/:entryId/registrations',
                name: 'courseEntryStudents',
                component: CourseEntryRegistrations,
                meta:{
                    seo:{
                        title: i18n.global.t('word.registrations')
                    }
                }
            },
            {
                path: ':id/entries/:entryId/registrations/:registrationId',
                name: 'courseEntryRegistration',
                component: CourseEntryRegistration,
                meta:{
                    seo:{
                        title: i18n.global.t('word.grades')
                    }
                }
            },
            {
                path: ':id/entries/:entryId/update',
                name: 'courseEntryUpdate',
                component: CourseEntryCrud,
                meta:{
                    seo:{
                        title: i18n.global.t('word.update.course.entry')
                    }
                }
            },
            {
                path: ':id/entries/create',
                name: 'courseEntryCreate',
                component: CourseEntryCrud,
                meta:{
                    seo:{
                        title: i18n.global.t('word.create.course.entry')
                    }
                }
            },
            {
                path: 'create',
                name: 'courseCreate',
                component: CourseCrud,
                children:[{
                    path:'',
                    name:'courseCreateIndex',
                    component: CourseCrudIndex,
                    meta:{
                        seo:{
                            title: i18n.global.t('word.create.course')
                        }
                    }
                }, {
                    path:'costs',
                    name:'courseCreateCosts',
                    component: CourseCrudCosts,
                    meta:{
                        seo:{
                            title: i18n.global.t('word.costs')
                        }
                    }
                }]
            },
            {
                path: ':id/update',
                name: 'courseUpdate',
                component: CourseCrud,
                children:[{
                    path:'',
                    name:'courseUpdateIndex',
                    component: CourseCrudIndex,
                    meta:{
                        seo:{
                            title: i18n.global.t('word.update.course.entry')
                        }
                    }
                }, {
                    path:'costs',
                    name:'courseUpdateCosts',
                    component: CourseCrudCosts,
                    meta:{
                        seo:{
                            title: i18n.global.t('word.costs')
                        }
                    }
                }]
            }
        ]
    }
]
