import { getters } from '@/store/modules/teacher/Teacher.getters'
import { actions } from '@/store/modules/teacher/Teacher.actions'
import { mutations } from '@/store/modules/teacher/Teacher.mutations'
import { TeacherInterface } from '@/models/teachers/Teacher.interface'
import { TeacherSchemaInterface, generateTeacherSchema } from '@/forms/teacher/Teacher.schema'
import { CourseDateInterface } from '@/models/course/CourseDate.interface'

/**
 * State interface
 */
export interface TeacherState {
    teacher?: TeacherInterface;
    courseDates?: CourseDateInterface[];
    loading: boolean;
    teacherSchema: TeacherSchemaInterface;
}

/**
 * State
 * @type {{teacherSchema: TeacherSchemaInterface, courseDates: null, teacher: null, loading: boolean}}
 */
export const initialState: TeacherState = {
    teacher: null,
    courseDates: null,
    loading: false,
    teacherSchema: generateTeacherSchema()
}

export const state = JSON.parse(JSON.stringify(initialState));

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
