import { MutationTree } from 'vuex'
import { UserInterface } from '@/models/users/User.interface'
import { UserState } from '@/store/modules/user/User'

export enum UserMutations {
    SET_USER = 'SET_USER',
    SET_AUTHENTICATED = 'SET_AUTHENTICATED'
}

// mutations
export const mutations: MutationTree<UserState> = {
    [UserMutations.SET_USER]: (state: UserState, user?: UserInterface) => {
        state.user = user

        if(user === null){
            localStorage.getItem('user') && localStorage.removeItem('user')
        } else {
            localStorage.setItem('user', JSON.stringify(user))
        }

    },
    [UserMutations.SET_AUTHENTICATED]: (state: UserState, authenticated: boolean) => {
        state.authenticated = authenticated
        localStorage.setItem('authenticated', String(authenticated))
    }
}
