import axios, { AxiosError, AxiosInstance } from 'axios'
import { MessageInterface } from '@/models/common/Message.interface'
import i18n from '@/i18n'
import { collect } from 'collect.js'
import router from '@/router'
const errorMessage: MessageInterface = {
    type:'error',
    title: i18n.global.t('title.error.connection'),
    message: i18n.global.t('sentence.error.connection')
}

export const instance: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

// Response interceptors
instance.interceptors.response.use((response) => {
    // Do something with response data
    return response;
}, async (error: AxiosError) => {
    const errorObj: Record<string, any> = error.toJSON()

    if(errorObj.message){
        errorMessage.title = errorObj.name
        errorMessage.message = errorObj.message
    }

    // Specific api platform errors
    if(collect(error.response).has('data') && collect(error.response.data).has('hydra:title')){
        errorMessage.title = error.response.data['hydra:title']
    }

    if(collect(error.response).has('data') && collect(error.response.data).has('hydra:description')){
        errorMessage.message = error.response.data['hydra:description']
    }

    if(error.response.status === 401){
        // I cant' dispatch a store action in axios, because it's not properly loaded.
        // So we redirect to the login page with an revoked query parameter.
        await router.push({
            name: 'authLogin',
            query:{
                'revoked': 'true'
            }
        })
    }
    return Promise.reject(error);
})

// Request interceptors
instance.interceptors.request.use(request => {
    request.headers['Content-Type'] = 'application/ld+json'
    request.withCredentials = true

    return request;
})

export default instance


