import yup from '@/yup'
import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

import { generateFieldInsertion } from '@/forms/common/fields/FieldInsertion'
import { generateFieldLastName } from '@/forms/common/fields/FieldLastName'
import{ generateFieldEmail } from '@/forms/common/fields/FieldEmail'
import { generateFieldPhoneNumber } from '@/forms/common/fields/FieldPhoneNumber'
import { generateFieldFirstName } from '@/forms/common/fields/FieldFirstName'
import { StudentInterface } from '@/models/student/Student.interface';
import { generateFieldSalutation } from '@/forms/common/fields/FieldSalutation';

export interface StudentSchemaFieldInterface {
    firstName: FieldInterface;
    insertion: FieldInterface;
    lastName: FieldInterface;
    salutation: FieldInterface;
    companyName: FieldInterface;
    email: FieldInterface;
    phoneNumber: FieldInterface;
    orderedVademecum: FieldInterface;
    dateOfBirth: FieldInterface;
}

export interface StudentSchemaInterface {
    fields: StudentSchemaFieldInterface;
    model: Partial<StudentInterface>
    validation: Record<any, any>
}

export function generateStudentFieldsSchema(): StudentSchemaFieldInterface {
    return {
        firstName: generateFieldFirstName(),
        insertion: generateFieldInsertion(),
        lastName: generateFieldLastName(),
        salutation: generateFieldSalutation(),
        companyName: {
            component: 'Input',
            type: 'text',
            model: 'companyName',
            required: true,
            label: {
                text: i18n.global.t('word.company.practise')
            }
        },
        email: generateFieldEmail(),
        phoneNumber: generateFieldPhoneNumber(),
        orderedVademecum: {
            component: 'Switch',
            model: 'orderedVademecum',
            required: true,
            label: {
                text: i18n.global.t('word.address')
            },
            validation: yup.bool()
        },
        dateOfBirth: {
            component: 'Input',
            type: 'date',
            model: 'dateOfBirth',
            required: true,
            label: {
                text: i18n.global.t('word.date.of.birth')
            }
        }
    }
}

export function generateStudentModelSchema(): Partial<StudentInterface> {
    return {
        id: null,
        firstName: '',
        insertion: '',
        lastName: '',
        salutation: null,
        companyName: '',
        email: '',
        phoneNumber: '',
        orderedVademecum: false,
        dateOfBirth: null
    }
}

export function generateStudentValidationSchema(): Record<any, any> {
    return {
        firstName: yup.string()
            .required()
            .label(i18n.global.t('word.first.name')),
        insertion: yup.string()
            .nullable()
            .label(i18n.global.t('abbr.insertion')),
        lastName: yup.string()
            .required()
            .label(i18n.global.t('word.last.name')),
        salutation: null,
        companyName: yup.string()
            .label(i18n.global.t('word.company.practise')),
        email: yup.string()
            .required()
            .email()
            .label(i18n.global.t('word.email')),
        phoneNumber: yup.string()
            .nullable()
            .label(i18n.global.t('word.phone.number')),
        orderedVademecum: null
    }
}

export function generateStudentSchema(): StudentSchemaInterface{
    return {
        fields: generateStudentFieldsSchema(),
        model: generateStudentModelSchema(),
        validation: generateStudentValidationSchema()
    }
}
