import { FieldInterface } from '@/models/forms/schema/Field.interface';
import i18n from '@/i18n';
import { CourseEntryPlatformTypes } from '@/models/course-entries/Types';

export function generateFieldCourseEntryPlatformType(options: any = {}){
    let courseTypes: FieldInterface = {
        component: 'Select',
        model: 'platform',
        required: true,
        choices: [
            {
                label: i18n.global.t(`word.course.entry.platform.type.${CourseEntryPlatformTypes.zoom}`),
                value: CourseEntryPlatformTypes.zoom
            },
            {
                label: i18n.global.t(`word.course.entry.platform.type.${CourseEntryPlatformTypes.webinargeek}`),
                value: CourseEntryPlatformTypes.webinargeek
            }
        ],
        label:{
            text: i18n.global.t('word.course.entry.platform')
        }
    }

    courseTypes = Object.assign(options, courseTypes)

    return courseTypes
}
