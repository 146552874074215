import { createI18n, IntlDateTimeFormats, LocaleMessages, VueMessageType } from 'vue-i18n'

/**
 * Load locale messages from json files
 * @returns {{messages: LocaleMessages<string | VNode<RendererNode, RendererElement, {[p: string]: any}>>; dateTimeFormats: DateTimeFormats}}
 */

function loadLocaleMessages() {
  const locales = require.context('@/lang', true, /\.json$/)
  const messages: LocaleMessages<VueMessageType> = {}
  const dateTimeFormats: IntlDateTimeFormats = {}

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-]+)./i)

    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).messages
      dateTimeFormats[locale] = locales(key).dateTimeFormats
    }
  })

  return {
    messages,
    dateTimeFormats
  }
}

const locales = loadLocaleMessages()

export default createI18n({
  availableLocales: ['nl'],
  locale: 'nl',
  fallbackLocale: 'en',
  sharedMessages: locales.messages,
  datetimeFormats: locales.dateTimeFormats
})
