import { CoursePhaseType, CourseType, CourseTypes } from '@/models/course/Course.types'
import i18n from '@/i18n'
import { CoursePhases } from '@/models/course-entries/Types'
import { PaymentStatusType } from '@/models/payment/Payment.interface';
import { RegistrationStatus, RegistrationTypes } from '@/models/registration/Registration.types';

/**
 * Interface for full name
 */
interface FullNameFilter {
    firstName: string;
    insertion?: string;
    lastName: string;
}

/**
 * Full name
 * @param {FullName} name
 * @returns {string}
 */

export function fullNameFilter(name: FullNameFilter){
    return `${name.firstName}${name.insertion ? ' ' + name.insertion + ' ' : ' '}${name.lastName}`
}

/**
 * Place type string
 * @param {CoursePhaseType} type
 * @returns {string}
 */
export function coursePhaseFilter(type: CoursePhaseType){
    let string = ''

    if(type === CoursePhases.earlyBird){
        string = i18n.global.t('word.placetype.earlybird')
    } else if(type === CoursePhases.guaranteedStart){
        string = i18n.global.t('word.placetype.guaranteed.start')
    } else if(type === CoursePhases.lastRemaining) {
        string = i18n.global.t('word.placetype.last.remaining')
    } else if(type === CoursePhases.elearning) {
        string = i18n.global.t('word.placetype.elearning')
    }

    return string
}

/**
 * Place type string
 * @param {CoursePhaseType} type
 * @returns {string}
 */
export function courseTypeFilter(type: CourseType){
    let string = ''

    if(type === CourseTypes.classical){
        string = i18n.global.t('word.course.type.classical')
    } else if(type === CourseTypes.elearning){
        string = i18n.global.t('word.course.type.elearning')
    } else if(type === CourseTypes.webinar) {
        string = i18n.global.t('word.course.type.webinar')
    }

    return string
}


export function getCountryNameBasedOnCode(code?: string){
    // We need to ignore this error, because our app is optimized for chrome
    //@ts-ignore
    const regionNames = new Intl.DisplayNames(['nl'], {type: 'region'});

    return code ? regionNames.of(code.trim()) : '';
}


export function registrationPaymentStatusFilter(method: PaymentStatusType){
    return i18n.global.t(`word.payment.state.${method}`)
}

export function registrationStatusFilter(state: RegistrationStatus){
    return i18n.global.t(`word.state.${state}`)
}

export function registrationTypeFilter(type: RegistrationTypes){
    return i18n.global.t(`word.type.${type}`)
}
