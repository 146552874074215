import { AgendaCourseEntriesFilterInterface } from '@/models/agenda/AgendaCourseEntriesFilter.interface'
import { CourseDateInterface } from '@/models/course/CourseDate.interface'
import { CourseDatesFilterSchema, generateAgendaFilter } from '@/forms/agenda/AgendaFilter.schema'
import { mutations } from '@/store/modules/agenda/Agenda.mutations'
import { agendaGetters } from '@/store/modules/agenda/Agenda.getters'
import { actions } from '@/store/modules/agenda/Agenda.actions'

/**
 * State interface
 */
export interface AgendaState {
    courseDates: CourseDateInterface[];
    courseDatesFilter?: AgendaCourseEntriesFilterInterface;
    courseDatesFilterSchema: CourseDatesFilterSchema;
    loading: boolean;
}

/**
 * State
 * @type {{courseDates: null, courseDatesFilter: {teacher: string, dateRange: string, order_by: string, location: string, order: string}, loading: boolean}}
 */
export const state: AgendaState = {
    courseDates: null,
    loading: false,
    courseDatesFilterSchema: generateAgendaFilter(),
    courseDatesFilter: {
        order: 'asc',
        order_by: 'date',
        dateRange: 'week',
        location: '',
        teacher: ''
    }
}

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters: agendaGetters,
    actions,
    mutations
}
