import { ActionTree } from 'vuex'
import { GeneralState } from '@/store/modules/general/General'
import { GeneralMutations } from '@/store/modules/general/General.mutations'
import { MessageInterface } from '@/models/common/Message.interface'
import i18n from '@/i18n'

export enum GeneralActions {
    TOGGLE_NOTIFICATIONS_DRAWER = 'TOGGLE_NOTIFICATIONS_DRAWER',

    RESET_GLOBAL_MESSAGE = 'RESET_GLOBAL_MESSAGE',

    CREATED_GLOBAL_MESSAGE = 'CREATED_GLOBAL_MESSAGE',
    CREATED_GLOBAL_ERROR_MESSAGE = 'CREATED_GLOBAL_ERROR_MESSAGE',

    UPDATED_GLOBAL_MESSAGE = 'UPDATED_GLOBAL_MESSAGE',
    UPDATED_GLOBAL_ERROR_MESSAGE = 'UPDATED_GLOBAL_ERROR_MESSAGE',

    DELETED_GLOBAL_MESSAGE = 'DELETED_GLOBAL_MESSAGE',
    DELETED_GLOBAL_ERROR_MESSAGE = 'DELETED_GLOBAL_ERROR_MESSAGE',

    SEND_GLOBAL_MESSAGE = 'SEND_GLOBAL_MESSAGE',
    REQUEST_FAILED_GLOBAL_MESSAGE = 'REQUEST_FAILED_GLOBAL_MESSAGE',
    NOT_FOUND_GLOBAL_MESSAGE = 'NOT_FOUND_GLOBAL_MESSAGE',
    SESSION_EXPIRED_MESSAGE = 'SESSION_EXPIRED_MESSAGE',
    APP_UPDATE_MESSAGE = 'APP_UPDATE_MESSAGE'
}

// actions
export const actions: ActionTree<GeneralState, any> = {
    [GeneralActions.RESET_GLOBAL_MESSAGE]:({
        commit
    }) => {
        commit(GeneralMutations.SET_GLOBAL_MESSAGE, null)
    },

    [GeneralActions.CREATED_GLOBAL_MESSAGE]:({commit}, {text}) => {
        const message = i18n.global.t('sentence.global.created', {
            text
        })

        const messageObj: MessageInterface = {
            name: GeneralActions.CREATED_GLOBAL_MESSAGE,
            type: 'success',
            title: message,
            message
        }

        commit(GeneralMutations.SET_GLOBAL_MESSAGE, messageObj)
    },

    [GeneralActions.UPDATED_GLOBAL_MESSAGE]:({commit}, {text}) => {
        const message = i18n.global.t('sentence.global.updated', {
            text
        })

        const messageObj: MessageInterface = {
            name: GeneralActions.UPDATED_GLOBAL_MESSAGE,
            type: 'success',
            title: message,
            message
        }

        commit(GeneralMutations.SET_GLOBAL_MESSAGE, messageObj)
    },

    [GeneralActions.DELETED_GLOBAL_MESSAGE]:({commit}, {text}) => {
        const message = i18n.global.t('sentence.global.deleted', {text})
        const messageObj: MessageInterface = {
            name: GeneralActions.DELETED_GLOBAL_MESSAGE,
            type: 'success',
            title: message,
            message
        }

        commit(GeneralMutations.SET_GLOBAL_MESSAGE, messageObj)
    },

    [GeneralActions.CREATED_GLOBAL_ERROR_MESSAGE]:({commit}, {text}) => {
        const message = i18n.global.t('sentence.global.created.error', {text})
        const messageObj: MessageInterface = {
            name: GeneralActions.CREATED_GLOBAL_ERROR_MESSAGE,
            type: 'error',
            title: message,
            message
        }

        commit(GeneralMutations.SET_GLOBAL_MESSAGE, messageObj)
    },

    [GeneralActions.SEND_GLOBAL_MESSAGE]:({commit}, {text}) => {
        const message = i18n.global.t('sentence.global.send', {
            text
        })

        const messageObj: MessageInterface = {
            name: GeneralActions.SEND_GLOBAL_MESSAGE,
            type: 'success',
            title: message,
            message
        }

        commit(GeneralMutations.SET_GLOBAL_MESSAGE, messageObj)
    },

    [GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE]:({commit}) => {
        const message = i18n.global.t('sentence.global.request.failed')

        const messageObj: MessageInterface = {
            name: GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE,
            type: 'error',
            title: message,
            message
        }

        commit(GeneralMutations.SET_GLOBAL_MESSAGE, messageObj)
    },

    [GeneralActions.SESSION_EXPIRED_MESSAGE]:({commit}) => {
        const messageObj: MessageInterface = {
            type: 'warning',
            name: GeneralActions.SESSION_EXPIRED_MESSAGE,
            title: i18n.global.t('title.error.session.expired'),
            message: i18n.global.t('sentence.error.session.expired')
        }

        commit(GeneralMutations.SET_GLOBAL_MESSAGE, messageObj)
    },

    [GeneralActions.APP_UPDATE_MESSAGE]:({commit}) => {
        const messageObj: MessageInterface = {
            type: 'warning',
            name: GeneralActions.APP_UPDATE_MESSAGE,
            title: i18n.global.t('title.notification.app.update.available'),
            message: i18n.global.t('sentence.notification.app.update.available'),
            force: true
        }

        commit(GeneralMutations.SET_GLOBAL_MESSAGE, messageObj)
    },

    [GeneralActions.NOT_FOUND_GLOBAL_MESSAGE]:({commit}, {text}) => {
        const message = i18n.global.t('sentence.global.not.found', {
            text
        })

        const messageObj: MessageInterface = {
            type: 'warning',
            name: GeneralActions.NOT_FOUND_GLOBAL_MESSAGE,
            title: message,
            message
        }

        commit(GeneralMutations.SET_GLOBAL_MESSAGE, messageObj)
    }
}
