import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'
import { PaymentStates } from '@/models/payment/Payment.interface'

export function generateFieldPaymentStatus(options: any = {}){
    const choices = [PaymentStates.COMPLETED, PaymentStates.NEW, PaymentStates.OPEN, PaymentStates.CANCELLED].map(state => {
        return {
            label: i18n.global.t(`word.payment.state.${state}`),
            value: state
        }
    })

    let paymentStatusField: FieldInterface = {
        component: 'Select',
        model: 'paymentState',
        required: true,
        label:{
            text: i18n.global.t('word.payment.state')
        },
        choices
    }

    paymentStatusField = Object.assign(paymentStatusField, options)

    return paymentStatusField
}
