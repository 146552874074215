import { useRoute } from 'vue-router'
import { AuthFormState } from '@/store/modules/auth/Auth'

export function useAuthFormState(): AuthFormState{
    const route = useRoute()

    if(route){
        return route.name === 'authLogin' ? 'login' : 'requestPassword'
    }

    return 'login'
}
