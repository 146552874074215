import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export function generateFieldEmail(options: any = {}){
    let email: FieldInterface = {
        component: 'Input',
        type: 'email',
        model: 'email',
        required: true,
        label:{
            text: i18n.global.t('word.email')
        },
        //validation: yup.string()
        //    .required()
        //    .label(i18n.global.t('word.email'))
    }

    email = Object.assign(options, email)

    return email
}
