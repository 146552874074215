import {
    RegistrationInvoiceType,
    RegistrationStatus,
    RegistrationTypes
} from '@/models/registration/Registration.types'
import { CoursePhases } from '@/models/course-entries/Types'
import {
    AddressFieldInterface,
    generateAddressFieldsSchema,
    generateAddressModelSchema, generateAddressValidationSchema
} from '@/forms/address/Address.schema'
import {
    generateStudentFieldsSchema,
    generateStudentModelSchema, generateStudentValidationSchema,
    StudentSchemaFieldInterface
} from '@/forms/student/Student.schema'
import yup from '@/yup'
import { FieldInterface } from '@/models/forms/schema/Field.interface'
import useFieldSchemaModelPrefix from '@/composables/forms/fields/useFieldSchemaModelPrefix'
import { RegistrationInterface } from '@/models/registration/Registration.interface'
import { generateFieldCourseEntry } from '@/forms/courseEntry/generators/fields/FieldCourseEntry'
import { generateFieldStatus } from '@/forms/registrations/fields/FieldStatus';
import { registrationStatusFilter } from '@/filters';
import i18n from '@/i18n';


export interface RegistrationSchemaInterface {
    fields: {
        status: FieldInterface;
        stapRegistration: FieldInterface;
        student: StudentSchemaFieldInterface|Record<any, any>,
        invoiceAddress: AddressFieldInterface
        courseEntry: FieldInterface
    },
    model: Partial<RegistrationInterface>,
    validation: any
}

export function generateRegistrationSchema(): RegistrationSchemaInterface {
    return {
        fields: {
            status: generateFieldStatus({
                choices: [RegistrationStatus.REGISTERED, RegistrationStatus.WAITING].map(status => {
                    return {
                        label: registrationStatusFilter(status),
                        value: status
                    }
                })
            }),
            stapRegistration: {
                component: 'Switch',
                model: 'stapRegistration',
                label: {
                    text: i18n.global.t('word.stap.budget')
                }
            },
            courseEntry: generateFieldCourseEntry(),
            invoiceAddress: useFieldSchemaModelPrefix(
                'invoiceAddress',
                generateAddressFieldsSchema()
            ),
            student: useFieldSchemaModelPrefix(
                'student',
                generateStudentFieldsSchema()
            )
        },
        model:{
            type: RegistrationTypes.REGISTRATION,
            status: RegistrationStatus.REGISTERED,
            stapRegistration: false,
            placeType: CoursePhases.guaranteedStart,
            checkoutCompletedAt: null,
            courseEntry: '',
            invoiceType: RegistrationInvoiceType.PRIVATE,
            student: generateStudentModelSchema(),
            invoiceAddress: generateAddressModelSchema()
        },
        validation: yup.object({
            status: yup.string().required(),
            student: yup.object(generateStudentValidationSchema()),
            invoiceAddress: yup.object(generateAddressValidationSchema()),
            courseEntry: yup
                .string()
                .required()
                .label(i18n.global.t('word.entry'))
        })
    }
}
