import yup from '@/yup'
import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export interface AuthLoginSchemaInterface {
    fields: {
        email: FieldInterface;
        password: FieldInterface;
        remember: FieldInterface;
    },
    model:{
        email: string;
        password: string;
        remember: boolean;
    }
}

export const authLoginSchema: AuthLoginSchemaInterface = {
    fields: {
        email: {
            component: 'Input',
            type: 'email',
            model: 'email',
            required: true,
            label: {
                text: i18n.global.t('word.email')
            },
            validation: yup.string()
                .required()
                .email()
                .label(i18n.global.t('word.email'))
        },
        password: {
            component: 'Input',
            type: 'password',
            model: 'password',
            required: true,
            label: {
                text: i18n.global.t('word.password')
            },
            validation: yup.string()
                .required()
                .label(i18n.global.t('word.password'))
        },
        remember: {
            component: 'Checkbox',
            model: 'remember',
            required: true,
            label: {
                text: i18n.global.t('word.remember.me')
            },
            choices: {
                label: i18n.global.t('word.remember.me'),
                value: true
            },
            validation: yup.boolean()
        }
    },
    model:{
        email: '',
        password: '',
        remember: true
    }
}
