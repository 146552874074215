const Exception404 = () => import(/* webpackChunkName: "group-exceptions" */ '@/components/general/exceptions/Exception404.vue')

export default [
    {
        path: '/:pathMatch(.*)',
        name: 'exception404',
        component: Exception404,
        meta:{
            roles: [],
            auth: false,
            meta:{
                seo: {
                    title: '404'
                }
            }
        }
    }
]
