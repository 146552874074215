import yup from '@/yup'
import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'
import { generateFieldTeacher } from '@/forms/common/fields/FieldTeacher'
import { generateFieldLocation } from '@/forms/common/fields/FieldLocation'
import { generateFieldCourseType } from '@/forms/common/fields/FieldCourseType'
import { generateFieldTotal } from '@/forms/common/fields/FieldTotal'
import { generateFieldTime } from '@/forms/common/fields/FieldTime'
import { generateFieldCurrency } from '@/forms/common/fields/FieldCurrency'
import fieldRangeGenerator from '@/forms/common/generators/FieldNumberOptionsGenerator'

import { CourseDateInterface } from '@/models/course/CourseDate.interface'
import { CourseEntryPlatformTypes, CoursePhases } from '@/models/course-entries/Types'
import { CourseEntryInterface } from '@/models/course-entries/CourseEntry.interface'
import { CourseTypes } from '@/models/course/Course.types'
import { generateFieldCourseEntryPlatformType } from '@/forms/common/fields/FieldCourseEntryPlatformType';

export interface CourseEntrySchemaModelInterface extends CourseEntryInterface {
    courseDates: CourseDateInterface[]
}

export interface CourseEntryFieldsInterface {
    currency: FieldInterface;
    courseDates:{
        date: FieldInterface;
        startDate: FieldInterface;
        endDate: FieldInterface;
        teacher: FieldInterface;
        zoomLink: FieldInterface;
    },
    code: FieldInterface;
    regularStapId: FieldInterface
    earlyBirdStapId: FieldInterface
    type: FieldInterface;
    location: FieldInterface;
    total: FieldInterface;
    part: FieldInterface;
    spots: {
        [CoursePhases.earlyBird]?: FieldInterface,
        [CoursePhases.guaranteedStart]?: FieldInterface,
        [CoursePhases.lastRemaining]?: FieldInterface,
        [CoursePhases.elearning]?: FieldInterface,
    },
    isOnline: FieldInterface;
    isCompleted: FieldInterface;
    gradesCompleted: FieldInterface;
    digitalTeachingMaterials: FieldInterface;
    platform: FieldInterface;
}

export interface CourseEntrySchemaInterface {
    fields: CourseEntryFieldsInterface,
    model: Partial<CourseEntrySchemaModelInterface>,
    validation: any
}
export function generateEmptyCourseDateModel(): CourseDateInterface{
    return {
        date: '',
        startDate: '',
        endDate: '',
        teacher: '',
    }
}

const fields: CourseEntryFieldsInterface = {
    currency: generateFieldCurrency(),
    total: generateFieldTotal(),
    courseDates: {
        date: {
            component: 'Input',
            type: 'date',
            model: 'date',
            required: true,
            label: {
                text: i18n.global.t('word.start.date')
            },
            validation: undefined
        },
        startDate: generateFieldTime({
            model: 'startDate',
            label: i18n.global.t('word.start.time'),
            validation: undefined
        }),
        endDate: generateFieldTime({
            model: 'endDate',
            label: i18n.global.t('word.end.time'),
            validation: undefined
        }),
        teacher: generateFieldTeacher({
            validation: undefined
        }),
        zoomLink: {
            component: 'Input',
            type: 'text',
            model: 'zoomLink',
            required: true,
            label: {
                text: i18n.global.t('word.zoom.link')
            },
            validation: yup.string()
        }
    },
    type: generateFieldCourseType(),
    location: generateFieldLocation(),
    code: {
        component: 'Input',
        type: 'text',
        model: 'code',
        required: true,
        label: {
            text: i18n.global.t('word.code')
        }
    },
    regularStapId: {
        component: 'Input',
        type: 'text',
        model: 'regularStapId',
        required: true,
        label: {
            text: i18n.global.t('word.regular.stap.id')
        }
    },
    earlyBirdStapId: {
        component: 'Input',
        type: 'text',
        model: 'earlyBirdStapId',
        required: true,
        label: {
            text: i18n.global.t('word.earlybird.stap.id')
        }
    },
    isOnline: {
        component: 'Switch',
        model: 'isOnline',
        required: true,
        label: {
            text: i18n.global.t('word.course.online')
        }
    },
    isCompleted: {
        component: 'Switch',
        model: 'isCompleted',
        required: true,
        label: {
            text: i18n.global.t('word.course.completed')
        }
    },
    gradesCompleted: {
        component: 'Switch',
        model: 'gradesCompleted',
        required: true,
        label: {
            text: i18n.global.t('word.grades.completed')
        }
    },
    digitalTeachingMaterials: {
        component: 'Switch',
        model: 'digitalTeachingMaterials',
        required: true,
        label: {
            text: i18n.global.t('word.digital.teaching.materials')
        }
    },
    platform: generateFieldCourseEntryPlatformType(),
    part: {
        component: 'Input',
        type: 'text',
        model: 'part',
        required: true,
        label: {
            text: i18n.global.t('word.part')
        }
    },
    spots:{
        earlyBird: {
            component: 'Select',
            model: CoursePhases.earlyBird,
            required: true,
            label: {
                text: i18n.global.t('word.early.bird')
            },
            choices: fieldRangeGenerator(0)
        },
        guaranteedStart: {
            component: 'Select',
            model: CoursePhases.guaranteedStart,
            required: true,
            label: {
                text: i18n.global.t('word.guaranteed.start')
            },
            choices: fieldRangeGenerator(0)
        },
        lastRemaining: {
            component: 'Select',
            model: CoursePhases.lastRemaining,
            required: true,
            label: {
                text: i18n.global.t('word.last.remaining')
            },
            choices: fieldRangeGenerator(0)
        }
    }
}

const model = {
    courseDates:[generateEmptyCourseDateModel()],
    type: CourseTypes.classical,
    code: '',
    location: '',
    course: null,
    isOnline: true,
    isCompleted: false,
    gradesCompleted: false,
    digitalTeachingMaterials: false,
    platform: CourseEntryPlatformTypes.zoom,
    parts: [],
    spots: [
        {
            type: CoursePhases.earlyBird,
            amount: 10
        },
        {
            type: CoursePhases.guaranteedStart,
            amount: 10
        },
        {
            type: CoursePhases.lastRemaining,
            amount: 10
        }
    ]
}

export function generateCourseEntrySchema(): CourseEntrySchemaInterface{
    return {
        fields,
        model,
        validation: yup.object().shape({
            code: yup.string()
                .required()
                .label(fields.code.label.text),
            platform: yup.string()
                .nullable()
                .label(fields.platform.label.text),
            type: yup.string()
                .required()
                .label(fields.type.label.text),
            location: yup.string()
                .required()
                .label(fields.location.label.text),
            //courseDates:
            //    yup
            //        .array()
            //        .of(
            //            yup.object().shape({
            //                date: yup.string().required().label(fields.courseDates.date.label.text),
            //                startDate: yup.string().required().label(fields.courseDates.startDate.label.text),
            //                endDate: yup.string().required().label(fields.courseDates.endDate.label.text),
            //                teacher: yup.string().required().label(fields.courseDates.teacher.label.text),
            //            })
            //        ).strict()
        })
    }
}
