import Api from '@/api/src/Api'
import { AxiosPromise } from 'axios'
import { TeacherInterface } from '@/models/teachers/Teacher.interface'

export default class TeachersApi extends Api {
    constructor() {
        super()

        this.endPoint = 'teachers'
    }

    /**
     * Create resource
     * @param {{}} data
     * @returns {AxiosPromise}
     */
    create(data: TeacherInterface): AxiosPromise {
        delete data.id

        return this.axios.post(`${this.endPoint}`, data)
    }

    /**
     * Updated resource
     * @param {string} id
     * @param {TeacherInterface} data
     * @returns {AxiosPromise}
     */
    update(id: string, data: TeacherInterface): AxiosPromise {
        delete data.id

        return this.axios.put(`${this.endPoint}/${id}`, data)
    }
}
