import {AddressInterface} from '@/models/common/Address.interface'
import { MutationTree } from 'vuex'
import { StudentState, initialState } from '@/store/modules/student/Student'
import { CourseInterface } from '@/models/course/Course.interface'
import { RegistrationInterface } from '@/models/registration/Registration.interface'
import { StudentInterface } from '@/models/student/Student.interface'
import { StudentRegistrationFilterInterface } from '@/models/student/StudentRegistrationFilter.interface'
import { generateStudentSchema } from '@/forms/student/Student.schema'

export enum StudentMutations {
    SET_STUDENT = 'SET_STUDENT',
    SET_STUDENT_VADEMECUM_ORDERED = 'SET_STUDENT_VADEMECUM_ORDERED',
    SET_REGISTRATIONS = 'SET_REGISTRATIONS',
    SET_WAITING_LISTS = 'SET_WAITING_LISTS',
    SET_RECOMMENDED_COURSES = 'SET_RECOMMENDED_COURSES',
    UPDATE_STUDENT_REGISTRATIONS_FILTER = 'UPDATE_STUDENT_REGISTRATIONS_FILTER',
    SET_LOADING = 'SET_LOADING',
    RESET_STATE = 'RESET_STATE',
    ADD_ADDRESS = 'ADD_ADDRESS',
    DELETE_ADDRESS = 'DELETE_ADDRESS'
}

// mutations
export const mutations: MutationTree<StudentState> = {
    [StudentMutations.SET_STUDENT]: (state: StudentState, student: StudentInterface) => {
        state.student = student
        state.studentSchema.model = JSON.parse(JSON.stringify(student))
    },

    [StudentMutations.SET_REGISTRATIONS]: (state: StudentState, registrations: RegistrationInterface[]) => {
        state.registrations = registrations
    },

    [StudentMutations.SET_WAITING_LISTS]: (state: StudentState, registrations: RegistrationInterface[]) => {
        state.waitingLists = registrations
    },

    [StudentMutations.UPDATE_STUDENT_REGISTRATIONS_FILTER]: (state: StudentState, studentRegistrationsFilter: StudentRegistrationFilterInterface) => {
        state.studentRegistrationsFilter = Object.assign(state.studentRegistrationsFilter, studentRegistrationsFilter);
    },

    [StudentMutations.SET_RECOMMENDED_COURSES]: (state: StudentState, courses: CourseInterface[]) => {
        state.recommendedCourses = courses
    },

    [StudentMutations.SET_LOADING]: (state: StudentState, loading: boolean) => {
        state.loading = loading
    },

    [StudentMutations.RESET_STATE]: (state: StudentState) => {
        Object.assign(state, initialState)

        // Reset schema
        state.studentSchema = generateStudentSchema()
    },

    [StudentMutations.SET_STUDENT_VADEMECUM_ORDERED]: (state: StudentState, payload: boolean) => {
        state.student.orderedVademecum = payload
    },

    [StudentMutations.ADD_ADDRESS]: (state: StudentState, address: AddressInterface) => {
        state.studentSchema.model.addresses.push(address)
        state.student.addresses.push(address)
    },

    [StudentMutations.DELETE_ADDRESS]: (state: StudentState, index: number) => {
        state.studentSchema.model.addresses.splice(index, 1);
    }
}
