import i18n from '@/i18n';

const Students = () => import(/* webpackChunkName: "group-students" */ '@/views/app/students/StudentsIndex.vue')
const StudentSingle = () => import(/* webpackChunkName: "group-students" */ '@/views/app/students/StudentSingle.vue')
const StudentCrud = () => import(/* webpackChunkName: "group-students" */ '@/views/app/students/StudentCrud.vue')

import menuStudentIcon from '@/assets/images/icons/menu-student-icon.svg'
import GeneralRouterView from '@/components/general/GeneralRouterView.vue'

export default [{
        path: '/students',
        name: 'students',
        component: GeneralRouterView,
        meta: {
            label: 'word.students',
            icon: menuStudentIcon,
            iconCurrent: menuStudentIcon
        },
        children:[
            {
                path: '',
                name: 'studentsIndex',
                component: Students,
                meta:{
                    seo: {
                        title: i18n.global.t('word.students')
                    }
                }
            },
            {
                path: ':id',
                name: 'studentSingle',
                component: StudentSingle,
                meta:{
                    seo: {
                        title: i18n.global.t('word.student')
                    }
                }
            },
            {
                path: ':id/update',
                name: 'studentUpdate',
                component: StudentCrud,
                meta:{
                    seo: {
                        title: i18n.global.t('word.update.student')
                    }
                }
            }
        ]
    }
]
