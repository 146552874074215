import { LocationInterface } from '@/models/locations/Location.interface';
import { generateLocationSchema, LocationSchemaInterface } from '@/forms/location/Location.schema';
import { getters } from '@/store/modules/location/Location.getters';
import { actions } from '@/store/modules/location/Location.actions';
import { mutations } from '@/store/modules/location/Location.mutations';
import { CourseDateInterface } from '@/models/course/CourseDate.interface';

/**
 * State interface
 */
export interface LocationState {
    location?: LocationInterface;
    courseDates?: CourseDateInterface[];
    loading: boolean;
    locationSchema: LocationSchemaInterface;
}

/**
 * Location
 * @type {{location: null, loading: boolean, locationSchema: LocationSchemaInterface}}
 */
export const initialState: LocationState = {
    location: null,
    loading: false,
    courseDates: null,
    locationSchema: generateLocationSchema()
}

export const state = JSON.parse(JSON.stringify(initialState));

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
