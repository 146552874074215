import i18n from '@/i18n'
import yup from '@/yup'
import { FieldInterface } from '@/models/forms/schema/Field.interface'
import { CourseTypes } from '@/models/course/Course.types'

const choices = Object.values(CourseTypes).map(type => {
    return {
        label: i18n.global.t(`word.course.type.${type}`),
        value: type
    }
})

export function generateFieldCourseType(options: any = {}){
    let courseTypes: FieldInterface = {
        component: 'Select',
        model: 'type',
        required: true,
        choices,
        label:{
            text: i18n.global.t('word.type')
        },
        validation: yup.string()
            .required()
            .label(options.label || i18n.global.t('word.type'))
    }

    courseTypes = Object.assign(options, courseTypes)

    return courseTypes
}
