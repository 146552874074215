import { FieldInterface } from '@/models/forms/schema/Field.interface'
import { generateFieldStatus } from '@/forms/registrations/fields/FieldStatus'
import { generateFieldPaymentStatus } from '@/forms/registrations/fields/FieldPaymentStatus'
import { RegistrationStatus } from '@/models/registration/Registration.types'

export interface RegistrationsFilterSchemaInterface {
    fields: {
        status: FieldInterface;
        paymentState: FieldInterface;
    },
    model: {
        status?: RegistrationStatus | 'all'
    }
}

export function generateRegistrationsFilter(): RegistrationsFilterSchemaInterface {
    return {
        fields: {
            status: generateFieldStatus(),
            paymentState: generateFieldPaymentStatus()
        },
        model:{
            status: 'all'
        }
    }
}
