import i18n from '@/i18n'
import { FieldInterface } from '@/models/forms/schema/Field.interface'

export function generateFieldSalutation(options: any = {}){
    let fieldSalutation: FieldInterface = {
        component: 'Radio',
        required: true,
        model: 'salutation',
        label: {
            text: i18n.global.t('form.label.salutation')
        },
        choices: [{
           value: 'male',
           label:  i18n.global.t('form.label.salutation.male')
        }, {
            value: 'female',
            label:  i18n.global.t('form.label.salutation.female')
        }],
        //validation: null
    }

    fieldSalutation = Object.assign(options, fieldSalutation)

    return fieldSalutation
}
