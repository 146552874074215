import { ActionTree } from 'vuex'
import { AxiosResponse } from 'axios'
import { TeachersMutations } from '@/store/modules/teachers/Teachers.mutations'
import { TeacherInterface } from '@/models/teachers/Teacher.interface'
import { TeachersState } from '@/store/modules/teachers/Teachers'
import Api from '@/api/Api'
import { apiFormatOrderFilter } from '@/api/src/Api'

export enum TeachersActions {
    GET_TEACHERS = 'GET_TEACHERS'
}

// actions
export const actions: ActionTree<TeachersState, any> = {
    [TeachersActions.GET_TEACHERS]: async ({
         commit,
         state
     }) => {
        const response: AxiosResponse<TeacherInterface> = await Api.teachers.index({
            params:{
                'archivedAt[null]': true,
                'deletedAt[null]': true,
                ...apiFormatOrderFilter(state.teachersFilter)
            }
        })
        commit(TeachersMutations.SET_TEACHERS, response.data['hydra:member'])
    }
}
