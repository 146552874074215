import { teachersGetters } from '@/store/modules/teachers/Teachers.getters'
import { actions } from '@/store/modules/teachers/Teachers.actions'
import { mutations } from '@/store/modules/teachers/Teachers.mutations'
import { TeacherInterface } from '@/models/teachers/Teacher.interface'
import { TeachersFilterInterface } from '@/models/teachers/TeachersFilter.interface'

/**
 * State interface
 */
export interface TeachersState {
    teachers: TeacherInterface[];
    teachersFilter: TeachersFilterInterface;
    loading: boolean;
}

/**
 * State
 * @type {{courses: null, loading: boolean}}
 */
export const state: TeachersState = {
    teachers: null,
    loading: false,
    teachersFilter: {
        order: 'asc',
        order_by: 'firstName'
    }
}

/**
 * Export module
 */

export default {
    namespaced: true,
    state,
    getters: teachersGetters,
    actions,
    mutations
}
