import { mutations } from '@/store/modules/seo/Seo.mutations'
import { getters } from '@/store/modules/seo/Seo.getters'
import { actions } from '@/store/modules/seo/Seo.actions'

/**
 * State interface
 */
export interface SeoState {
    title: string;
}

/**
 * State
 * @type {{title: string}}
 */
export const initialState: SeoState = {
    title: ''
}

export const state = JSON.parse(JSON.stringify(initialState));

/**
 * Export module
 */
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
