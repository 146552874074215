<template>
    <MenuWalker prefixClass="menu-user" :routes="routes" />
</template>

<script>
    import MenuWalker from '@/components/menus/MenuWalker.vue'
    import { reactive, computed, toRefs, defineComponent } from "vue";

    export default defineComponent({
        components:{
            MenuWalker
        },
        setup(){
            const data = reactive({
                routes: computed(() => {
                    return [
                        { route:'authLogout' }
                    ]
                })
            })

            return {
                ...toRefs(data)
            }
        }
    })
</script>

<style lang="scss">
    @use '~@/assets/scss/core/functions';
    @use '~@/assets/scss/core/mixins';
    @use '~@/assets/scss/core/config/colors';
    @use '~@/assets/scss/core/config/typography';
    @use '~@/assets/scss/core/config/misc';

    .menu-user {
        position:absolute;
        bottom:9.5rem;
        left:3rem;
        right:3rem;
        padding:0.5rem;
        border-radius: misc.$border-radius-default;
        background-color:white;

        &:before{
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 0.6rem solid transparent;
            border-right: 0.6rem solid transparent;
            border-top: 0.6rem solid #ffffff;
            left: 2rem;
            bottom: -0.6rem;
        }

        &__link{
            display:block;
            padding: 0.6rem 1.5rem;
            color: colors.$primary;
            transition: functions.trans(color, background);
            border-radius: misc.$border-radius-default;
            cursor: pointer;
            font:{
                size: 1.3rem;
                weight: typography.$weight-medium;
            }

            &:hover{
                color: colors.$primary-text;
                background-color: colors.$primary;
            }
        }
    }
</style>


