import { dayjs } from '@/main'

export const dateWeek = (date: Date) => {
  return dayjs(date).week()
}

export const dateClock = (date: Date) => {
    return dayjs(date).format('DD-MM')
}

export const dateTimeWithSeconds = (date: Date) => {
  return dayjs(date).format('HH:mm:ss')
}

export const dateTimeGetMinutes = (date: string | number | Date) => {
    let minutes = dayjs(date).minute()
    minutes += dayjs(date).hour() * 60

    return +minutes
}
