// actions
import { ActionTree } from 'vuex'
import { AxiosResponse } from 'axios'
import { UserInterface } from '@/models/users/User.interface'
import { UserMutations } from '@/store/modules/user/User.mutations'
import { UserState } from '@/store/modules/user/User'
import Api from '@/api/Api'

export enum UserActions {
    GET_USER = 'GET_USER',
}

export const actions: ActionTree<UserState, any> = {
    [UserActions.GET_USER]: async ({commit}, {
        id
    }) => {
        try {
            if (id) {
                const response: AxiosResponse<UserInterface> = await Api.users.showById(id)
                commit(UserMutations.SET_USER, response.data)
            } else if (localStorage.getItem('user')) {
                commit(UserMutations.SET_USER, JSON.parse(localStorage.getItem('user')))
                commit(UserMutations.SET_AUTHENTICATED, JSON.parse(localStorage.getItem('authenticated')))
            }
        } catch (e){
            console.log(e)
        }
    }
}
