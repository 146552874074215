import i18n from '@/i18n';

const Locations = () => import(/* webpackChunkName: "group-locations" */ '@/views/app/locations/LocationsIndex.vue')
const LocationSingle = () => import(/* webpackChunkName: "group-locations" */ '@/views/app/locations/LocationSingle.vue')
const LocationCreate = () => import(/* webpackChunkName: "group-locations" */ '@/views/app/locations/LocationCrud.vue')
const TeacherUpdate = () => import(/* webpackChunkName: "group-locations" */ '@/views/app/locations/LocationCrud.vue')
const GeneralRouterView = () => import(/* webpackChunkName: "group-locations" */ '@/components/general/GeneralRouterView.vue')
import menuLocationIcon from '@/assets/images/icons/menu-location-icon.svg'

export default [
    {
        path: '/locations',
        name: 'locations',
        component: GeneralRouterView,
        meta: {
            'label': 'word.locations',
            'icon': menuLocationIcon,
            'iconCurrent': menuLocationIcon
        },
        children:[
            {
                path: '',
                name: 'locationsIndex',
                component: Locations,
                meta:{
                    seo: {
                        title: i18n.global.t('word.locations')
                    }
                }
            },
            {
                path: ':id',
                name: 'locationSingle',
                component: LocationSingle,
                meta:{
                    seo: {
                        title: i18n.global.t('word.location')
                    }
                }
            },
            {
                path: 'create',
                name: 'locationCreate',
                component: LocationCreate,
                meta:{
                    seo: {
                        title: i18n.global.t('word.create.location')
                    }
                }
            },
            {
                path: ':id/update',
                name: 'locationUpdate',
                component: TeacherUpdate,
                meta:{
                    seo: {
                        title: i18n.global.t('word.update.location')
                    }
                }
            }
        ]
    }
]
