import { ActionTree } from 'vuex'
import { AxiosResponse } from 'axios'
import { TeacherState } from '@/store/modules/teacher/Teacher'
import { TeacherMutations } from '@/store/modules/teacher/Teacher.mutations'
import { TeacherInterface } from '@/models/teachers/Teacher.interface'
import { CourseEntryInterface } from '@/models/course-entries/CourseEntry.interface'
import Api from '@/api/Api'

export enum TeacherActions {
    GET_TEACHER = 'GET_TEACHER',
    CREATE_TEACHER = 'CREATE_TEACHER',
    UPDATE_TEACHER = 'UPDATE_TEACHER',
    DELETE_TEACHER = 'DELETE_TEACHER',
    GET_COURSE_DATES = 'GET_COURSE_DATES'
}

// actions
export const actions: ActionTree<TeacherState, any> = {
    /**
     * Get teacher
     * @param {Commit} commit
     * @param {any} id
     * @returns {Promise<void>}
     */
    [TeacherActions.GET_TEACHER]: async ({commit}, {
        id
    }) => {
        commit(TeacherMutations.RESET_STATE)

        const response: AxiosResponse<TeacherInterface> = await Api.teachers.showById(id)
        commit(TeacherMutations.SET_TEACHER, response.data)
    },

    [TeacherActions.CREATE_TEACHER]: async (context) => {
        const response: AxiosResponse<CourseEntryInterface> = await Api.teachers.create(context.state.teacherSchema.model)
        context.commit(TeacherMutations.SET_TEACHER, response.data)
    },

    /**
     * Update teacher
     * @param {ActionContext<TeacherState, any>} context
     * @returns {Promise<void>}
     */
    [TeacherActions.UPDATE_TEACHER]: async (context) => {
        const model = context.state.teacherSchema.model as TeacherInterface
        const response: AxiosResponse<CourseEntryInterface> = await Api.teachers.update(model.id, model)
        context.commit(TeacherMutations.SET_TEACHER, response.data)
    },

    /**
     * Delete teacher
     * @param {ActionContext<TeacherState, any>} context
     * @returns {Promise<void>}
     */
    [TeacherActions.DELETE_TEACHER]: async (context) => {
        try{
            await Api.teachers.delete(context.state.teacherSchema.model.id)
        } catch(e){
            console.log(e)
        }
    },

    /**
     * Get course dates by teacher
     * @param {ActionContext<TeacherState, any>} context
     * @returns {Promise<void>}
     */
    [TeacherActions.GET_COURSE_DATES]: async (context) => {
        const teacher = context.state.teacher

        if(teacher) {
            const calls = []
            teacher.courseDates.forEach(courseDate => {
                calls.push(Api.instance.showByUri(courseDate['@id']))
            })

            Promise.all(calls).then((response) => {
                context.commit(TeacherMutations.SET_COURSE_DATES, response.map(courseDate => courseDate.data))
            })
        }
    }
}
